import pako from 'pako';
import axios from 'axios';
import { getEnvConfig } from '../envConfig';
import uploadsApi from '../services/uploads';
import auth from '../auth';

const envConfig = getEnvConfig();

// Max Cloudfront url length is 8192, limit to 7000 to leave a buffer for the rest of the URL
const TRANSIENT_DOCUMENT_LENGTH_LIMIT = 7000;

const bufferToBase64 = (buffer: Uint8Array): string => {
    const binstr = Array.prototype.map
        .call(buffer, (ch) => String.fromCharCode(ch))
        .join('');

    return btoa(binstr);
};

export const getSceneUrl = (designPhysicalSpecId: string, width: number): string => {
    const surfaceUrl = `https://${envConfig.dpsUrl}/api/v2/DesignPhysicalSpecs/${designPhysicalSpecId}/surfaceSet?requestor=${getEnvConfig().requestorParam}`;

    const sceneInstructions = {
        width,
        page: 1,
        showFullBleed: true,
        product: {
            calculatedSurfaceSetUrl: surfaceUrl,
        },
        layers: [
            {
                type: 'overlay',
                source: 'safe',
                stroke: {
                    color: 'rgb(100,100,100)',
                    dotted: true,
                },
            },
            {
                type: 'overlay',
                source: 'trim',
                stroke: {
                    color: 'rgb(100,100,100)',
                    dotted: false,
                },
            },
            {
                type: 'overlay',
                source: 'bleed',
                fillColor: 'rgb(255,255,255)',
                stroke: {
                    color: 'rgb(100,100,100)',
                    dotted: false,
                },
            },
            {
                type: 'overlay',
                source: 'fold',
                stroke: {
                    color: 'rgb(255,0,0)',
                    dotted: true,
                },
            },
            {
                type: 'overlay',
                source: 'cut',
                stroke: {
                    color: 'rgb(0,0,255)',
                    dotted: false,
                },
            },
        ],
    };

    const deflatedSceneInstructions = btoa(JSON.stringify(sceneInstructions));
    const sceneUrl = `https://scenes.documents.cimpress.io/v3/transient?data=${deflatedSceneInstructions}`;

    return sceneUrl;
};

export const encodeDocument = (document: string): string => {
    const deflated = pako.deflateRaw(document);

    return encodeURIComponent(bufferToBase64(deflated));
};

const encodeOrUploadDocument = async (document: string): Promise<string> => {
    const encodedDocument = encodeDocument(document);

    return encodedDocument.length > TRANSIENT_DOCUMENT_LENGTH_LIMIT
        ? encodeURIComponent(await uploadsApi.uploadTransientDocument(document))
        : encodedDocument;
};

export const getPreviewUrlUsingDocument = async (
    document: string,
    dpsId: string,
    width: number,
): Promise<string> => {
    const documentOrUploadUrl = await encodeOrUploadDocument(document);
    const instructionsUrl = `https://${envConfig.instructionsDocumentsUrl}/v3/instructions:preview?documentUri=${documentOrUploadUrl}`;
    const renderingUrl = `https://${envConfig.renderingUrl}/v1/cse/preview?width=${width}&height=${width}&format=png&instructions_uri=${encodeURIComponent(instructionsUrl)}`;
    const sceneUrl = getSceneUrl(dpsId, width);

    return `${renderingUrl}&scene=${encodeURIComponent(sceneUrl)}`;
};

export async function getTemplatePreviewUrl(templateToken: string, width: number, panelType: string): Promise<string> {
    const authToken = auth.getAccessToken();
    let uri = `https://${getEnvConfig().dterUrl}/api/v2/templates/${templateToken}/cultures/en-us/renderDocument?useFakeFamily=false&finishType=None`;

    if (panelType !== 'front') {
        const response = await axios.get(`https://${getEnvConfig().dtecUrl}/api/v4/templates/${templateToken}/ensembles`, {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            validateStatus: () => true,
        });
        const ensembleTemplates = response.data[0]?.templates;
        const ensemble = ensembleTemplates?.find((item: TemplateCatalog.Panel) => item.ensemblePanelType === panelType);

        uri = `https://${getEnvConfig().dterUrl}/api/v2/templates/${ensemble?.templateToken}/cultures/en-us/renderDocument?useFakeFamily=false&finishType=None`;
    }

    const encodedRealizationUri = encodeURIComponent(uri.toString());

    return `https://rendering.mcp.cimpress.com/v2/vp/preview?format=png&instructions_uri=${encodeURIComponent(`https://${envConfig.instructionsDocumentsUrl}/v3/instructions:preview?documentUri=${encodedRealizationUri}`)}&width=${width}`;
}
