import React from 'react';
import { JobItemSubItemPanel } from '../../services/gamService';

interface Props {
    panel: JobItemSubItemPanel;
}

const SingleColumnMetadataView = ({ panel }: Props): JSX.Element => (<ul>
    <li>
        <span className="additional-info-property">DPS:</span>
        {panel.designPhysicalSpec.designPhysicalSpecId}
    </li>
    <li>
        <span className="additional-info-property">Substrate RGB:</span>
        {panel.designPhysicalSpec.substrateColor}
    </li>
    <li>
        <span className="additional-info-property">Orientation:</span>
        {panel.designPhysicalSpec.orientation}
    </li>
</ul>);

export default SingleColumnMetadataView;
