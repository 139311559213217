import { Link } from 'react-router-dom';
import {
    BreadcrumbItem, Breadcrumbs,
} from '@cimpress/react-components';
import React, { useContext } from 'react';
import BreadcrumbsConfigContext from './breadcrumbsConfigContextProvider';

const AppBreadcrumbs = (): JSX.Element => {
    const { breadcrumbsConfig: { ensembleId, ensembleLineId, designConceptId } } = useContext(BreadcrumbsConfigContext);

    return (

        <Breadcrumbs data-testid="breadcrumbs">
            {
                designConceptId
            && <BreadcrumbItem data-testid="design-concept-breadcrumb">
                {ensembleLineId
                    ? <Link to={`/designConceptDetails/${designConceptId}`}>
                        Design Concept
                    </Link>
                    : `Design Concept ${designConceptId}`}
            </BreadcrumbItem>
            }
            {
                ensembleLineId
            && <BreadcrumbItem data-testid="ensemble-line-breadcrumb">
                {ensembleId
                    ? <Link to={`/ensembleLineDetails/${ensembleLineId}`}>
                        Ensemble Line
                    </Link>
                    : `Ensemble Line ${ensembleLineId}`}
            </BreadcrumbItem>
            }
            {
                ensembleId
            && <BreadcrumbItem data-testid="ensemble-breadcrumb">
                {`Ensemble ${ensembleId}`}
            </BreadcrumbItem>
            }
        </Breadcrumbs>);
};

export default AppBreadcrumbs;
