import React, { useState, useEffect } from 'react';
import { PreviewGenerationPanelData } from './previewGenerationResultData';
import { getPreviewUrlUsingDocument } from '../../utils/templatePreviewUtility';
import '../templateGeneration/generatedEnsemblePanelPreview.scss';

interface Props {
    panel: PreviewGenerationPanelData;
    width: number;
}

const EnsembleLineGroupPanelPreview = ({ panel, width }: Props): JSX.Element => {
    const [previewUrl, setPreviewUrl] = useState<string>();

    useEffect(
        () => {
            async function setPreviewUrlUsingDocument(): Promise<void> {
                const url = await getPreviewUrlUsingDocument(
                    panel.template.design,
                    panel.designPhysicalSpecId,
                    500,
                );

                setPreviewUrl(url);
            }

            setPreviewUrlUsingDocument();
        }, [panel],
    );

    return (
        <div className="ensemble-preview">
            <div className="ensemble-panel-tile">
                <a href={previewUrl} target="_blank" rel="noopener noreferrer">
                    <img src={previewUrl} width={width} alt="preview" />
                </a>
            </div>
        </div>
    );
};

export default EnsembleLineGroupPanelPreview;
