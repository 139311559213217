import React from 'react';
import './panelDetailsCard.scss';

interface Props {
    displayName: string;
    displayColor: string;
    dpsId: string;
    showFrontIndicatorText: boolean;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
const DesignPhysicalSpecDetailsSection = ({
    displayName, displayColor, dpsId, showFrontIndicatorText,
}: Props): JSX.Element => (
    <div className="panel-details-item">
        <div className="panel-details-card-text">
            {showFrontIndicatorText ? <p>Design Physical Specifications : Front</p> : <p>Design Physical Specifications</p>}
            <ul>
                <li>Trim: {displayName}</li>
                {
                    displayColor && displayColor.startsWith('rgb')
                        ? <li>Substrate: {displayColor} <span style={{ backgroundColor: displayColor, border: '1px solid gray' }}>&emsp;</span></li>
                        : displayColor && !displayColor.startsWith('rgb')
                            ? <li>Substrate: {displayColor}</li>
                            : <span />
                }
                <li>DPS ID: {dpsId}</li>
            </ul>
        </div>
    </div>
);

/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable no-nested-ternary */
export default DesignPhysicalSpecDetailsSection;
