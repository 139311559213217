import {
    Accordion,
    Alert,
    Button, Card, Checkbox,
} from '@cimpress/react-components';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { Grid } from '@material-ui/core';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import {
    DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import { performLineReplacement, ReplaceLineInputItem } from '../../services/dtecBackstageService';
import { getEnsemblesContainingTemplateToken } from '../../services/dtecService';
import AccordianTitle, { ReplacementStatus } from './AccordianTitle';
import EnsemblePreview from './EnsemblePreview';
import LineDetails from './LineDetails';
import ReplacementError from './ReplacementError';

export interface Replacement {
    index: number;
    oldToken: string;
    newToken: string;
    oldLine: TemplateCatalog.EnsembleLine | undefined;
    newLine: TemplateCatalog.EnsembleLine | undefined;
    status: ReplacementStatus;
    errorText?: string;
    correlationId?: string;
}

/* eslint-disable react/jsx-one-expression-per-line */

function sortProminentToFront(ensembles: TemplateCatalog.Ensemble[] | undefined): TemplateCatalog.Ensemble[] {
    const sortedEnsembles: TemplateCatalog.Ensemble[] = [];

    ensembles?.forEach((e) => {
        if (e.designProperties.isProminentColorVariant) {
            sortedEnsembles.unshift(e);
        } else {
            sortedEnsembles.push(e);
        }
    });

    return sortedEnsembles;
}

interface Props {
    replacement: Replacement;
    designUseCases: DesignUseCase.DesignUseCaseDto[];
    designPhysicalSpecs: DesignPhysicalSpec.DesignPhysicalSpecDto[];
    onChangeReplacementStatus: (oldToken: string, status: ReplacementStatus, correlationId?: string, errorText?: string) => void;
}

const LineReplacement = ({
    replacement, designUseCases, designPhysicalSpecs, onChangeReplacementStatus,
}: Props): JSX.Element => {
    const [oldEnsembles] = useState<TemplateCatalog.Ensemble[]>(sortProminentToFront(replacement.oldLine?.ensembles ?? []));
    const [newEnsembles, setNewEnsembles] = useState<TemplateCatalog.Ensemble[]>(
        sortProminentToFront(replacement.newLine?.ensembles ?? []),
    );
    const [approved, setApproved] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        const reorderedEnsembles = Array.from(newEnsembles);
        const [removed] = reorderedEnsembles.splice(result.source.index, 1);

        reorderedEnsembles.splice(result.destination.index, 0, removed);

        setNewEnsembles(reorderedEnsembles);
    };

    const onApprovalChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setApproved(e.currentTarget.checked);
    };

    const buildApiInput = (): ReplaceLineInputItem[] => oldEnsembles.map((oldEnsemble, index) => ({
        oldEnsembleId: oldEnsemble.ensembleId,
        newEnsembleId: newEnsembles[index].ensembleId,
        isProminentVariant: index === 0,
    }));

    const confirmReplacement = async (): Promise<string | undefined> => {
        const newToken = newEnsembles[0].templates[0].templateToken;
        const response = await getEnsemblesContainingTemplateToken(newToken);

        if (!response || response.length === 0) {
            return 'Failed to retrieve updated template from Template Catalog. Replacement status unknown.';
        }

        if (response.length > 1) {
            return `New template ${newToken} exists in multiple ensembles. Replacement status cannot be verified.`;
        }

        if (response[0].ensembleId !== oldEnsembles[0].ensembleId) {
            return 'Verification of replacement failed. Reason unknown.';
        }

        return undefined;
    };

    const onSubmit = async (): Promise<void> => {
        setIsLoading(true);

        const response = await performLineReplacement(buildApiInput(), replacement.oldLine?.ensembleLineId || '');

        let { error } = response;

        if (!error) {
            error = await confirmReplacement();
        }

        setOpen(false);
        setIsLoading(false);

        if (error) {
            onChangeReplacementStatus(replacement.oldToken, 'failure', response.correlationId, error);
        } else {
            onChangeReplacementStatus(replacement.oldToken, 'success', response.correlationId);
        }
    };

    const getPreviewClassName = (): string => {
        const dpsId = replacement.oldLine?.ensembles[0].templates[0].designPhysicalSpecId
        ?? replacement.newLine?.ensembles[0].templates[0].designPhysicalSpecId;

        if (!dpsId) {
            return '';
        }

        const dps = designPhysicalSpecs.find((d) => d.id === dpsId);

        const trimSize = dps?.spec.outlines.trim.sizeInTwips;

        return trimSize && trimSize.height / trimSize.width > 1 ? 'portrait' : 'landscape';
    };

    const getPrimaryTemplateOriginalId = (): string | undefined => replacement.newLine
        ?.ensembles.filter((e) => e.designProperties.isProminentColorVariant)[0]
        ?.templates.filter((t) => t.ensemblePanelType === 'front')[0]
        ?.templateId;

    return (
        <Accordion
            title={<AccordianTitle
                oldTemplateToken={replacement.oldToken}
                newTemplateToken={replacement.newToken}
                status={replacement.status}
            />}
            headerStyle={{ background: 'white' }}
            customOpen={open}
            onHeaderClick={(): void => setOpen(!open)}
        >
            <Grid container direction="row" justify="space-evenly" spacing={3}>
                {replacement.status !== 'already replaced' && <Grid item xs={6}>
                    <h3>Old Ensembles</h3>
                    <p>Token: {replacement.oldToken}</p>
                    <Card>
                        {replacement.oldLine && <LineDetails
                            ensembleLine={replacement.oldLine}
                            designUseCases={designUseCases}
                            designPhysicalSpecs={designPhysicalSpecs}
                        />}
                        {replacement.status === 'pending' && <small>Old line ensembles cannot be reordered. Prominent variant cannot be changed.</small>}
                        <Grid container justify="space-evenly" spacing={1} direction="column">
                            {oldEnsembles
                                .map((e, index) => (
                                    <EnsemblePreview
                                        className={getPreviewClassName()}
                                        key={e.ensembleId}
                                        ensemble={e}
                                        isProminent={index === 0}
                                    />))}
                        </Grid>
                    </Card>
                </Grid>}
                <Grid item xs={6}>
                    <h3>New Ensembles</h3>
                    <p>Token: {replacement.newToken}</p>
                    <Card className={replacement.status === 'pending' ? 'draggable' : ''}>
                        {replacement.newLine && <LineDetails
                            ensembleLine={replacement.newLine}
                            designUseCases={designUseCases}
                            designPhysicalSpecs={designPhysicalSpecs}
                        />}
                        {replacement.status === 'pending' && <small>Drag and drop new line ensembles below to match color variant order in old line.</small>}
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="new-ensembles">
                                {(provided): ReactElement => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <Grid container justify="space-evenly" spacing={1} direction="column">
                                            {newEnsembles.map((ensemble, index) => (
                                                <Grid item key={ensemble.ensembleId}>
                                                    <Draggable
                                                        key={ensemble.ensembleId}
                                                        draggableId={ensemble.ensembleId}
                                                        index={index}
                                                        isDragDisabled={replacement.status !== 'pending'}
                                                    >
                                                        {(p): ReactElement => (
                                                            <div
                                                                ref={p.innerRef}
                                                                {...p.draggableProps}
                                                                {...p.dragHandleProps}
                                                            >
                                                                <EnsemblePreview
                                                                    className={getPreviewClassName()}
                                                                    ensemble={ensemble}
                                                                    isProminent={index === 0}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Card>
                </Grid>
                {replacement.status === 'pending'
                    && (isLoading
                        ? <div id="spinner"> <Spinner size="medium" /> </div>
                        : <Grid item container xs={12} direction="row">
                            <Grid item xs={12}>
                                <Checkbox className="approval-checkbox" label="I have reviewed the new and old ensemble lines and approve this replacement." checked={approved} onChange={onApprovalChange} />
                            </Grid>
                            <div className="accordion-buttons">
                                <Button variant="primary" disabled={!approved} onClick={onSubmit}>Replace</Button>
                                <Button variant="outline-secondary" onClick={(): void => setOpen(false)}>Cancel</Button>
                            </div>
                        </Grid>)}

                {replacement.status === 'success' && <Alert status="success" message={`Replacement successful. Original template id: ${getPrimaryTemplateOriginalId()}`} dismissible={false} />}
                {replacement.status === 'failure' && <Alert status="danger" message={<ReplacementError error={replacement.errorText} correlationId={replacement.correlationId} />} dismissible={false} />}
            </Grid>
        </Accordion>
    );
};

export default LineReplacement;
