import { Checkbox, Card } from '@cimpress/react-components';
import React, { useState } from 'react';
import { PreviewGenerationPanelData, PreviewGenerationResult } from './previewGenerationResultData';
import EnsembleLineGroupPanelPreview from './EnsembleLineGroupPanelPreview';
import PreviewGroupMetadata from './PreviewGroupMetadata';
import '../templateGeneration/generatedPreviewGrouping.scss';
import OriginalTemplatePanelPreview from './OriginalTemplatePanelPreview';

interface Props {
    ensembleLine: PreviewGenerationResult;
    onJobItemSelected: (id: string[], isSelected: boolean) => void;
    isSelected: boolean;
    width: number;
}
const panelOrder = ['front', 'insideFront', 'inside', 'insideBack', 'back'];
const getSortedPanels = (panels: PreviewGenerationPanelData[]): PreviewGenerationPanelData[] => {
    const sortedPanels: PreviewGenerationPanelData[] = [];

    panelOrder.forEach((panelName) => {
        const foundPanelType = panels.find((p) => p.panelType === panelName);

        if (foundPanelType) {
            sortedPanels.push(foundPanelType);
        }
    });
    return sortedPanels;
};

// Get Prominent Variant to display for the ensembleLine/jobItem.
const getProminentAndSortPanels = (ensembleLine: PreviewGenerationResult): PreviewGenerationPanelData[] => {
    const prominentPanels = ensembleLine.ensembles
        .find(((e) => e.isProminentVariant === true))?.panels || [] as PreviewGenerationPanelData[];

    return getSortedPanels(prominentPanels);
};

const EnsembleLinePreviewGroup = ({
    ensembleLine, onJobItemSelected, isSelected, width,
}: Props): JSX.Element => {
    const [prominentVariantPanels] = useState<PreviewGenerationPanelData[]>(getProminentAndSortPanels(ensembleLine));
    const labelId = `id_${Math.random()}`;

    return (<Card className="resize-preview-tile-container">
        <Checkbox
            className="composable-preview-checkbox"
            checked={isSelected}
            id={labelId}
            onChange={(): void => onJobItemSelected([ensembleLine.uniqueIdentifier as string], !isSelected)}
        />
        <div className="ensemble-preview-body">
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div className="ensemble-preview-body-panels">
                    <strong>Source Template</strong>
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                    }}
                    >
                        {prominentVariantPanels.map((panel) => (
                            <OriginalTemplatePanelPreview
                                key={panel.panelType}
                                panelType={panel.panelType}
                                sourceTemplateToken={ensembleLine.sourceTemplateToken}
                                width={width}
                            />
                        ))}
                        <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                            <span className="additional-info-property">Template Token:</span>
                            <span>{ensembleLine.sourceTemplateToken}</span>
                        </div>
                    </div>
                </div>
                <div className="ensemble-preview-body-panels">
                    <strong>Destination Template</strong>
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                    }}
                    >
                        {prominentVariantPanels.map((panel) => (
                            <EnsembleLineGroupPanelPreview
                                panel={panel}
                                key={panel.panelType}
                                width={width}
                            />
                        ))}
                        <PreviewGroupMetadata
                            target={ensembleLine.target}
                            ensembleCount={ensembleLine.ensembles.length}
                            multiColumn={prominentVariantPanels.length > 1}
                            dpsTrimDisplayText={ensembleLine.dpsTrim as string}
                            productName={ensembleLine.productName as string}
                        />
                    </div>
                </div>
            </div>
        </div>
    </Card>);
};

export default EnsembleLinePreviewGroup;
