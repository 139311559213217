/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid } from '@material-ui/core';
import './ensembleSearchResultPage.scss';
import EnsembleSearchResult from '../../components/searchEnsembles/ensembleSearchResult';

interface RouterLocationPartial {
    state: {
        ensembles?: TemplateCatalog.Ensemble[];
    };
}

interface Props {
    location: RouterLocationPartial;
}

const EnsembleSearchResultPage = ({ location }: Props): JSX.Element => {
    const resultEnsembles = location.state?.ensembles;

    // eslint-disable react/jsx-wrap-multilines
    return (
        <Grid container direction="column" justify="space-evenly" alignItems="stretch" spacing={1}>
            {resultEnsembles && resultEnsembles.length > 0
                && resultEnsembles.map((ensemble) => (<Grid item key={ensemble.ensembleId} data-testid={`search-result-${ensemble.ensembleId}`}><EnsembleSearchResult key={ensemble.ensembleId} ensemble={ensemble} /></Grid>))}
        </Grid>
    );
};

export default EnsembleSearchResultPage;
