/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Alert, Card } from '@cimpress/react-components';
import { Grid, Icon } from '@material-ui/core';
import Loading from '../../components/common/loading';
import { hasWritePermissions } from '../../auth';
import StepperLayout from '../../components/stepper/stepperLayout';
import StepDefinition from '../../components/stepper/stepDefinition';
import ResizeTemplateInput from '../../components/resizableTemplates/ResizeTemplateInput';
import {
    generatePreviews,
    TemplateGenerationResult,
    generateTemplatesFromApprovedPreviews,
    ApprovedPreviewConfiguration,
} from '../../services/rtgService';
import {
    PreviewGenerationResult,
    ResizerErrorWithId,
} from '../../components/resizableTemplates/previewGenerationResultData';
import { TargetProductSpecification } from '../../components/templateGeneration/TargetProductSpecification';
import ResizeTemplatePreview from '../../components/resizableTemplates/ResizeTemplatePreview';
import ResizeTemplateResults from '../../components/resizableTemplates/ResizeTemplateResults';
import './resizeTemplateStepsPage.scss';

enum ResizeStepStatus {
    Load,
    Error,
    Success
}

const ResizeTemplateStepsPage = (): JSX.Element => {
    const [hasAccess, setHasAccess] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [resizeErrors, setResizeErrors] = useState<{errors: ResizerErrorWithId[], templatesProcessed: number} | undefined>(undefined);
    const [previews, setPreviews] = useState<PreviewGenerationResult[]>([]);
    const [generationResults, setGenerationResults] = useState<TemplateGenerationResult>();
    const [currentStepStatus, setCurrentStepStatus] = useState<ResizeStepStatus>(1);

    useEffect(() => {
        hasWritePermissions().then((writePermissions: boolean) => {
            setHasAccess(writePermissions);
        });
    }, []);

    const onInputSubmit = async (tmplteTokens: string[], targetProductSpecifications: TargetProductSpecification[]): Promise<void> => {
        setCurrentStepStatus(ResizeStepStatus.Load);
        const generatePreviewsWithError = await generatePreviews(tmplteTokens, targetProductSpecifications);

        if (generatePreviewsWithError.ensembleLines.length > 0) {
            setPreviews(generatePreviewsWithError.ensembleLines);
            setActiveStep(1);
            setCurrentStepStatus(ResizeStepStatus.Success);
        } else {
            setActiveStep(0);
            setCurrentStepStatus(ResizeStepStatus.Error);
        }

        if (generatePreviewsWithError.errors.length > 0) {
            setResizeErrors({
                errors: generatePreviewsWithError.errors,
                templatesProcessed: generatePreviewsWithError.ensembleLines.length,
            });
        }
    };

    const onPreviewConfirmation = async (
        approvedPreviewConfigurations: ApprovedPreviewConfiguration[]):
    Promise<void> => {
        setActiveStep(2);
        setCurrentStepStatus(ResizeStepStatus.Load);
        const genResults = await generateTemplatesFromApprovedPreviews(approvedPreviewConfigurations, true);

        setGenerationResults(genResults);
        setCurrentStepStatus(ResizeStepStatus.Success);
    };

    const stepDefinition: StepDefinition[] = [
        {
            order: 0,
            name: 'First Step',
            title: 'Create and add configs',
            canBeSetByUser: true,
        },
        {
            order: 1,
            name: 'Second Step',
            title: 'Review and approve',
        },
        {
            order: 2,
            name: 'Third Step',
            title: 'Download results',
        },
    ];

    return hasAccess ? (
        <>
            {resizeErrors && (
                <Alert
                    dismissible
                    status={resizeErrors.templatesProcessed === 0 ? 'danger' : 'warning'}
                    title={`Error ${resizeErrors.templatesProcessed > 0 ? ' of processing some templates' : ''}`}
                    message={<>
                        <Grid className="errors-counter">
                            <Grid className="error-counter-row">
                                Total source templates - <p>{resizeErrors.errors.length + resizeErrors.templatesProcessed}</p>
                            </Grid>
                            <Grid className="error-counter-row">
                                Impossible to process - <p>{resizeErrors.errors.length}</p>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="errors-info" xs={12}>
                            {resizeErrors.errors.map((err) => (
                                <Grid className="error" key={err.id}>
                                    <p>{err.id}</p>{`${err.err.toString().slice(0, 151)}...`}
                                </Grid>
                            ))}
                        </Grid>
                    </>}
                />
            )}
            <Card>
                <Grid container>
                    <Grid item sm={2} />
                    <Grid item sm={8}>
                        <h2 className="page-title">New resize</h2>
                    </Grid>
                    <Grid item container direction="column" sm={2} className="resize-guide">
                        <a href="https://cimpress-support.atlassian.net/wiki/spaces/Content/pages/15252291759/How+to+generate+templates+through+resizing">
                            <Icon className="fa fa-info-circle info" />
                            <small>Resize guide</small>
                        </a>
                    </Grid>
                </Grid>

                <StepperLayout
                    activeStep={activeStep}
                    steps={stepDefinition}
                    onStepChange={setActiveStep}
                />

                {activeStep === 0 && currentStepStatus !== ResizeStepStatus.Load && (
                    <ResizeTemplateInput requestPreviews={onInputSubmit} />
                )}

                {currentStepStatus === ResizeStepStatus.Load && (
                    <div className="previews-generation-wrapper">
                        <Loading />
                        <h2>Generating resized previews...</h2>
                    </div>
                )}

                {activeStep === 1
                    && currentStepStatus === ResizeStepStatus.Success
                    && previews.length > 0 && (
                    <ResizeTemplatePreview previewGenerationResults={previews} onPreviewConfirmation={onPreviewConfirmation} />
                )}
                {activeStep === 2
                    && currentStepStatus === ResizeStepStatus.Success
                    && generationResults && (
                    <ResizeTemplateResults generationResults={generationResults} previewSpecifications={previews} />
                )}
            </Card>
        </>
    ) : (
        <span>Access Denied</span>
    );
};

export default ResizeTemplateStepsPage;
