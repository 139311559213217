import React from 'react';
import { PreviewGenerationResult } from './previewGenerationResultData';
import EnsembleLinePreviewGroup from './EnsembleLinePreviewGroup';
import '../templateGeneration/previewGroup.scss';

interface Props {
    previewGroups: PreviewGenerationResult[];
    onJobItemSelected: (id: string[], isSelected: boolean) => void;
    selectedJobItems: string[];
    width: number;
}

const PreviewGroupsBySourceDesign = ({
    previewGroups, onJobItemSelected, selectedJobItems, width,
}: Props): JSX.Element => (<div className="composed-previews-overview-section">
    <div className="composed-previews-overview-container">
        {previewGroups.map((previewGroup) => (<EnsembleLinePreviewGroup
            key={previewGroup.uniqueIdentifier}
            ensembleLine={previewGroup}
            isSelected={selectedJobItems.some((selectedJobItem) => selectedJobItem === previewGroup.uniqueIdentifier)}
            width={width}
            onJobItemSelected={onJobItemSelected}
        />))}
    </div>
</div>);

export default PreviewGroupsBySourceDesign;
