import _, { Dictionary } from 'lodash';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, Checkbox } from '@cimpress/react-components';
import * as colors from '@cimpress/react-components/lib/colors';
import { Slider } from '@material-ui/core';
import { TargetProductSpecification } from '../templateGeneration/TargetProductSpecification';
import RejectJobConfirmationModal from '../templateGeneration/rejectJobConfirmationModal';
import ResizeTemplateJobLabel from '../templateGeneration/templateGenerationJobLabel';
import PreviewGroupsBySourceDesign from './PreviewGroupsBySourceDesign';
import { PreviewGenerationResult } from './previewGenerationResultData';
import { batchGetDps } from '../../services/dpsService';
import getProductName from '../../services/productService';
import { getDpsDisplayText } from '../../utilityHelpers';
import { ApprovedPreviewConfiguration } from '../../services/rtgService';
import '../templateGeneration/reviewAndApprove.scss';

interface Props {
    previewGenerationResults: PreviewGenerationResult[];
    onPreviewConfirmation:
    (approvedPreviewConfigurations: ApprovedPreviewConfiguration[]) => void;
}

const ResizeTemplatePreview = ({ previewGenerationResults, onPreviewConfirmation }: Props): JSX.Element => {
    const [wasPageReviewed, setWasPageReviewed] = useState(false);
    const [isRejectionModalVisible, setIsRejectionModalVisible] = useState(false);
    const [jobItemIds, setJobItemIds] = useState<string[]>([]);
    const [finalPreviewResults, setFinalPreviewResults] = useState<Dictionary<PreviewGenerationResult[]>>();
    const history = useHistory();

    // Named as JobItems in anticipation of when we are working with JobItems.
    // For now, these are actually ensembleLines, with a meaningless identifier to represent them
    const [selectedJobItems, setSelectedJobItems] = useState<string[]>([]);
    const [width, setWidth] = useState(200);

    useEffect(() => {
        if (finalPreviewResults) {
            /* eslint-disable-next-line */
            const groups = Object.entries(finalPreviewResults).flatMap(([sourceTT, previewGroups]) => previewGroups);
            const ids = groups.map((x) => x.uniqueIdentifier as string);

            setJobItemIds(ids);
        }
    }, [finalPreviewResults]);

    useEffect(() => {
        async function getDpsDataFromApi(): Promise<Map<string, string>> {
            // Fetch DPS data for each prominent ensemble to display Trim data and substrate color
            // Assumes same DPS for each panel in the prominent variant ensemble
            const dpsIds = previewGenerationResults.map((ensembleLine) => ensembleLine.ensembles
                .find(((e) => e.isProminentVariant === true))?.panels[0].designPhysicalSpecId);
            const uniqueDpsIds = [...new Set(dpsIds)];
            const dpsData = await batchGetDps(uniqueDpsIds as string[]);
            const trimMap = new Map<string, string>();

            dpsData.forEach((dps) => {
                trimMap.set(dps.id, getDpsDisplayText(dps));
            });
            return trimMap;
        }

        async function getProductDataFromApi(): Promise<Map<string, string>> {
            // Fetch each Product Name for each PRD to display
            const prds = previewGenerationResults.map((ensembleLine) => ensembleLine.target.product);
            const uniquePrds = [...new Set(prds)];
            const promises = uniquePrds.map((prd) => getProductName(prd));

            /* eslint-disable no-param-reassign */
            return Promise.all(promises).then((results) => {
                // Union all of the maps together
                const mergedMap = results.reduce((finalMap: Map<string, string>, oneResult) => {
                    if (oneResult) {
                        finalMap = new Map([...oneResult, ...finalMap]);
                    }
                    return finalMap;
                }, new Map<string, string>());

                return mergedMap;
            }).catch(() => new Map<string, string>());
            /* eslint-enable no-param-reassign */
        }

        // Adds the unique ID, Trim values and product name for display in the preview group. Also groups according to source TT
        /* eslint-disable-next-line */
        async function setPreviewGroupMetadata(dpsMapPromise:Promise<Map<string,string>>, productMapPromise:Promise<Map<string,string>>): Promise<void> {
            const dpsMap = await dpsMapPromise;
            const productNameMap = await productMapPromise;
            const generationResultsWithMetadata = previewGenerationResults.map((ensembleLine, index) => {
                const completeEnsembleLine = ensembleLine;
                const prominentVariantDps = completeEnsembleLine.ensembles
                    .find(((e) => e.isProminentVariant === true))?.panels[0].designPhysicalSpecId || '';

                completeEnsembleLine.uniqueIdentifier = index.toString();
                completeEnsembleLine.dpsTrim = dpsMap.get(prominentVariantDps);
                completeEnsembleLine.productName = productNameMap.get(completeEnsembleLine.target.product);
                return completeEnsembleLine;
            });

            const groupedBySourceTemplate = _.groupBy(generationResultsWithMetadata, 'sourceTemplateToken');

            setFinalPreviewResults(groupedBySourceTemplate);
        }
        const dpsDataMap = getDpsDataFromApi();
        const productDataMap = getProductDataFromApi();

        setPreviewGroupMetadata(dpsDataMap, productDataMap);
    }, [previewGenerationResults]);

    // Add or remove jobItems from the list of approved ids as they are selected
    const onJobItemSelected = (ids: string[], isSelected: boolean): void => {
        if (isSelected) {
            setSelectedJobItems([...new Set([...selectedJobItems, ...ids])]);
        } else {
            setSelectedJobItems([...new Set(selectedJobItems.filter((selectedJobItem) => !ids.includes(selectedJobItem)))]);
        }
    };

    // Final function on this page which kicks off the generation request
    const onContinue = (): void => {
        const approvedTokenTargetCombinations: Map<string, TargetProductSpecification[]> = new Map<string, TargetProductSpecification[]>();

        selectedJobItems.forEach((ji: string) => {
            // Fetch corresponding input of approved "jobItems" to be generated
            const previewResultToGenerate = previewGenerationResults[parseInt(ji, 10)];

            // Add each approved Source Template and Target Combination
            // We end up with a Map of approved Targets per each approved Source Template.
            const mapEntry = approvedTokenTargetCombinations.get(previewResultToGenerate.sourceTemplateToken);

            if (mapEntry) {
                mapEntry.push(previewResultToGenerate.target);
            } else {
                approvedTokenTargetCombinations.set(previewResultToGenerate.sourceTemplateToken, [previewResultToGenerate.target]);
            }
        });

        // Finally, turn the Map of approved Targets per Token to the appropriate type for generation
        /* eslint-disable implicit-arrow-linebreak */
        const approvedConfigurationsForGeneration = Array.from(approvedTokenTargetCombinations, (sourceAndTargetsCombo) =>
            ({ sourceTemplateToken: sourceAndTargetsCombo[0], targets: sourceAndTargetsCombo[1] } as ApprovedPreviewConfiguration));

        onPreviewConfirmation(approvedConfigurationsForGeneration);
    };

    // If the user rejects the whole job. Redirect to the start of the flow
    const onRejectConfirmed = (): void => {
        setIsRejectionModalVisible(false);
        // Reloads the page. Resize Tool is a single page app so there is nowhere else to redirect to
        history.go(0);
    };

    const onSelectAllOrUnselectAllClicked = (value: boolean): void => {
        if (jobItemIds) {
            onJobItemSelected(jobItemIds, value);
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleChangeWidth = (e: ChangeEvent<{}>, newValue: number | number[]): void => setWidth(newValue as number);

    return (
        <div>
            <div className="job-details-header">
                { /* TODO: Add Job ID header here during phase 2 */ }
                <h3>
                    Job
                </h3>
                <ResizeTemplateJobLabel status="InReview" />
            </div>

            <div className="job-details-subheader">
                {/* eslint-disable-next-line */}
                Check all primary configurations to be generated. If there are issues with the resize, leave the configuration unchecked. Any unchecked configurations will not be created and the ensemble line will not be generated.
            </div>

            <div className="composed-previews-section">
                <div className="composed-previews-section-buttons-group">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span style={{ fontSize: 12 }}>Preview size, px:</span>
                        <Slider
                            style={{ width: 200 }}
                            min={100}
                            max={500}
                            step={100}
                            value={width}
                            valueLabelDisplay="auto"
                            onChange={handleChangeWidth}
                        />
                    </div>
                    <Button onClick={(): void => onSelectAllOrUnselectAllClicked(false)}>Unselect All</Button>
                    <Button onClick={(): void => onSelectAllOrUnselectAllClicked(true)}>Select All</Button>
                </div>
            </div>

            <div>
                {/* eslint-disable-next-line */}
                {finalPreviewResults && Object.entries(finalPreviewResults).map(([sourceTT, previewGroups]) => (<PreviewGroupsBySourceDesign 
                    previewGroups={previewGroups}
                    selectedJobItems={selectedJobItems}
                    width={width}
                    onJobItemSelected={onJobItemSelected}
                />))}
            </div>

            <div>
                <Checkbox
                    checked={wasPageReviewed}
                    label="By checking this box I confirm that all previews have been reviewed and that any checked meet Cimpress standards."
                    onChange={(): void => setWasPageReviewed(!wasPageReviewed)}
                />
            </div>
            <div className="approve-buttons-wrapper">
                {/* TODO: During Preview Phase 2, remove the below '|| selectedJobItems.length < 1' constraint. */}
                <Button
                    disabled={wasPageReviewed !== true || selectedJobItems.length < 1}
                    className="publish-button"
                    variant="primary"
                    size="lg"
                    onClick={(): void => onContinue()}
                >
                    Finish
                </Button>
                <small style={{ color: colors.shale, flex: 1 }}>{`${selectedJobItems.length} of ${previewGenerationResults.length} selected`}</small>
                <Button
                    variant="anchor"
                    onClick={(): void => setIsRejectionModalVisible(true)}
                >
                    Reject All
                </Button>
            </div>
            <RejectJobConfirmationModal
                isModalVisible={isRejectionModalVisible}
                isSaving={false}
                onSetModalVisible={setIsRejectionModalVisible}
                onConfirmed={onRejectConfirmed}
            />
        </div>
    );
};

export default ResizeTemplatePreview;
