import React, { useEffect, useState } from 'react';
import { JobItemSubItem } from '../../services/gamService';
import { getPreviewUrlUsingDocument } from '../../utils/templatePreviewUtility';
import '../templateGeneration/generatedEnsemblePanelPreview.scss';

interface Props {
    item: JobItemSubItem;
    panelSizes: Record<string, { width: number; height: number } | undefined>;
    enforceSingleColumn?: boolean;
}
const GeneratedEnsemblePanelPreview = ({ item, panelSizes, enforceSingleColumn = false }: Props): JSX.Element => {
    const [panelPreviewUrls, setPanelPreviewUrls] = useState<Map<string, string>>();

    useEffect(
        () => {
            async function setPreviewUrlsUsingDocument(): Promise<void> {
                const panelTypeToPreviewMap = new Map<string, string>();

                const urlPromises = item.panels.slice(0, 2).map(async (p) => {
                    const url = await getPreviewUrlUsingDocument(
                        p.design,
                        p.designPhysicalSpec.designPhysicalSpecId,
                        500,
                    );

                    panelTypeToPreviewMap.set(p.type.toLowerCase(), url);
                });

                await Promise.all(urlPromises);

                setPanelPreviewUrls(panelTypeToPreviewMap);
            }

            setPreviewUrlsUsingDocument();
        }, [item],
    );

    return (
        <div className={`ensemble-preview ${enforceSingleColumn ? 'ensemble-panel-preview-direction' : ''}`}>
            {panelPreviewUrls && item.panels.slice(0, 2).map((panel) => {
                const previewUrl = panelPreviewUrls.get(panel.type.toLowerCase());
                const panelSize = panelSizes[panel.type.toLowerCase()];
                const label = `${panel.type}`;

                return (<div className="ensemble-panel-tile" key={`${panel.designPhysicalSpec}${panel.type}`}>
                    <h3 className="ensemble-panel-title">{label}</h3>
                    <a href={previewUrl} target="_blank" rel="noopener noreferrer">
                        <img className="ensemble-panel-preview" src={previewUrl} alt="preview" />
                    </a>
                    {panelSize && <div className="ensemble-panel-size">{`${panelSize.width}mm x ${panelSize.height}mm`}</div>}
                </div>);
            })}
        </div>
    );
};

export default GeneratedEnsemblePanelPreview;
