import React, { useContext, useState, useEffect } from 'react';
import EnsembleDetailsCard from '../../components/browseEnsembles/ensembleDetailsCard';
import { getEnsembleById } from '../../services/dtecService';
import './ensembleDetailsPage.scss';
import BreadcrumbsConfigContext from '../../components/breadcrumbs/breadcrumbsConfigContextProvider';
import { searchDucs } from '../../services/ducService';
import { batchGetDps } from '../../services/dpsService';
import {
    getDpsDisplayText,
    getDpsDisplayColor,
    isAlphaNumeric,
} from '../../utilityHelpers';

interface RouterMatchPartial {
    params: {
        ensembleId: string;
    };
}
interface RouterLocationPartial {
    state: {
        ensemble?: TemplateCatalog.Ensemble;
    };
}
interface Props {
    location: RouterLocationPartial;
    match: RouterMatchPartial;
}

const EnsembleDetailsPage = ({ location, match }: Props): JSX.Element => {
    const [resultEnsemble, setResultEnsemble] = useState<TemplateCatalog.Ensemble | undefined>(undefined);
    const { setBreadcrumbsConfig } = useContext(BreadcrumbsConfigContext);
    const ensembleRef = location?.state?.ensemble;

    useEffect(() => {
        const getEnsembleByEnsembleId = async (): Promise<TemplateCatalog.Ensemble | undefined> => {
            // Get Ensemble ID from URL
            const { ensembleId } = match.params;

            // Query string input must be validated before being used
            if (!isAlphaNumeric(ensembleId)) {
                return undefined;
            }

            // Get the Ensemble Data from DTeC
            const ensembleData = await getEnsembleById(ensembleId);

            // Fetch the DUC names from service and set the name on the ensemble.
            if (ensembleData?.designUseCaseId) {
                const ducs = await searchDucs([ensembleData.designUseCaseId]);

                ensembleData.designUseCaseName = ducs[0]?.name;
            }

            // Get DPS Data and populate panels with it
            const dpsIds = ensembleData?.templates.map((t) => t.designPhysicalSpecId);
            const uniqueDpsIds = [...new Set(dpsIds)];
            const dpsData = await batchGetDps(uniqueDpsIds);

            /* eslint-disable no-param-reassign */
            ensembleData?.templates.forEach((p) => {
                const dpsForPanel = dpsData.find((d) => d.id === p.designPhysicalSpecId);

                p.dpsDisplayName = getDpsDisplayText(dpsForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                p.dpsDisplayColor = getDpsDisplayColor(dpsForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                p.dpsSubstrateColor = dpsForPanel?.spec.substrate.color ?? '';
            });
            /* eslint-enable no-param-reassign */

            return ensembleData;
        };

        async function setFromApi(): Promise<TemplateCatalog.Ensemble | undefined> {
            // Ensemble Data is either passed through from search or is missing because the URL was hit directly
            const ensemble = resultEnsemble ?? ensembleRef ?? await getEnsembleByEnsembleId();

            if (!resultEnsemble) {
                setResultEnsemble(ensemble);
            }
            return ensemble;
        }

        async function setBreadcrumbs(ensemblePromise: Promise<TemplateCatalog.Ensemble | undefined>): Promise<void> {
            const ensembleValue = await ensemblePromise;

            // Set Breadcrumbs when data is available
            if (ensembleValue) {
                setBreadcrumbsConfig({
                    designConceptId: ensembleValue?.designConceptId,
                    ensembleLineId: ensembleValue?.ensembleLineId,
                    ensembleId: ensembleValue?.ensembleId,
                });
            }
        }

        const foundEnsemble = setFromApi();

        setBreadcrumbs(foundEnsemble);
        // Nothing can change that would cause a need to call useEffect() again
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        <h2>Ensemble Details</h2>
        {resultEnsemble
            ? (<EnsembleDetailsCard key={resultEnsemble.ensembleId} data-testid="ensemble-details-card" ensemble={resultEnsemble} />)
            : (<p>Error getting ensemble from search or URL</p>)}
    </>);
};

export default EnsembleDetailsPage;
