import { Button, Modal, Toggle } from '@cimpress/react-components';
import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { PreferencesConfiguration } from './preferencesConfig';
import PreferencesConfigurationContext from './preferencesContext';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onPreferencesChange: (newConfig: PreferencesConfiguration) => void;
}

const PreferencesModal = ({ isOpen, onClose, onPreferencesChange }: Props): JSX.Element => {
    const preferencesConfig = useContext(PreferencesConfigurationContext);

    function onFakeFamilyToggle(): void {
        onPreferencesChange({ useFakeFamily: !preferencesConfig.useFakeFamily });
    }

    return (
        <Modal
            show={isOpen}
            title="User preferences"
            className="preferences-modal"
            footer={
                <Button className="btn btn-default" variant="default" onClick={onClose}>
                    Done
                </Button>
            }
            onRequestHide={onClose}
        >
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <p>
                        Preview fake families
                    </p>
                </Grid>
                <Grid item xs={4}>
                    <Toggle on={preferencesConfig.useFakeFamily} disabled={false} size="sm" data-testid="fake-family-toggle" onClick={onFakeFamilyToggle} />
                </Grid>
            </Grid>

        </Modal>
    );
};

export default PreferencesModal;
