/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Modal } from '@cimpress/react-components';
import React from 'react';

interface Props{
    isModalVisible: boolean;
    isSaving: boolean;
    onSetModalVisible: (value: boolean) => void;
    onConfirmed: () => void;
}

const RejectJobConfirmationModal = ({
    isModalVisible, isSaving, onSetModalVisible, onConfirmed,
}: Props): JSX.Element => <Modal
    closeButton
    show={isModalVisible}
    title="Reject this job?"
    footer={
        <>
            <Button disabled={isSaving} className="btn btn-default" onClick={() => onSetModalVisible(false)}>
                Cancel
            </Button>
            <Button disabled={isSaving} className="btn btn-danger" onClick={() => onConfirmed()}>
                Reject
            </Button>
        </>
    }
    onRequestHide={(): void => onSetModalVisible(false)}
>
    {'Are you sure you want to reject this job? '}
    <strong>No templates will be published.</strong>
</Modal>;

export default RejectJobConfirmationModal;
