import { Snackbar } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';

interface Props {
    message?: string;
}

const ApiErrorToast = ({ message }: Props): JSX.Element => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        if (message) {
            setShowSnackbar(true);
        }
    }, [message]);

    return message ? (
        <Snackbar
            show={showSnackbar}
            status="danger"
            onHideSnackbar={(): void => setShowSnackbar(false)}
        >
            {message}
        </Snackbar>
    ) : <></>;
};

export default ApiErrorToast;
