import axios from 'axios';
import auth from '../auth';
import { getEnvConfig } from '../envConfig';

function getBaseUri(): string {
    return `${getEnvConfig().productUrl}`;
}

function getMerchantUri(): string {
    return `${getEnvConfig().merchantUrl}`;
}

function getBesProductsUri(): string {
    return `${getEnvConfig().besProductsUrl}`;
}

// Obviously there are many more properties but this is all we need for now
interface ProductServiceResponseDTO {
    name: string;
}

interface ProductVersion {
    version: number;
}

export interface Version {
    version: number;
    current: boolean;
    status: string;
}

export interface RootProduct {
    productId: string;
    version: number;
    name?: string;
    versions?: Version[];
    currentVersion?: number;
}

export const getCurrentProductVersion = async (id: string): Promise<ProductVersion> => {
    const url = `https://${getMerchantUri()}/v3/products/${id}/versions/current`;
    const authToken = auth.getAccessToken();

    const response = await axios.get<ProductVersion>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    return response.data;
};

export default async function getProductName(id: string): Promise<Map<string, string> | undefined> {
    const url = `https://${getBaseUri()}/v1/products/${id}:latest`;
    // Use user's JWT token for authorization
    const authToken = auth.getAccessToken();
    const response = await axios.get<ProductServiceResponseDTO>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    if (!response || response.status > 299) {
        return undefined;
    }
    const productNameMap: Map<string, string> = new Map();

    productNameMap.set(id, response.data.name);
    return productNameMap;
}

export const getProductVersions = async (id: string): Promise<Record<string, Record<string, Version[]>>> => {
    const versionsURL = `https://${getBaseUri()}/v1/products/${id}/versions`;

    const authToken = auth.getAccessToken();

    const response = await axios.get<Record<string, Record<string, Version[]>>>(versionsURL, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    return response.data;
};

export const getProductVersionsByPRD = async (id: string): Promise<Record<string, Version[]>> => {
    const versionsURL = `https://${getMerchantUri()}/v3/products/${id}/versions/status:all`;

    const authToken = auth.getAccessToken();

    const response = await axios.get<Record<string, Version[]>>(versionsURL, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    return response.data;
};

export const getProductBySKU = async (id: string, isPRDRequest: boolean): Promise<RootProduct> => {
    const productUrl = `https://${getBaseUri()}/v1/products/${id}:current`;
    const authToken = auth.getAccessToken();
    const productResponse = await axios.get<RootProduct>(productUrl, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    let response = {
        ...productResponse.data,
    };

    if (!isPRDRequest) {
        const versions = await getProductVersions(id);

        response = {
            ...response,
            // eslint-disable-next-line no-underscore-dangle
            versions: versions._embedded.item,
            currentVersion: response.version,
        };
    }

    return response;
};

export const getProduct = async (id: string, versionProp?: number): Promise<RootProduct> => {
    const { version } = await getCurrentProductVersion(id);
    const SKUUrl = `https://${getBesProductsUri()}/api/v1/products/${id}/${versionProp || version}/rootproduct`;
    const authToken = auth.getAccessToken();
    const SKUResponse = await axios.get<RootProduct>(SKUUrl, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    const product = await getProductBySKU(SKUResponse.data.productId, true);
    const versions = await getProductVersionsByPRD(id);

    return {
        currentVersion: version,
        versions: versions.versionContextualStatus.reverse(),
        ...product,
        version: versionProp || version,
        productId: SKUResponse.data.productId,
    };
};

export const getProductByVersion = async (id: string, propVersion: number): Promise<RootProduct> => {
    const { version } = await getProductBySKU(id, false);
    const productUrl = `https://${getBaseUri()}/v1/products/${id}/versions/${propVersion}`;
    const authToken = auth.getAccessToken();
    const productResponse = await axios.get<RootProduct>(productUrl, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        validateStatus: () => true,
    });

    return {
        ...productResponse.data,
        currentVersion: version,
    };
};
