import React from 'react';
import ObjCsv from 'objects-to-csv';
import { Button } from '@cimpress/react-components';

const DownloadSampleCsvComponent = (): JSX.Element => {
    const downloadSampleCsv = async (): Promise<void> => {
        const sampleData = [
            {
                product: 'CIM-MK50GZPX',
                productOptions: {
                    finish: 'Matte',
                    size: '3"x5"',
                    Shape: 'Oval',
                    Material: 'White Paper',
                },
                orientation: 'horizontal',
                substrateColor: 'RGB(255,255,255)',
                substrateOpacity: 'opaque',
                designUseCaseId: 'ComposableTemplate_Sticker',
            },
            {
                product: 'CIM-MK50GZPX',
                productOptions: {
                    finish: 'Matte',
                    size: '3"x3"',
                    Shape: 'Circle',
                    Material: 'White Paper',
                },
                orientation: 'horizontal',
                substrateColor: 'RGB(255,255,255)',
                substrateOpacity: 'opaque',
                designUseCaseId: 'ComposableTemplate_Sticker',
            },
        ];

        // Download csv in utf-8-BOM encoding
        const csvData = new ObjCsv(sampleData);
        const generatedCsv = new File([await csvData.toString()],
            `composable-template-${new Date().toJSON().replaceAll(/-|:|\.|Z/g, '').replace('T', '_')}.csv`, {
                type: 'text/csv',
            });
        const element = document.createElement('a');
        const universalBOM = '\uFEFF';

        element.href = URL.createObjectURL(new Blob([universalBOM, generatedCsv], {
            type: 'text/csv;charset=utf-8',
        }));
        element.download = generatedCsv?.name || '';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (<Button variant="default" size="sm" onClick={downloadSampleCsv}>Download sample CSV</Button>);
};

export default DownloadSampleCsvComponent;
