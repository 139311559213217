import axios, { AxiosResponse } from 'axios';
import auth from '../auth';
import { getEnvConfig } from '../envConfig';
import { delayAwait } from '../utilityHelpers';

const prepressUrl = 'https://print.prepress.cimpress.io/v2/print-prep?asynchronous=true&noRedirect=true&withoutRetry=true';

function getinstructionsDocumentsUrl(): string {
    return `${getEnvConfig().instructionsDocumentsUrl}`;
}

function getTemplateRealizationUrl(): string {
    return `${getEnvConfig().dterUrl}`;
}

/* eslint-disable no-await-in-loop */
async function pollForCompletion(pollingUrl: string): Promise<string | undefined> {
    const startTime = Date.now();
    let pollResponse: AxiosResponse | null = null;

    while (startTime + getEnvConfig().prepressAsyncPollingTimeout > Date.now()) {
        await delayAwait(Date.now(), getEnvConfig().asyncPollingInterval);

        pollResponse = await axios.get(pollingUrl, {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
            },
            validateStatus: () => true,
        });

        if (!pollResponse || pollResponse.status > 299 || !pollResponse?.data?.Status) {
            return Promise.reject(new Error(`${pollingUrl} failed: Error polling Prepress Status`));
        }

        /* eslint-disable indent */
        switch (pollResponse.data.Status) {
            case 'Completed': {
                return pollResponse.data.Output;
            }
            case 'Failed': {
                return Promise.reject(new Error(`${pollingUrl} : ${pollResponse.data.Status} - Message: ${pollResponse.data.Exception.Message}`));
            }
            default: {
                break;
            }
        } /* eslint-enable indent */
    }
    // Timeout
    return Promise.reject(new Error(`Request timed out: ${pollingUrl} : ${pollResponse?.data.Status}`));
}
/* eslint-enable no-await-in-loop */

/**
 * For downloading the high resolution pdf of a template
 * @param templateToken: The templateToken of the template we want to download
 * @returns: the url of the high-resolution template pdf
 */
export default async function downloadHighResolutionPDFByTemplateToken(templateToken: string):
Promise<string | undefined> {
    const authenticationToken = auth.getAccessToken();

    /* eslint-disable react/jsx-one-expression-per-line */
    /* eslint-disable react/jsx-boolean-value */

    if (typeof authenticationToken === 'undefined') {
        // eslint-disable-next-line
        window.alert('Failed to fetch authentication token.');
        return undefined;
    }

    // Step1: Get template realization url
    const templaterealizationUrl = `https://${getTemplateRealizationUrl()}/api/v2/templates/${templateToken}/cultures/en-us/renderDocument`;

    // Step2: Get document Instructions Url
    const documentInstructionsUrl = `https://${getinstructionsDocumentsUrl()}/v3/instructions:drawing?documentUri=${encodeURIComponent(templaterealizationUrl)}`;

    const input = {
        DocumentInstructionsUrl: documentInstructionsUrl,
        timeout: 180000,
    };

    // Step3: make async api call to generate pdf, set the post timeout to 3mins
    const initialPrepressReponse = await axios.post(prepressUrl, input, {
        headers: {
            Authorization: `Bearer ${authenticationToken}`,
            'content-type': 'application/json',
            Accept: 'application/json',
        },
        validateStatus: () => true,
    });

    if (!initialPrepressReponse || initialPrepressReponse.status > 299) {
        // eslint-disable-next-line
        window.alert('An exception occurred when calling prepress.documents.cimpress.io API');
    }

    if (!initialPrepressReponse || !initialPrepressReponse?.data?.ResultUrl) {
        // eslint-disable-next-line
        window.alert('Failed to call prepress.documents.cimpress.io API.');
        return undefined;
    }

    // Step 4 Poll for Completion
    const pollingOutput = await pollForCompletion(initialPrepressReponse.data.ResultUrl) || '';

    if (!pollingOutput) {
        // eslint-disable-next-line
        window.alert('Prepress API returned empty or bad result');
        return undefined;
    }

    // Step 5 Extract the response
    const synchronousPrepressReponse = await axios.get(pollingOutput)
        .then((res) => res)
        .catch(() => undefined);

    if (typeof synchronousPrepressReponse === 'undefined') {
        // eslint-disable-next-line
        window.alert('Failed to extract hi-res pdf url from the API call response.');
        return undefined;
    }

    return synchronousPrepressReponse?.data?.PdfUrl;
}
