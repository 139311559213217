import _ from 'lodash';
import React from 'react';
import { Card } from '@cimpress/react-components';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import PublishedPreviewGroup from '../../components/composableTemplates/publishedPreviewGroup';
import ComposeTemplateJobLabel from '../../components/templateGeneration/templateGenerationJobLabel';
import ReRunCompositionJob from '../../components/composableTemplates/reRunCompositionJob';

interface Props {
    job: GeneratedArtworkJobDetail;
}

const PublishedJobDetailPage = ({ job }: Props): JSX.Element => {
    const published = _.filter(job.items, (item) => item.status === 'Published');
    const rejected = _.filter(job.items, (item) => item.status !== 'Published');

    return (
        <div>
            <Card>
                <div className="job-details-header">
                    <h3>{`Job ID: ${job.id}`}</h3>
                    <ComposeTemplateJobLabel status={job.status} />
                    <ReRunCompositionJob job={job} />
                </div>
            </Card>
            <div>
                <h2>Published templates</h2>
                <div>
                    {/* eslint-disable-next-line */}
                    {Object.entries(_.groupBy(published, 'jobItemInput.designConcept')).map(([dc, jobItems], i) => (<PublishedPreviewGroup dc={dc} jobItems={jobItems} key={i} />),)}
                </div>

            </div>
            {rejected.length > 0 && <div>
                <h2>Not approved templates</h2>
                <div>
                    {/* eslint-disable-next-line */}
                    {Object.entries(_.groupBy(rejected, 'jobItemInput.designConcept')).map(([dc, jobItems], i) => (<PublishedPreviewGroup dc={dc} jobItems={jobItems} key={i} />),)}
                </div>
            </div>}
        </div>
    );
};

export default PublishedJobDetailPage;
