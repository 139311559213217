export interface TargetProductSpecification {
    index?: string;
    product: string;
    productOptions: string;
    orientation: string;
    substrateColor: string;
    substrateOpacity: string;
    designUseCaseId: string;
}

export interface ValidationResult {
    isSuccessful?: boolean;
    errorMessages?: string[];
}

const requiredFields = ['product', 'productOptions', 'orientation', 'substrateColor', 'substrateOpacity', 'designUseCaseId'];

export function areAllRequiredFieldsPresent(inputSpecifications: TargetProductSpecification): ValidationResult {
    const validationResult: ValidationResult = { isSuccessful: true, errorMessages: [] };

    requiredFields.forEach((field) => {
        if (!(field in inputSpecifications)) {
            validationResult.isSuccessful = false;
            validationResult.errorMessages?.push(`Missing required field: ${field}.`);
        }
    });

    Object.entries(inputSpecifications).forEach(([property, val]) => {
        if ((requiredFields.indexOf(property) !== -1) && ((val === null) || (val === ''))) {
            validationResult.isSuccessful = false;
            validationResult.errorMessages?.push(`Empty required field: ${property}. `);
        }
    });

    return validationResult;
}
