import axios from 'axios';
import { getEnvConfig } from '../envConfig';

function getBaseUri(): string {
    return `${getEnvConfig().dpsUrl}`;
}

export async function getDps(id: string): Promise<DesignPhysicalSpec.DesignPhysicalSpecDto | undefined> {
    const url = `https://${getBaseUri()}/api/v2/DesignPhysicalSpecs/${id}?requestor=DTeC_UI`;

    const response = await axios.get<DesignPhysicalSpec.DesignPhysicalSpecDto>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
        },
        validateStatus: () => true,
    });

    if (!response) {
        return undefined;
    }

    return response.status > 299 ? undefined : response.data;
}

export async function batchGetDps(ids: string[]): Promise<DesignPhysicalSpec.DesignPhysicalSpecDto[]> {
    const url = `https://${getBaseUri()}/api/v2/DesignPhysicalSpecs/BulkGet?requestor=DTeC_UI`;

    const response = await axios.post<DesignPhysicalSpec.DesignPhysicalSpecDto[]>(url, ids, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
        },
        validateStatus: () => true,
    });

    if (!response) {
        return [];
    }

    return response.status > 299 ? [] : response.data;
}
