import { Select } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';
import { Tenant } from './TenantContext';
import './TenantPicker.scss';

interface Props {
    tenants: Tenant[];
    selected: Tenant;
    isReadOnly: boolean;
    onTenantSelected: (tenant: Tenant) => void;
}

const TenantPicker = ({
    tenants, selected, isReadOnly, onTenantSelected,
}: Props): JSX.Element => {
    const [availableTenants] = useState<Tenant[]>(tenants);
    const [tenant, setTenant] = useState<Tenant>(selected);

    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

    const tenantKey = (t: Tenant): string => `${t.contentAccountId}_${t.contentAreaId}`;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapTenantToSelectItem = (t: Tenant) => ({
        label: `${t.name}`,
        value: tenantKey(t),
    });

    useEffect(() => {
        setOptions(availableTenants.map((t) => mapTenantToSelectItem(t)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTenants]);

    const onChange = (option: { label: string; value: string }): void => {
        if (option) {
            const selectedTenant = availableTenants.find((t) => tenantKey(t) === option.value);

            if (selectedTenant) {
                setTenant(selectedTenant);
                onTenantSelected(selectedTenant);
            }
        }
    };

    return (
        <div>
            <div className="tenant-picker">
                <Select
                    isClearable={false}
                    label="Tenant"
                    value={mapTenantToSelectItem(tenant)}
                    options={options}
                    isDisabled={isReadOnly}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={onChange as any}
                />
            </div>
        </div>
    );
};

export default TenantPicker;
