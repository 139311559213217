import React from 'react';
import { TextField } from '@material-ui/core';
import './ColorInput.scss';

interface Props {
    color: string;
    setColor: (color: string) => void;
    isValidColor: boolean;
    setIsValidColor: (isValid: boolean) => void;
}
const ColorInput = ({
    color, setColor, isValidColor, setIsValidColor,
}: Props): JSX.Element => {
    const isValidSubstrateColor = (value: string): boolean => {
        if (!value) {
            return false;
        }

        const rgbString = /RGB\(\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b,\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b,\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b\)/;

        return rgbString.test(value);
    };

    const onChange = (value: string): void => {
        setColor(value);
        if (!isValidColor) {
            setIsValidColor(isValidSubstrateColor(value));
        }
    };

    const onBlur = (value: string): void => {
        setIsValidColor(isValidSubstrateColor(value));
    };

    return (
        <div className="color-input-container">
            <TextField
                required
                value={color}
                error={!isValidColor}
                className="color-input"
                label="RGB(255,255,255)"
                onChange={(event): void => onChange(event.target.value)}
                onBlur={(event): void => onBlur(event.target.value)}
            />
            <div className="color-preview" style={{ backgroundColor: color }} />
        </div>
    );
};

export default ColorInput;
