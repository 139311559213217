import { Tooltip } from '@cimpress/react-components';
import React from 'react';
import isLastPublishedTokenIsSameAsCurrentJobItem from '../../pages/composeTemplatePage/helpers/templateCheckUtility';
import { GeneratedArtworkJobItem } from '../../services/gamService';

interface Props {
    jobItem: GeneratedArtworkJobItem;
}
const DuplicateCheckmark = ({ jobItem }: Props): JSX.Element => {
    const isDuplicateTemplate = isLastPublishedTokenIsSameAsCurrentJobItem(jobItem);
    const checkContentValue = !isDuplicateTemplate ? {
        content: 'Another version is published',
        style: {
            color: 'orange',
            fontSize: '20px',
        },
    } : {
        content: 'Published',
        style: {
            color: 'green',
            fontSize: '20px',
        },
    };

    return (<Tooltip contents={checkContentValue.content} direction="right"><i className="fa fa-circle" style={checkContentValue.style} /></Tooltip>);
};

export default DuplicateCheckmark;
