import axios from 'axios';
import { getEnvConfig } from '../envConfig';
import auth from '../auth';

export interface ComboIdLookupResult {
    templateToken: string;
    statusCode: number;
    comboId: string;
}

function getBaseUri(): string {
    return `${getEnvConfig().dtecUrl}`;
}

export async function baseGet<T>(url: string): Promise<T | undefined> {
    const response = await axios.get<T>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
        },
        params: {
            requestor: getEnvConfig().requestorParam,
        },
        validateStatus: () => true,
    });

    if (!response) {
        return undefined;
    }

    return response.status > 299 ? undefined : response.data;
}

/**
 * Call DTeC API to search for template token by comboID
 * @param comboId: the comboID we want to transform
 * @returns a ComboIdLookupResult which contains template token and http status
 */
export async function getTemplateTokenByComboId(comboId: string):
Promise<ComboIdLookupResult> {
    // Hardcode the Vistaprint AccountID and ContentAreaID into the url
    // ComboId is a Vistaprint notion, so it is okay to always hardcode the Vistaprint Tenant into the URL
    // Even though Cimpress Accounts can use this feature we are assuming they are devs
    const url = `https://${getBaseUri()}/api/v4/accounts/ozoDdrmewShEcbUDWX8J3V/contentAreas/IoBX4KKMc0uyUyA0tFQDg/templateTokens:search`;

    // Use user's JWT token for authorization
    const authenticationToken = auth.getAccessToken();
    const headers = {
        Authorization: `Bearer ${authenticationToken}`,
    };

    return axios.get(url, {
        headers,
        params: {
            comboId,
            requestor: getEnvConfig().requestorParam,
            includeDeleted: true,
        },
    })
        .then((res) => (
            {
                templateToken: res.data,
                statusCode: res.status,
                comboId,
            }
        ))
        .catch((res) => (
            {
                templateToken: '',
                statusCode: res?.response?.status ?? 999,
                comboId,
            }
        ));
}

export async function getEnsemblesContainingTemplateToken(templateToken: string):
Promise<TemplateCatalog.Ensemble[] | undefined> {
    const url = `https://${getBaseUri()}/api/v4/templates/${templateToken.trim()}/ensembles?includeDeleted=true`;

    return baseGet<TemplateCatalog.Ensemble[]>(url);
}

export async function getEnsembleById(ensembleId: string): Promise<TemplateCatalog.Ensemble | undefined> {
    const url = `https://${getBaseUri()}/api/v4/ensembles/${ensembleId}`;

    return baseGet<TemplateCatalog.Ensemble>(url);
}

export async function getEnsembleLineById(ensembleLineId: string): Promise<TemplateCatalog.EnsembleLine | undefined> {
    const url = `https://${getBaseUri()}/api/v4/ensembleLines/${ensembleLineId}`;

    const response = await baseGet<TemplateCatalog.EnsembleLine>(url);

    if (!response) {
        return undefined;
    }
    response.ensembles.forEach((e) => {
        e.ensembleLineId = response.ensembleLineId;
        e.designUseCaseId = response.designUseCaseId;
        e.designConceptId = response.designConceptId;
    });

    return response;
}

export async function getEnsembleLinesByIds(ensembleLineIds: string[]): Promise<TemplateCatalog.EnsembleLine[] | undefined> {
    if (!ensembleLineIds || ensembleLineIds.length === 0) {
        return [];
    }

    const url = `https://${getBaseUri()}/api/v4/ensembleLines?${ensembleLineIds.map((id) => `ensembleLineIds=${id}`).join('&')}`;
    const response = await baseGet<TemplateCatalog.EnsembleLine[]>(url);

    if (!response) {
        return undefined;
    }
    response.forEach((el) => {
        el.ensembles.forEach((e) => {
            e.ensembleLineId = el.ensembleLineId;
            e.designUseCaseId = el.designUseCaseId;
        });
    });

    return response;
}

export async function getEnsembleLinesByDesignConceptId(designConceptId: string):
Promise<TemplateCatalog.EnsembleLine[] | undefined> {
    const url = `https://${getBaseUri()}/api/v4/designConcepts/${designConceptId}/ensembleLines`;

    const response = await baseGet<TemplateCatalog.EnsembleLine[]>(url);

    if (!response) {
        return undefined;
    }
    response.forEach((el) => el.ensembles.forEach((e) => {
        e.ensembleLineId = el.ensembleLineId;
        e.designUseCaseId = el.designUseCaseId;
        e.designConceptId = el.designConceptId;
    }));

    return response;
}

export async function getEnsembleLineByTemplateToken(token: string): Promise<TemplateCatalog.EnsembleLine | undefined> {
    const ensembleResponse = await getEnsemblesContainingTemplateToken(token);

    if (!ensembleResponse) {
        return undefined;
    }

    if (ensembleResponse.length !== 1) {
        return undefined;
    }

    const { ensembleLineId } = ensembleResponse[0];

    const lineResponse = await getEnsembleLineById(ensembleLineId);

    return lineResponse;
}
