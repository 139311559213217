import React, { useState } from 'react';
import { TextField } from '@cimpress/react-components';
import './templateTokensSelector.scss';
import { TextFieldProps } from '@cimpress/react-components/lib/TextField';
import { TemplateTokenRegularExpression } from '../../TemplateTokenUtils';
import { getEnsemblesContainingTemplateToken } from '../../services/dtecService';
import { splitInputByWhitespaceAndComma } from '../../utilityHelpers';

interface Props {
    initialValue: string[];
    onTemplateTokensChanged: (parsedValues: string[]) => void;
    label: string;
    validateTenant?: boolean;
    disabled?: boolean;
    setIsTokenValid?: (isValid: boolean) => void;
}

const tokenToTenantCache: {[key: string]: string} = {};

const tokensHaveMultipleTenants = async (tokens: string[]): Promise<boolean> => {
    const tenants = new Set();

    await Promise.all(tokens.map(async (token) => {
        if (tokenToTenantCache[token]) {
            tenants.add(tokenToTenantCache[token]);
        } else {
            const ensembles = await getEnsemblesContainingTemplateToken(token);

            if (ensembles && ensembles.length > 0) {
                tenants.add(ensembles[0].tenant.contentAreaId);
                tokenToTenantCache[token] = ensembles[0].tenant.contentAreaId;
            }
            // We don't need to error here if the ensemble isn't found,
            // the step function will take care of that
        }
    }));

    return tenants.size > 1;
};

const TemplateTokensSelector = ({
    initialValue,
    onTemplateTokensChanged,
    label,
    validateTenant = false,
    disabled = false,
    setIsTokenValid,
}: Props): JSX.Element => {
    const [value, setValue] = useState<string>(initialValue ? initialValue.join('\n') : '');
    const [isTemplateTokenTextFieldValid, setIsTemplateTokenValid] = useState<boolean | undefined>();
    const [errorText, setErrorText] = useState<string>();

    const onChanged = async (input: string): Promise<void> => {
        setErrorText('');
        setValue(input);
        const parsedValues = splitInputByWhitespaceAndComma(input);

        if (parsedValues.length === 0) {
            setIsTemplateTokenValid(undefined);
            setIsTokenValid?.(false);
        } else if (parsedValues.some((parsedValue) => !parsedValue.match(TemplateTokenRegularExpression))) {
            // if there is at least one parsed value which isn't a valid template token, then we show an error
            setIsTemplateTokenValid(false);
            setIsTokenValid?.(false);
            setErrorText('Input invalid. Please review and correct template tokens.');
        } else if (validateTenant && await tokensHaveMultipleTenants(parsedValues)) {
            setIsTemplateTokenValid(false);
            setIsTokenValid?.(false);
            setErrorText('Template tokens must all be for the same tenant. Please review and correct template tokens.');
        } else {
            setIsTemplateTokenValid(true);
            setIsTokenValid?.(true);
        }
        onTemplateTokensChanged(parsedValues);
    };

    const mapToTextAreaStatus = (): TextFieldProps['status'] => {
        if (isTemplateTokenTextFieldValid === undefined || value === '') {
            return '';
        }
        if (isTemplateTokenTextFieldValid) {
            return 'success';
        }
        return 'error';
    };

    return (
        <TextField
            className="template-tokens-input"
            type="textarea"
            name="layout-template-tokens-search"
            label={label}
            value={value || ''}
            data-testid="template-tokens-search-input"
            inputStyle={{
                minHeight: '100px',
                minWidth: '30%',
                maxWidth: '100%',
            }}
            status={mapToTextAreaStatus()}
            helpText={errorText}
            disabled={disabled}
            onChange={(e): Promise<void> => onChanged(e.target.value)}
        />
    );
};

export default TemplateTokensSelector;
