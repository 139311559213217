import { CimDoc, Panel } from '@design-stack-ct/cdif-types';

const roundToTwoDecimalPlaces = (num: number): number => Math.round(num * 100) / 100;

const getMillimeterSize = (size: string): number => {
    const regex = /(\d+(\.\d+)?)(mm|cm|m|in)/; // matching 123.123mm, 123m or 123.1cm
    const match = size.match(regex);

    if (match) {
        let value = parseFloat(match[1]);

        if (match[3] === 'cm') {
            value *= 10;
        } else if (match[3] === 'm') {
            value *= 1000;
        } else if (match[3] === 'in') {
            value *= 25.4;
        }
        return roundToTwoDecimalPlaces(value);
    }

    throw new Error("Invalid size format. Expected format: number + 'mm' or number + 'cm' or number + 'm' or number + 'in'");
};

/**
 * @param document cimdoc to extract panel sizes from
 * returns sizes of panels in milimeters
 */
export function extractPanelSizes(
    adtifJson: string,
): Record<string, { width: number; height: number } | undefined> {
    try {
        const adtif: CimDoc = JSON.parse(adtifJson);

        const { panels } = adtif.document;

        const panelsToSizes: Record<string, { width: number; height: number } | undefined> = {};

        panels.forEach((panel: Panel) => {
            if (panel.name) {
                try {
                    panelsToSizes[panel.name.toLowerCase()] = {
                        width: getMillimeterSize(panel.width),
                        height: getMillimeterSize(panel.height),
                    };
                } catch (error) {
                    panelsToSizes[panel.name.toLowerCase()] = undefined;
                }
            }
        });

        return panelsToSizes;
    } catch (error) {
        return {};
    }
}

export default extractPanelSizes;
