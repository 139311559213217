import { Label } from '@cimpress/react-components';
import React from 'react';
import { GeneratedArtworkJobStatus } from '../../services/gamService';

interface Props {
    status: GeneratedArtworkJobStatus;
}

type JobStatusLabel = {
    text: string;
    status: 'danger' | 'default' | 'info' | 'primary' | 'success' | 'warning';
};

const jobStatuses = new Map<GeneratedArtworkJobStatus, JobStatusLabel>([
    ['InProgress', { text: 'Generating Previews', status: 'info' }],
    ['InReview', { text: 'Pending approvals', status: 'warning' }],
    ['Published', { text: 'Published', status: 'success' }],
    ['Closed', { text: 'Closed', status: 'default' }],
    ['Failed', { text: 'Failed', status: 'danger' }],
]);

const TemplateGenerationJobLabel = ({ status }: Props): JSX.Element => {
    const jobStatus = jobStatuses.get(status) as JobStatusLabel;

    return (
        <Label text={jobStatus.text} size="lg" status={jobStatus.status} />
    );
};

export default TemplateGenerationJobLabel;
