import React from 'react';

export interface Tenant {
    name: string;
    contentAccountId: string;
    contentAreaId: string;
}

export const defaultTenant: Tenant = {
    name: 'Vistaprint: Vistaprint Production',
    contentAccountId: 'ozoDdrmewShEcbUDWX8J3V',
    contentAreaId: 'IoBX4KKMc0uyUyA0tFQDg',
};

export const availableTenantList: Tenant[] = [
    defaultTenant,
    {
        name: 'Cimpress Technology: Content Solution Test Tenant',
        contentAccountId: 'g2Ez5VaoZWoqU22XqPjTLU',
        contentAreaId: 'V0DJMs5nByPR6UD',
    },
    {
        name: 'Pixart',
        contentAccountId: 'sUwJnco1V7RtqbL6W8DuQ8',
        contentAreaId: 'S58aw8ckDU6NagJ9pUUDRg',
    },
];

export const TenantContext = React.createContext<{ tenant: Tenant; availableTenants: Tenant[] }>({
    tenant: defaultTenant,
    availableTenants: availableTenantList,
});

const parseTenantFromPathname = (pathname: string): Tenant | undefined => {
    const pathParts = pathname.split('/');

    // Expecting structure of url like: /composeJobDetails/{accountId}/{contentAreaId}/{jobDetailId}
    // To be able to get accountId and contentAreaId out or url, we need to first find composeJobDetails part
    // and then (only if it exists), we take the next two parts (e.g. +1 for accountId and +2 for contentAreaId)
    const composeJobDetailsPart = 'composeJobDetails';

    if (pathParts.length < pathParts.indexOf(composeJobDetailsPart) + 2) {
        return undefined;
    }

    const accountId = pathParts[pathParts.indexOf(composeJobDetailsPart) + 1];
    const contentAreaId = pathParts[pathParts.indexOf(composeJobDetailsPart) + 2];

    return availableTenantList.find((t) => t.contentAreaId === contentAreaId && t.contentAccountId === accountId);
};

export const getDefaultTenant = (pathname: string): Tenant => parseTenantFromPathname(pathname) || defaultTenant;
