import { Card } from '@cimpress/react-components';
import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbsConfigContext from '../../components/breadcrumbs/breadcrumbsConfigContextProvider';
import { searchDucs } from '../../services/ducService';
import { batchGetDps } from '../../services/dpsService';
import { getDpsDisplayText, getDpsDisplayColor, isAlphaNumeric } from '../../utilityHelpers';
import { getEnsembleLineById } from '../../services/dtecService';
import EnsembleLineDetailsCard from '../../components/browseEnsembles/ensembleLineDetailsCard';
import './ensembleLineDetailsPage.scss';

interface RouterMatchPartial {
    params: {
        ensembleLineId: string;
    };
}
interface RouterLocationPartial {
    state: {
        ensembleLine?: TemplateCatalog.EnsembleLine;
    };
}
interface Props {
    location: RouterLocationPartial;
    match: RouterMatchPartial;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
const EnsembleLineDetailsPage = ({
    match, location,
}: Props): JSX.Element => {
    const [resultEnsembleLine, setResultEnsembleLine] = useState<TemplateCatalog.EnsembleLine | undefined>(undefined);
    const { setBreadcrumbsConfig } = useContext(BreadcrumbsConfigContext);
    const ensembleLineRef = location?.state?.ensembleLine;
    const { ensembleLineId } = match.params;

    useEffect(() => {
        async function setFromApi(): Promise<TemplateCatalog.EnsembleLine | undefined> {
            // Query string input must be validated before being used
            if (!isAlphaNumeric(ensembleLineId)) {
                return undefined;
            }
            // Ensemble Line data may have been passed through state
            const ensembleLine = ensembleLineRef ?? await getEnsembleLineById(ensembleLineId);
            const templateToCheckForData = ensembleLine?.ensembles[0]?.templates[0];

            // Check if DPS data was passed through state already to avoid api calls
            if (!templateToCheckForData?.dpsDisplayColor || !templateToCheckForData?.dpsDisplayName || !templateToCheckForData?.dpsSubstrateColor) {
                // Fetch DPS data to assign Panel DPS Details
                const dpsIds = ensembleLine?.ensembles.map((e) => e.templates.map((t) => t.designPhysicalSpecId)).flat();
                const uniqueDpsIds = [...new Set(dpsIds)];
                const dpsData = await batchGetDps(uniqueDpsIds as string[]);

                ensembleLine?.ensembles.forEach((e) => e.templates.forEach((p) => {
                    const dpsDataForPanel = dpsData.find((d) => d.id === p.designPhysicalSpecId);

                    p.dpsDisplayName = getDpsDisplayText(dpsDataForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                    p.dpsDisplayColor = getDpsDisplayColor(dpsDataForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                    p.dpsSubstrateColor = dpsDataForPanel?.spec.substrate.color ?? '';
                }));
            }

            // Fetch the DUC name if it wasn't passed through the state
            if (ensembleLine?.designUseCaseId && !ensembleLine.designUseCaseName) {
                const ducs = await searchDucs([ensembleLine.designUseCaseId]);

                ensembleLine.designUseCaseName = ducs[0]?.name;
            }

            setResultEnsembleLine(ensembleLine);
            return ensembleLine;
        }

        async function setBreadcrumbs(ensembleLinePromise: Promise<TemplateCatalog.EnsembleLine | undefined>): Promise<void> {
            const ensembleLine = await ensembleLinePromise;

            // Set Breadcrumbs when data is available
            if (ensembleLine) {
                setBreadcrumbsConfig({
                    designConceptId: ensembleLine.designConceptId,
                    ensembleLineId: ensembleLine.ensembleLineId,
                    ensembleId: undefined,
                });
            }
        }

        const foundEnsembleLine = setFromApi();

        setBreadcrumbs(foundEnsembleLine);
        // eslint wants breadcrumbsConfig as a dependency but function dependencies cause duplicate calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ensembleLineRef]);

    return (<>
        <h2>Ensemble Line Details</h2>
        {resultEnsembleLine?.ensembles
        && (
            <Card>
                <EnsembleLineDetailsCard
                    ensembleLine={resultEnsembleLine}
                    minified={false}
                />
            </Card>)}
    </>);
};
/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable react/jsx-boolean-value */
/* eslint-enable no-param-reassign */
/* eslint-enable max-len */

export default EnsembleLineDetailsPage;
