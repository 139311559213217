// Generated Artwork Manager service

import { getEnvConfig } from '../envConfig';
import { TargetProductSpecification } from '../pages/composeTemplatePage/TargetProductSpecification';
import * as RequestUtils from './RequestUtils';
import { delayAwait } from '../utilityHelpers';
import { ApiResponseOrError } from './ApiResponseOrError';
import { Tenant } from '../components/composableTemplates/TenantContext';

function getBaseUri(): string {
    return `https://${getEnvConfig().gamUrl}/api/v1`;
}

function getTenantedBaseUri(tenant: Tenant): string {
    return `${getBaseUri()}/${tenant.contentAccountId}/${tenant.contentAreaId}`;
}

export type GeneratedArtworkJobKind = 'ComposableTemplates' | 'Resize';

export type GeneratedArtworkJobStatus = 'InProgress' | 'InReview' | 'Published' | 'Closed' | 'Failed';

export interface GeneratedArtworkJobBase {
    id: string;
    jobKind: GeneratedArtworkJobKind;
    created: string;
    finished?: string;
    createdBy: string;
    status: GeneratedArtworkJobStatus;
    rawInput: string;
    errorDetails: GeneratedArtworkJobError;
}

export interface GetJobsResponse {
    jobs: GeneratedArtworkJob[];
    count: number;
}

export interface GeneratedArtworkJob extends GeneratedArtworkJobBase{
    itemsCount: number;
}
export interface GeneratedArtworkJobDetail extends GeneratedArtworkJobBase{
    items: GeneratedArtworkJobItem[];
}

export interface GeneratedArtworkJobError{
    errorName?: string;
    errorMessage?: string;
}

export interface JobItemInput {
    product: string;
    productOptions: { [key: string]: string };
    sourceTemplateTokens: string[];
    designConcept: string;
    designUseCaseId: string;
    strategist: string;
}

export interface DesignPhysicalSpec {
    designPhysicalSpecId: string;
    orientation: string;
    substrateColor: string;
    substrateOpacity: string;
}

export interface JobItemSubItemPanel {
    design: string;
    generatedTemplateToken?: string;
    type: 'Front' | 'Inside' | 'InsideFront' | 'InsideBack' | 'Back';
    templateId: string;
    designPhysicalSpec: DesignPhysicalSpec;
}

export interface JobItemSubItemComponent {
    type: string;
    originalSourceUrl: string;
}

export interface JobItemSubItem {
    generatedDocument: string;
    agsInput: string;
    panels: JobItemSubItemPanel[];
    components: JobItemSubItemComponent[];
}

export interface GeneratedArtworkJobItem {
    id: string;
    jobId: string;
    ducId: string;
    created: string;
    updated?: string;
    status: string;

    jobItemInput: JobItemInput;
    subItem: JobItemSubItem;
    previouslyPublishedSubItem?: JobItemSubItem;
}

export async function createNewJob(tenant: Tenant, products: TargetProductSpecification[], designConceptIds: string[], templateTokens: string[], strategist: string): Promise<ApiResponseOrError<GeneratedArtworkJobDetail>> {
    const url = `${getTenantedBaseUri(tenant)}/jobs/enqueue?requestor=${getEnvConfig().requestorParam}`;

    const body = {
        products: products.map((p) => ({
            ...p,
            productOptions: JSON.parse(p.productOptions),
        })),
        designConceptIds,
        templateTokens,
        strategist,
    };

    const resonse = await RequestUtils.doPostRequest<GeneratedArtworkJobDetail>(url, body);

    return resonse;
}

export async function saveTemplates(jobItemIds: string[]): Promise<ApiResponseOrError<GeneratedArtworkJobItem[]>> {
    const url = `${getBaseUri()}/jobitems/save?requestor=${getEnvConfig().requestorParam}`;

    const body = {
        jobItemIds,
    };

    // save all templates
    const response = await RequestUtils.doPostRequest<GeneratedArtworkJobItem[]>(url, body);

    return response;
}

export async function finishJob(tenant: Tenant, jobId: string, status: 'Published' | 'Closed'): Promise<ApiResponseOrError<GeneratedArtworkJobDetail>> {
    const url = `${getTenantedBaseUri(tenant)}/jobs/${jobId}?requestor=${getEnvConfig().requestorParam}`;

    const body = {
        status,
    };

    const response = await RequestUtils.doPatchRequest<GeneratedArtworkJobDetail>(url, body);

    return response;
}

export async function getJob(tenant: Tenant, jobId: string): Promise<ApiResponseOrError<GeneratedArtworkJobDetail>> {
    const url = `${getTenantedBaseUri(tenant)}/jobs/${jobId}?requestor=${getEnvConfig().requestorParam}`;

    const response = await RequestUtils.doGetRequest<GeneratedArtworkJobDetail>(url);

    return response;
}

export async function getJobs(tenant: Tenant, pageNumber: number, pageSize: number): Promise<ApiResponseOrError<GetJobsResponse>> {
    const url = `${getTenantedBaseUri(tenant)}/jobs?requestor=${getEnvConfig().requestorParam}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    const response = await RequestUtils.doGetRequest<GetJobsResponse>(url);

    return response;
}

/* eslint-disable no-await-in-loop */
export async function pollForCompletion(tenant: Tenant, jobId: string): Promise<ApiResponseOrError<GeneratedArtworkJobDetail>> {
    const startTime = Date.now();

    while (startTime + getEnvConfig().gamAsyncPollingTimeout > Date.now()) {
        await delayAwait(Date.now(), getEnvConfig().asyncPollingInterval);

        const generatedJob = await getJob(tenant, jobId);

        if (RequestUtils.isSuccessfulResponse(generatedJob)) {
            /* eslint-disable indent */
            switch (generatedJob.data.status) {
                case 'InProgress':
                    break;
                case 'InReview':
                case 'Published':
                case 'Closed':
                    return Promise.resolve(generatedJob);
                case 'Failed':
                    return Promise.resolve(generatedJob);
                default:
                    break;
            } /* eslint-enable indent */
        } else {
            return Promise.resolve(generatedJob);
        }
    }

    // Timeout
    return Promise.reject(new Error('Request timed out'));
}
/* eslint-enable no-await-in-loop */
