import { Grid } from '@material-ui/core';
import { Card } from '@cimpress/react-components';
import React from 'react';
import PanelDetailsCard from './panelDetailsCard';
import './ensembleDetailsCard.scss';

interface Props {
    ensemble: TemplateCatalog.Ensemble;
}

/* eslint-disable react/jsx-one-expression-per-line */
const EnsembleDetailsCard = ({ ensemble }: Props): JSX.Element => (
    <Card className="ensemble-details-card-style">
        <Grid container direction="column" justify="space-evenly" alignItems="stretch" spacing={1}>
            <Grid item xs={6}>
                <Card>
                    <div className="ensemble-detail">
                        <div>
                            <div className="ensemble-details-item">
                                <p>
                                    Ensemble ID: {ensemble.ensembleId}
                                </p>
                                <p>
                                    Ensemble Line ID: {ensemble.ensembleLineId}
                                </p>
                                <p>
                                    Design Use Case: {ensemble.designUseCaseName} (ID: {ensemble.designUseCaseId})
                                </p>
                                {ensemble.isDeleted
                                    && <p className="ensemble-deleted">
                                        Status: Deleted
                                    </p>}
                            </div>
                        </div>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <h2>Panels</h2>
                <Grid container direction="row" spacing={1}>
                    {ensemble.templates.map((panel) => (
                        <Grid item xs="auto" key={panel.templateToken} data-testid={`panel-result-${panel.templateToken}`}>
                            <PanelDetailsCard panel={panel} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </Card>
);
/* eslint-enable react/jsx-one-expression-per-line */

export default EnsembleDetailsCard;
