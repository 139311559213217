import axios, { AxiosResponse } from 'axios';
import auth from '../auth';
import { delayAwait } from '../utilityHelpers';
import { getEnvConfig } from '../envConfig';
import {
    AWSGatewayExecuteResult,
    FileInfo,
    PreviewGenerationResult,
    PreviewGenResultsWithError,
    ResizerErrorWithId,
    ResizerSuccessRespWithId,
} from '../components/resizableTemplates/previewGenerationResultData';
import { TargetProductSpecification } from '../components/templateGeneration/TargetProductSpecification';
import uploadsApi from './uploads';

export interface TemplateGenerationResult {
    data: (string | undefined)[];
    errors: ResizerErrorWithId[];
}

export interface AWSGatewayPollResult {
    output: string;
    status: string;
    error: string;
    cause: string;
    input: string;
}

export interface ApprovedPreviewConfiguration {
    sourceTemplateToken: string;
    targets: TargetProductSpecification[];
}

interface TemplateGenerationRequest {
    templateToken: string;
    targets: TargetProductSpecification[];
    bearerToken: string;
    generateEnabled: boolean;
    environment: string;
}

interface TemplateResizeRequest {
    templateToken: string;
    targets: TargetProductSpecification[];
    bearerToken: string;
    generateEnabled: boolean;
    isPreview: boolean;
    environment: string;
}

function getStateMachineARN(): string {
    return `${getEnvConfig().rtgArn}`;
}

// URI for API Gateway same for all environments
const AWSAPIGatewayURI = 'as27h7snud.execute-api.eu-west-1.amazonaws.com';
const url = `https://${AWSAPIGatewayURI}/alpha/execution`;

/* eslint-disable no-await-in-loop */
async function pollForCompletion(executionArn: string): Promise<string | undefined> {
    const pollingInput = {
        executionArn,
    };
    const startTime = Date.now();
    let pollResponse: AxiosResponse<AWSGatewayPollResult> | null = null;

    // Use user's JWT token for authorization
    const authToken = auth.getAccessToken();

    while (startTime + getEnvConfig().asyncPollingTimeout > Date.now()) {
        await delayAwait(Date.now(), getEnvConfig().asyncPollingInterval);

        pollResponse = await axios.put<AWSGatewayPollResult>(url, pollingInput, {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            validateStatus: () => true,
        });

        if (!pollResponse || pollResponse.status > 299) {
            return Promise.reject(new Error(`${executionArn} failed: ${pollResponse.status} : ${pollResponse.statusText}`));
        }

        if (pollResponse.data.status !== 'RUNNING') {
            if (pollResponse.data.status === 'SUCCEEDED') {
                // Success
                return pollResponse.data.output;
            }
            return Promise.reject(new Error(`${pollResponse.data.status} : ${pollResponse.data.error} : ${pollResponse.data.cause}`));
        }
    }
    // Timeout
    return Promise.reject(new Error(`Request timed out: ${executionArn} : ${pollResponse?.data.status}`));
}
/* eslint-enable no-await-in-loop */
export async function generateTemplatesFromApprovedPreviews(approvedInput: ApprovedPreviewConfiguration[], generateEnabled: boolean): Promise<TemplateGenerationResult | undefined> {
    // Use user's JWT token for authorization
    const authToken = auth.getAccessToken();
    const errors: ResizerErrorWithId[] = [];
    const successResp: ResizerSuccessRespWithId[] = [];

    const responses = await Promise.allSettled(approvedInput.map((approvedConfiguration) => {
        const templateGenerationRequest: TemplateGenerationRequest = {
            templateToken: approvedConfiguration.sourceTemplateToken,
            targets: approvedConfiguration.targets,
            bearerToken: authToken,
            generateEnabled,
            environment: getEnvConfig().env,
        };

        const input = {
            input: JSON.stringify(templateGenerationRequest),
            stateMachineArn: `${getStateMachineARN()}`,
        };

        try {
            return axios.post<AWSGatewayExecuteResult>(url, input, {
                headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                validateStatus: () => true,
            });
        } catch (e) {
            errors.push({ id: approvedConfiguration.sourceTemplateToken, err: e });
            return Promise.reject(e);
        }
    }));

    responses.forEach((pr, index) => {
        if (pr.status === 'fulfilled') {
            successResp.push({
                id: approvedInput[index].sourceTemplateToken,
                res: (pr as PromiseFulfilledResult<AxiosResponse<AWSGatewayExecuteResult>>).value,
            });
        }
    });

    const outputs = await Promise.allSettled(successResp.map(({ res, id }) => {
        if (!res) {
            const err = new Error(`Something went wrong posting to ${url}`);

            errors.push({ id, err });
            return Promise.reject(err);
        }

        if (res.status > 299) {
            const err = new Error(`${res.statusText}`);

            errors.push({ id, err });
            return Promise.reject(err);
        }

        return pollForCompletion(res.data.executionArn) || '';
    }));

    const data = (outputs.filter((pr) => pr.status === 'fulfilled') as PromiseFulfilledResult<string | undefined>[])
        .map((r) => r.value);

    return {
        errors,
        data,
    };
}

export async function generatePreviews(sourceTemplateTokens: string[], targets: TargetProductSpecification[]): Promise<PreviewGenResultsWithError> {
    // Use user's JWT token for authorization
    const authToken = auth.getAccessToken();
    const errors: ResizerErrorWithId[] = [];
    const successResp: ResizerSuccessRespWithId[] = [];

    const postResponses = await Promise.allSettled(sourceTemplateTokens.map(async (token) => {
        const templateGenerationRequest: TemplateResizeRequest = {
            templateToken: token,
            targets,
            bearerToken: authToken,
            generateEnabled: false,
            isPreview: true,
            environment: getEnvConfig().env,
        };

        const input = {
            input: JSON.stringify(templateGenerationRequest),
            stateMachineArn: `${getStateMachineARN()}`,
        };

        try {
            return await axios.post<AWSGatewayExecuteResult>(url, input, {
                headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                validateStatus: () => true,
            });
        } catch (e) {
            errors.push({ id: token, err: e });
            return Promise.reject(e);
        }
    }));

    postResponses.forEach((pr, index) => {
        if (pr.status === 'fulfilled') {
            successResp.push({
                id: sourceTemplateTokens[index],
                res: (pr as PromiseFulfilledResult<AxiosResponse<AWSGatewayExecuteResult>>).value,
            });
        }
    });

    const pollResponses = await Promise.allSettled(successResp.map(async ({ res, id }) => {
        if (!res) {
            const err = new Error(`Something went wrong posting to ${url}`);

            errors.push({ id, err });
            return Promise.reject(err);
        }

        if (res.status > 299) {
            const err = new Error(`${res.statusText}`);

            errors.push({ id, err });
            return Promise.reject(err);
        }
        try {
            const resp = await pollForCompletion(res.data.executionArn) || '';
            const parsedResp: FileInfo[] = JSON.parse(resp);

            return uploadsApi.getPreviewGenerationByIds(parsedResp.map((f) => f.uploadId));
        } catch (e) {
            errors.push({ id, err: e });
            return Promise.reject(e);
        }
    }));

    // Each response from the Step Function contains an array of ensembleLines that are the sourceToken applied to each Target
    // We flatten the arrays into a combined one for easier use

    const ensembleLines = (pollResponses.filter((pr) => pr.status === 'fulfilled') as PromiseFulfilledResult<PreviewGenerationResult[]>[])
        .map((r) => r.value).flat(1);

    return {
        errors,
        ensembleLines,
    };
}
