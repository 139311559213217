import React from 'react';
import { shapes } from '@cimpress/react-components';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Loading = (): JSX.Element => (
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
                <shapes.Spinner />
            </div>
        </div>
    </div>
);

export default Loading;
