import { ApiResponseOrError } from '../../../services/ApiResponseOrError';

const ErrorHelper = <T>(response?: ApiResponseOrError<T>, messageExtractor?: (response?: ApiResponseOrError<T>) => string): string => {
    let message = '';

    if (!response) {
        return message;
    }

    if (response.responseType === 'error') {
        message = response.content || 'Unexpected error occured.';
    } else if (messageExtractor) {
        message = messageExtractor(response);
    }
    return message;
};

export default ErrorHelper;
