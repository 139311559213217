import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Card } from '@cimpress/react-components';
import { getEnsembleLinesByDesignConceptId } from '../../services/dtecService';
import { searchDucs } from '../../services/ducService';
import { batchGetDps } from '../../services/dpsService';
import { getDpsDisplayText, getDpsDisplayColor, isAlphaNumeric } from '../../utilityHelpers';
import BreadcrumbsConfigContext from '../../components/breadcrumbs/breadcrumbsConfigContextProvider';
import EnsembleLineDetailsCard from '../../components/browseEnsembles/ensembleLineDetailsCard';
import './designConceptDetailsPage.scss';
import { GuidRegularExpression } from '../../TemplateTokenUtils';

interface RouterMatchPartial {
    params: {
        designConceptId: string;
    };
}
interface RouterLocationPartial {
    state: {
        ensembleLines?: TemplateCatalog.EnsembleLine[];
    };
}
interface Props {
    location: RouterLocationPartial;
    match: RouterMatchPartial;
}

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
const DesignConceptDetailsPage = ({ location, match }: Props): JSX.Element => {
    const [resultEnsembleLines, setResultEnsembleLines] = useState<TemplateCatalog.EnsembleLine[] | undefined>(undefined);
    const ensembleLinesRef = location?.state?.ensembleLines;
    const { setBreadcrumbsConfig } = useContext(BreadcrumbsConfigContext);
    const { designConceptId } = match.params;

    useEffect(() => {
        async function getFromApi(): Promise<void> {
            // Query string input must be validated before being used
            if (!designConceptId.match(GuidRegularExpression) && !isAlphaNumeric(designConceptId)) {
                return;
            }

            const finalEnsembleLines = ensembleLinesRef ?? await getEnsembleLinesByDesignConceptId(designConceptId);
            const templateToCheckForData = finalEnsembleLines?.[0]?.ensembles[0]?.templates[0];

            // Check if DPS data was passed through state already to avoid api calls
            if (!templateToCheckForData?.dpsDisplayColor || !templateToCheckForData?.dpsDisplayName || !templateToCheckForData?.dpsSubstrateColor) {
                // Fetch DPS data to assign Panel DPS Details
                const dpsIds = finalEnsembleLines?.flatMap((el) => el.ensembles.flatMap((e) => e.templates.map((t) => t.designPhysicalSpecId)));
                const uniqueDpsIds = [...new Set(dpsIds)];
                const dpsData = await batchGetDps(uniqueDpsIds as string[]);

                finalEnsembleLines?.forEach((el) => el.ensembles.forEach((e) => e.templates.forEach((p) => {
                    const dpsDataForPanel = dpsData.find((d) => d.id === p.designPhysicalSpecId);

                    p.dpsDisplayName = getDpsDisplayText(dpsDataForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                    p.dpsDisplayColor = getDpsDisplayColor(dpsDataForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                    p.dpsSubstrateColor = dpsDataForPanel?.spec.substrate.color ?? '';
                })));
            }

            // Fetch the DUC name if it wasn't passed through the state
            if (finalEnsembleLines && finalEnsembleLines.length > 1 && !finalEnsembleLines[0].designUseCaseName) {
                const uniqueDucIds = [...new Set(finalEnsembleLines?.map((line) => line.designUseCaseId))];
                const ducData = await searchDucs(uniqueDucIds);

                // Set DUC names and final ensembleLine data
                // eslint-disable-next-line
                finalEnsembleLines?.forEach((el) => el.designUseCaseName = ducData.find((duc) => duc.id === el.designUseCaseId)?.name);
            }

            setResultEnsembleLines(finalEnsembleLines);
        }

        getFromApi();

        setBreadcrumbsConfig({
            designConceptId,
            ensembleLineId: undefined,
            ensembleId: undefined,
        });
        // eslint wants breadcrumbsConfig as a dependency but function dependencies cause duplicate calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ensembleLinesRef]);

    return (<>
        <h2>
            Design Concept
            {' '}
            {designConceptId}
        </h2>

        {resultEnsembleLines && resultEnsembleLines.length > 0
            && (
                <Card>
                    <Grid container direction="column" spacing={2} id="design-concept-results-grid">
                        {resultEnsembleLines.map((line) => (
                            <Grid item key={line.ensembleLineId} data-testid={`ensemble-line-details-for-${line.ensembleLineId}`}>
                                <Card>
                                    <EnsembleLineDetailsCard
                                        ensembleLine={line}
                                        minified={true}
                                        key={line.ensembleLineId}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Card>)}
    </>);
};
/* eslint-enable react/jsx-boolean-value */
/* eslint-enable no-param-reassign */
/* eslint-enable max-len */

export default DesignConceptDetailsPage;
