/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { DragAndDroppedFile, getDroppedOrSelectedFiles } from 'html5-file-selector';
import React, {
    ChangeEvent,
    ReactNode,
    useState,
} from 'react';
import Dropzone, {
    IDropzoneProps,
    IInputProps,
    ILayoutProps,
} from 'react-dropzone-uploader';
import './DropzoneWrapper.scss';

interface Props {
    onDrop: (acceptedFiles: File[]) => void;
    allowMultiple: boolean;
    disabled: boolean;
    listOfFiles?: File[];
    helpText?: ReactNode;
    errorState?: boolean;
}

const DropzoneWrapper = ({
    onDrop, allowMultiple, disabled, listOfFiles = [], helpText, errorState = false,
}: Props): JSX.Element => {
    const [files, setFiles] = useState<File[]>(listOfFiles);

    const getFilesFromEvent = async (e: ChangeEvent<HTMLInputElement>) => (await getDroppedOrSelectedFiles(e))
        .map((f: DragAndDroppedFile): File => f.fileObject);

    const inputComponent = ({ onFiles }: IInputProps) => (<button type="submit" className="upload-button-style">
        <label
            htmlFor="selectFile"
            className="upload-dropzone-label"
        >
            Select File
            <input
                id="selectFile"
                style={{ display: 'none' }}
                type="file"
                aria-label="csv-file-input"
                onChange={(e) => getFilesFromEvent(e).then((chosenFiles: File[]) => {
                    onFiles(chosenFiles);
                    setFiles(chosenFiles);
                })}
            />
        </label>
    </button>
    );

    const handleStatusChange: IDropzoneProps['onChangeStatus'] = (_, status, allFiles) => {
        if (status === 'done' || status === 'rejected_max_files') {
            const fileObjects = allFiles.map((x) => x.file);

            setFiles(fileObjects);
            onDrop(fileObjects);

            if (!allowMultiple) {
                allFiles[0]?.remove();
            }
        }
    };

    const getWellClassNames = (): string => {
        let classNames = 'well upload-zone';

        if (!allowMultiple) {
            classNames += ' upload-zone-smaller';
        }

        if (errorState) {
            classNames += ' error';
        }

        return classNames;
    };

    const customLayoutComponent = ({
        input, dropzoneProps,
    }: ILayoutProps) => (
        <div>
            <div {...dropzoneProps}>
                <div className={getWellClassNames()}>
                    <div>
                        {files?.map((file: File) => (
                            <div key={file.name}>
                                <span>{file.name}</span>
                            </div>
                        ))}
                    </div>
                    {input}
                    <i className="fa fa-5x fa-cloud-upload background-text" />
                    <p className="background-text">Drag and Drop or Select Files to Upload</p>
                    {helpText && <small>{helpText}</small>}
                </div>
            </div>
        </div>);

    return (<Dropzone
        disabled={disabled}
        InputComponent={inputComponent}
        accept=".csv"
        LayoutComponent={customLayoutComponent}
        onChangeStatus={handleStatusChange}
    />);
};

export default DropzoneWrapper;
