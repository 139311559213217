import React, { useContext, useEffect, useState } from 'react';
import { Card, ReactTablev6, Tooltip } from '@cimpress/react-components';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { GeneratedArtworkJob } from '../../services/gamService';
import * as gamService from '../../services/gamService';
import { isSuccessfulResponse } from '../../services/RequestUtils';
import { formatDateTime } from './helpers/dateTimeHelper';
import BreadcrumbsConfigContext from '../../components/breadcrumbs/breadcrumbsConfigContextProvider';
import { TenantContext } from '../../components/composableTemplates/TenantContext';
import ComposeTemplateJobLabel from '../../components/templateGeneration/templateGenerationJobLabel';
import { FIRST_PAGE_NUMBER, getUserPreferredPageSize, setUserPreferredPageSize } from './helpers/pagingHelper';

const ComposeJobHistoryPage = (): JSX.Element => {
    const { tenant } = useContext(TenantContext);
    const [generatedArtworkJobs, setGeneratedArtworkJobs] = useState<GeneratedArtworkJob[]>([]);
    const [totalJobs, setTotalJobs] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(getUserPreferredPageSize());
    const [isLoading, setIsLoading] = useState(false);
    const { setBreadcrumbsConfig } = useContext(BreadcrumbsConfigContext);

    const load = async (loadPageNumber: number, loadPageSize: number): Promise<void> => {
        setIsLoading(true);
        try {
            const response = await gamService.getJobs(tenant, loadPageNumber, loadPageSize);

            if (isSuccessfulResponse(response)) {
                setGeneratedArtworkJobs(response.data.jobs);
                setTotalJobs(response.data.count);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Clear breadcrumbs
        setBreadcrumbsConfig({
            designConceptId: undefined,
            ensembleLineId: undefined,
            ensembleId: undefined,
        });

        load(FIRST_PAGE_NUMBER, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant]);

    type TableCellType = { original: GeneratedArtworkJob };

    const columns = [
        {
            Header: 'Job Id',
            Cell: ({ original: generatedArtworkJob }: TableCellType): JSX.Element => {
                const link = (
                    <Link
                        to={`/composeJobDetails/${tenant.contentAccountId}/${tenant.contentAreaId}/${generatedArtworkJob.id}`}
                    >
                        {generatedArtworkJob.id}
                    </Link>
                );

                return link;
            },
        },
        {
            Header: 'Job Status',
            id: 'created',
            Cell: ({ original: generatedArtworkJob }: TableCellType): JSX.Element => {
                const label = (
                    <ComposeTemplateJobLabel status={generatedArtworkJob.status} />
                );

                return label;
            },
        },
        {
            Header: 'Created',
            id: 'created',
            Cell: ({ original: generatedArtworkJob }: TableCellType): JSX.Element => {
                const tooltip = (
                    <Tooltip direction="right" contents={formatDateTime(generatedArtworkJob.created)}>
                        {formatDateTime(generatedArtworkJob.created, true)}
                    </Tooltip>
                );

                return tooltip;
            },
        },
        {
            Header: 'Created By',
            accessor: 'createdBy',
        },
        {
            Header: 'Templates Generated Count',
            accessor: 'itemsCount',
        },
    ] as Column<GeneratedArtworkJob>[];

    const onPageSizeChanged = (newPageSize: number): void => {
        setPageSize(newPageSize);
        setUserPreferredPageSize(newPageSize);

        load(FIRST_PAGE_NUMBER, newPageSize);
    };

    const onPageChange = (newPageNumber: number): void => {
        const backendPageNumber = newPageNumber + 1;

        load(backendPageNumber, pageSize);
    };

    return (<Card>
        <h2 style={{ marginBottom: '16px' }}>Compose History</h2>
        {generatedArtworkJobs.length > 0 && (
            <div className="row section">
                <div className="col-sm-12">
                    <ReactTablev6
                        sortable={false}
                        pageSize={pageSize}
                        pages={Math.ceil(totalJobs / pageSize)}
                        loading={isLoading}
                        minRows={0}
                        columns={columns}
                        data={generatedArtworkJobs}
                        collapseOnDataChange={false}
                        className="simple-table"
                        onPageSizeChange={onPageSizeChanged}
                        onPageChange={onPageChange}
                    />
                </div>
            </div>
        )}
    </Card>);
};

export default ComposeJobHistoryPage;
