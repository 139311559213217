import { Button } from '@cimpress/react-components';
import React from 'react';
import { GeneratedArtworkJobItem } from '../../services/gamService';
import GeneratedComposablePreview from './generatedComposablePreview';
import '../templateGeneration/previewGroup.scss';

interface Props {
    dc: string;
    jobItems: GeneratedArtworkJobItem[];
    onJobItemSelected: (id: string[], isSelected: boolean) => void;
    selectedJobItems: string[];
}

const PreviewGroup = ({
    dc, jobItems, onJobItemSelected, selectedJobItems,
}: Props): JSX.Element => {
    const jobItemIds = jobItems.map((x) => x.id);

    const onSelectOrUnselectClicked = (value: boolean): void => {
        onJobItemSelected(jobItemIds, value);
    };

    return (<div className="composed-previews-overview-section">
        <div className="composed-previews-section">
            <h3 className="composed-previews-section-title">
                {`Design Concept: ${dc}`}
            </h3>
            <div className="composed-previews-section-buttons-group">
                <Button onClick={(): void => onSelectOrUnselectClicked(false)}>Unselect All</Button>
                <Button onClick={(): void => onSelectOrUnselectClicked(true)}>Select All</Button>
            </div>
        </div>
        <div className="composed-previews-overview-container">
            {jobItems.map((jobItem) => (<GeneratedComposablePreview
                key={jobItem.id}
                jobItem={jobItem}
                isSelected={selectedJobItems.some((id) => id === jobItem.id)}
                onJobItemSelected={onJobItemSelected}
            />))}
        </div>
    </div>);
};

export default PreviewGroup;
