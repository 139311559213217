/* eslint-disable arrow-body-style */
import * as csvParse from 'csv-parse/lib';
import { TargetProductSpecification } from '../TargetProductSpecification';

const parseCsv = async (file: File): Promise<TargetProductSpecification[]> => {
    return new Promise<TargetProductSpecification[]>((resolve, reject) => {
        file.text().then((fileContent) => {
            csvParse.parse(fileContent, {
                delimiter: ',',
                columns: true,
                fromLine: 1,
            }, async (err, result: TargetProductSpecification[]) => {
                if (err) {
                    reject(err);
                }

                resolve(result);
            });
        });
    });
};

export default parseCsv;
