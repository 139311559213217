import React from 'react';
import { Button } from '@cimpress/react-components';
import './originalJobInput.scss';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import { CompositeState } from './CompositeState';

interface Props {
    job: GeneratedArtworkJobDetail;
}

const ReRunCompositionJob = ({ job }: Props): JSX.Element => {
    const jobInputJson = JSON.parse(job.rawInput) as Partial<CompositeState>;
    const isDisabled = !jobInputJson.products || !jobInputJson.strategist || !jobInputJson.templateTokens || !jobInputJson.designConceptIds;

    if (jobInputJson.products) {
        jobInputJson.products = jobInputJson.products.map((p) => ({ ...p, productOptions: JSON.stringify(p.productOptions) }));
    }
    const compositeLocation: LocationDescriptor<CompositeState> = {
        pathname: '/compose',
        state: jobInputJson as CompositeState,
    };

    return <Link to={compositeLocation} className={isDisabled ? 're-run-link-disabled' : 're-run-link'}>
        <Button
            variant="default"
            size="sm"
        >
            Re-run job
        </Button>
    </Link>;
};

export default ReRunCompositionJob;
