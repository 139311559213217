import { ApiSuccessResponse, ApiResponseOrError } from '../../../services/ApiResponseOrError';
import { GeneratedArtworkJobDetail } from '../../../services/gamService';

const JobErrorDetailExtractor = (input?: ApiResponseOrError<GeneratedArtworkJobDetail>): string => {
    const job = input as ApiSuccessResponse<GeneratedArtworkJobDetail>;

    if (job.data.errorDetails?.errorName && job.data.errorDetails?.errorMessage) {
        return `${job.data.errorDetails?.errorName}: ${job.data.errorDetails?.errorMessage}`;
    }
    return '';
};

export default JobErrorDetailExtractor;
