import { batchGetDps } from '../services/dpsService';
import { searchDucs } from '../services/ducService';
import { getDpsDisplayText, getDpsDisplayColor } from '../utilityHelpers';

/* eslint-disable no-param-reassign */
const fetchDucNamesDpsDisplayNames = async (ensembles: TemplateCatalog.Ensemble[]): Promise<void> => {
    const ducIds = ensembles.map((e) => e.designUseCaseId);
    const ducs = await searchDucs(ducIds);

    const dpsIds = ensembles.map((e) => e.templates.map((t) => t.designPhysicalSpecId)).flat();
    const uniqueDpsIds = [...new Set(dpsIds)];
    const dpsData = await batchGetDps(uniqueDpsIds);

    ensembles.forEach((e) => {
        e.designUseCaseName = (ducs.find((d) => d.id === e.designUseCaseId))?.name;
        e.templates.forEach((p) => {
            const dpsIdForPanel = dpsData.find((d) => d.id === p.designPhysicalSpecId);

            p.dpsDisplayName = getDpsDisplayText(dpsIdForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
            p.dpsDisplayColor = getDpsDisplayColor(dpsIdForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
            p.dpsSubstrateColor = dpsIdForPanel?.spec.substrate.color ?? '';
        });
    });
};
/* eslint-enable no-param-reassign */

export default fetchDucNamesDpsDisplayNames;
