import React from 'react';
import { getDpsDisplayColor, getDpsDisplayText } from '../../utilityHelpers';
import DesignPhysicalSpecDetailsSection from '../browseEnsembles/designPhysicalSpecDetailsSection';

/* eslint-disable react/jsx-one-expression-per-line */

interface Props {
    ensembleLine: TemplateCatalog.EnsembleLine;
    designUseCases: DesignUseCase.DesignUseCaseDto[];
    designPhysicalSpecs: DesignPhysicalSpec.DesignPhysicalSpecDto[];
}

const LineDetails = ({ ensembleLine, designUseCases, designPhysicalSpecs }: Props): JSX.Element => {
    const ducName = designUseCases.find((d) => d.id === ensembleLine.designUseCaseId)?.name;
    const dps = designPhysicalSpecs.find((d) => d.id === ensembleLine.ensembles[0].templates[0].designPhysicalSpecId);

    return (<div className="line-details">
        <p>Ensemble Line ID: {ensembleLine.ensembleLineId}</p>
        <p>Design Concept ID: {ensembleLine.designConceptId}</p>
        <p>
            Design Use Case: {ducName}
            &nbsp;(ID: {ensembleLine.designUseCaseId})
        </p>
        {dps && <DesignPhysicalSpecDetailsSection
            displayColor={getDpsDisplayColor(dps)}
            displayName={getDpsDisplayText(dps)}
            dpsId={dps.id}
            showFrontIndicatorText={false}
        />}
    </div>);
};

export default LineDetails;
