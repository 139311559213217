import React from 'react';
import '../templateGeneration/generatedPreviewGrouping.scss';
import { GeneratedArtworkJobItem } from '../../services/gamService';
import '../templateGeneration/previewGroup.scss';
import PublishedComposablePreview from './publishedComposablePreview';

interface Props {
    dc: string;
    jobItems: GeneratedArtworkJobItem[];
}

const PublishedPreviewGroup = ({
    dc,
    jobItems,
}: Props): JSX.Element => (
    <div className="composed-previews-overview-section">
        <h3 className="composed-previews-section-title">
            Design Concept:&nbsp;
            {dc}
        </h3>
        <div className="composed-previews-overview-container">
            {/* eslint-disable-next-line */}
            {jobItems.map((jobItem, i) => <PublishedComposablePreview jobItem={jobItem} key={i} />)}
        </div>
    </div>
);

export default PublishedPreviewGroup;
