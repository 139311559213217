import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import './ButtonBar.scss';

interface ButtonBarProps {
    values: string[];
    label: string;
    stateValue: string;
    setStateValue: (value: string) => void;
}

const ButtonBar = ({
    values, label, stateValue, setStateValue,
}: ButtonBarProps): JSX.Element => {
    const onChange = (value: string): void => {
        setStateValue(value);
    };

    return (
        <div className="button-bar-container">
            <p>{label}</p>
            <ButtonGroup className="button-bar">
                <Button
                    className={`switch-button ${stateValue === values[0] ? 'active' : ''}`}
                    onClick={(): void => onChange(values[0])}
                >
                    {values[0]}
                </Button>
                <Button
                    className={`switch-button ${stateValue === values[1] ? 'active' : ''}`}
                    onClick={(): void => onChange(values[1])}
                >
                    {values[1]}
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default ButtonBar;
