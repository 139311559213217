import axios from 'axios';
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'd6NRzz1RzioIkqM8MesH4f2KulFMi4ln'; // https://developer.cimpress.io/clients/d6NRzz1RzioIkqM8MesH4f2KulFMi4ln/settings

// eslint-disable-next-line new-cap
const auth = new centralizedAuth({
    clientID: CLIENT_ID,
    redirectRoute: '/',
    leeway: 300,
});

export function isVistaprintOrCimpressUser(): boolean {
    return auth?.getProfile()['https://claims.cimpress.io/tenants']?.some((s: string) => (s.toLowerCase() === 'vistaprint' || s.toLowerCase() === 'cimpress')) ?? false;
}

export function getUserAccountId(): string {
    return auth?.getProfile()['https://claims.cimpress.io/account'] ?? undefined;
}

async function hasPermissions(permissionFilter: string): Promise<boolean> {
    const url = `https://api.cimpress.io/auth/access-management/v1/principals/self/permissions/content-area?permissionFilter=${permissionFilter}`;

    const jwt = auth?.getAccessToken();

    const response = await axios.get<string[]>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${jwt}`,
        },
        validateStatus: () => true,
    });

    return response && response.status === 200 && response.data?.some((x) => x);
}

export async function hasWritePermissions(): Promise<boolean> {
    return hasPermissions('write:content');
}

export async function hasWriteOrPublishPermissions(): Promise<boolean> {
    return hasPermissions('write:content,publish:content');
}

export default auth;
