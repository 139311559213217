import React, { useState } from 'react';
import { TextField } from '@cimpress/react-components';
import './templateTokensSelector.scss';
import { TextFieldProps } from '@cimpress/react-components/lib/TextField';
import { TemplateTokenRegularExpression } from '../../TemplateTokenUtils';
import TryParseValue from './helpers/multiLineParser';

interface Props {
    initialValue: string[];
    onTemplateTokensChanged: (parsedValues: string[]) => void;
}

const TemplateTokensSelector = ({ initialValue, onTemplateTokensChanged }: Props): JSX.Element => {
    const [value, setValue] = useState<string>(initialValue ? initialValue.join('\n') : '');
    const [isTemplateTokenTextFieldValid, setIsTemplateTokenValid] = useState<boolean | undefined>();

    const onChanged = (input: string): void => {
        setValue(input);
        const parsedValues = TryParseValue(input);

        if (parsedValues.length === 0) {
            setIsTemplateTokenValid(undefined);
        } else if (parsedValues.some((parsedValue) => !parsedValue.match(TemplateTokenRegularExpression))) {
            // if there is at least one parsed value which isn't a valid template token, then we show an error
            setIsTemplateTokenValid(false);
        } else {
            setIsTemplateTokenValid(true);
        }
        onTemplateTokensChanged(parsedValues);
    };

    const mapToTextAreaStatus = (): TextFieldProps['status'] => {
        if (isTemplateTokenTextFieldValid === undefined || value === '') {
            return '';
        }
        if (isTemplateTokenTextFieldValid) {
            return 'success';
        }
        return 'error';
    };

    return (
        <TextField
            className="template-tokens-input"
            type="textarea"
            name="layout-template-tokens-search"
            label="Source Layouts (template tokens, each on a new line)"
            value={value || ''}
            data-testid="template-tokens-search-input"
            inputStyle={{
                minHeight: '100px',
                minWidth: '30%',
                maxWidth: '100%',
            }}
            status={mapToTextAreaStatus()}
            onChange={(e): void => onChanged(e.target.value)}
        />
    );
};

export default TemplateTokensSelector;
