import React, { useContext } from 'react';
import { getEnvConfig } from '../../envConfig';
import * as templatePreviewUtility from '../../utils/templatePreviewUtility';
import PreferencesConfigurationContext from '../preferences/preferencesContext';

interface Props {
    templateToken: string;
    designPhysicalSpecId?: string;
    backgroundColor?: string;
    previewWidth?: number;
    useFakeFamily?: boolean;
}

function getBaseUri(): string {
    return `${getEnvConfig().dterUrl}`;
}

export function GetTemplatePreviewUrl({
    templateToken, designPhysicalSpecId, backgroundColor, previewWidth, useFakeFamily,
}: Props): string {
    let width = 500;

    const realizationUri = new URL(`https://${getBaseUri()}/api/v2/templates/${templateToken}/cultures/en-us/renderDocument`);

    if (backgroundColor) {
        realizationUri.searchParams.append('backgroundColor', backgroundColor.toLowerCase());
    }
    if (useFakeFamily) {
        realizationUri.searchParams.append('useFakeFamily', 'true');
    }

    if (previewWidth) {
        width = previewWidth;
    }

    const encodedRealizationUri = encodeURIComponent(encodeURIComponent(realizationUri.toString()));

    let renderingUri = `https://rendering.mcp.cimpress.com/v2/vp/preview?format=png&instructions_uri=https%3A%2F%2Fuds.documents.cimpress.io%2Fv2%2Ftransient%2Fvp%3FdocumentUri%3D${encodedRealizationUri}%26type%3Dpreview&quality=95&width=${width}`;

    if (designPhysicalSpecId) {
        const encodedSceneUri = encodeURIComponent(templatePreviewUtility.getSceneUrl(designPhysicalSpecId, width));

        renderingUri = `${renderingUri}&scene=${encodedSceneUri}`;
    }

    return renderingUri;
}

const TemplatePreview = ({
    templateToken,
    designPhysicalSpecId,
    backgroundColor,
    previewWidth,
}: Props): JSX.Element => {
    const { useFakeFamily } = useContext(PreferencesConfigurationContext);

    return (
        <img
            src={GetTemplatePreviewUrl({
                templateToken, designPhysicalSpecId, backgroundColor, previewWidth, useFakeFamily,
            })}
            className="template-preview"
            alt="Template preview"
        />
    );
};

export default TemplatePreview;
