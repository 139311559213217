import { Step, Stepper } from '@cimpress/react-components';
import React from 'react';
import StepDefinition from './stepDefinition';
import './StepperLayout.css';

interface Props {
    activeStep: number;
    steps: StepDefinition[];
    onStepChange: (step: number) => void;
}

const StepperLayout = ({ activeStep, steps, onStepChange }: Props): JSX.Element => {
    const step = steps[activeStep];

    const setStep = (index: number): void => {
        if (steps[index].canBeSetByUser === true) {
            onStepChange(index);
        }
    };

    return (
        <div className="stepper-wrapper">
            <h4>
                {step.name}
                :
                {' '}
                {step.title}
            </h4>
            <Stepper status="success" activeStep={activeStep}>
                {steps.map((s): JSX.Element => <Step name={s.name} key={s.name} onClick={setStep}>{s.name}</Step>)}
            </Stepper>
        </div>);
};

export default StepperLayout;
