import axios, { AxiosError, AxiosResponse } from 'axios';
import { getEnvConfig } from '../envConfig';
import auth from '../auth';

function getBaseUri(): string {
    return `${getEnvConfig().dtecBackstageUrl}`;
}

export interface DtecBackstageResult {
    statusCode: number;
}

export interface ReplaceLineInputItem {
    oldEnsembleId: string;
    newEnsembleId: string;
    isProminentVariant: boolean;
}

export interface ReplaceLineResponse {
    data?: string | undefined;
    error?: string | undefined;
    correlationId?: string | undefined;
}

export async function catalogUpdateEnsemble(sourceType: string, ensembleId: string, enable: boolean): Promise<DtecBackstageResult> {
    const url = `https://${getBaseUri()}/api/v3/sources/${sourceType}/ensembles/${ensembleId}:${enable ? 'enable' : 'disable'}?requestor=${getEnvConfig().requestorParam}`;

    const authenticationToken = auth.getAccessToken();
    const headers = {
        Authorization: `Bearer ${authenticationToken}`,
    };

    return axios.post(url, {}, {
        headers,
    })
        .then((res) => (
            {
                statusCode: res.status,
            }
        ))
        .catch((res) => (
            {
                statusCode: res?.response?.status ?? 999,
            }
        ));
}

const correlationIdHeader = 'x-correlation-id';

function buildErrorResponse(axiosResponse?: AxiosResponse, error?: unknown): ReplaceLineResponse {
    const defaultErrorMessage = 'Unknown error, API call to Backstage failed.';

    const response = axiosResponse ?? (error as AxiosError).response;

    if (response) {
        // If response is 3xx or 4xx it might contain a useful message for the user
        if (response.status > 299 && response.status < 500) {
            return {
                error: response.data?.toString() || defaultErrorMessage,
                correlationId: response.headers[correlationIdHeader],
            };
        }

        // If response is 5xx it likely has no message or an ugly stack trace, show default message instead
        if (response.status >= 500) {
            return {
                error: defaultErrorMessage,
                correlationId: response.headers[correlationIdHeader],
            };
        }
    }

    return {
        error: (error as Error).message
        || defaultErrorMessage,
    };
}

export async function performLineReplacement(input: ReplaceLineInputItem[], oldEnsembleLineId: string): Promise<ReplaceLineResponse> {
    // Replacement is currently only supported for monolith ensemble lines, so comboBackedDesign can be hardcoded here for now
    const url = `https://${getBaseUri()}/api/v3/sources/comboBackedDesign/ensembleLines/${oldEnsembleLineId}:replace?requestor=${getEnvConfig().requestorParam}`;
    const authenticationToken = auth.getAccessToken();
    const headers = {
        Authorization: `Bearer ${authenticationToken}`,
    };

    try {
        const response = await axios.post(url, input, { headers });

        if (response && response.status < 300) {
            return {
                data: response.data?.toString(),
            };
        }

        return buildErrorResponse(response);
    } catch (e) {
        return buildErrorResponse(undefined, e);
    }
}
