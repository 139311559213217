import axios, { AxiosError } from 'axios';
import { getEnvConfig } from '../../envConfig';
import { UploadRequest, UploadsServiceResponseDto } from './types';
import auth from '../../auth';
import { PreviewGenerationResult } from '../../components/resizableTemplates/previewGenerationResultData';

const uploadRequest = async <T>(config: UploadRequest): Promise<T> => {
    try {
        const authHeaders = {
            ...config.headers,
            Authorization: `Bearer ${auth.getAccessToken()}`,
        };

        const response = await axios.request<T>({ url: `https://${getEnvConfig().uploadsUrl}/${config.path}`, ...config, ...{ headers: authHeaders } });

        return response.data;
    } catch (e) {
        const axiosError = e as AxiosError;
        let errorMessage = '';

        if (!axiosError || !axiosError.response) {
            errorMessage = 'Error calling Uploads Service.';
        } else {
            const { response } = axiosError;
            const contentType = response.headers['content-type'];
            let errorDetail = '';

            if (response.status > 499) {
                errorDetail = response.statusText;
            } else if (contentType === 'application/json' || contentType === 'application/problem+json') {
                errorDetail = response.data.message;
            } else {
                errorDetail = response.data;
            }
            errorMessage = `Error calling Uploads Service. Status code: ${response.status}, detail: "${errorDetail}"`;
        }

        throw new Error(errorMessage);
    }
};

// Based on ACE implementation:
// https://gitlab.com/vistaprint-org/content-technology/content-authoring/adobe-content-extension/-/blob/master/client/scripts/util.ts#L31

const uploadTransientDocument = async (document: string): Promise<string> => {
    const { uploadsUrl, transientUploadTenant } = getEnvConfig();
    const contents = Buffer.from(document, 'utf-8');
    const formData = new FormData();

    formData.append('file', new Blob([contents], { type: 'application/json' }), 'preview.json');

    const processArg = encodeURI(JSON.stringify({ type: 'storeOnly' }));

    const dto = await uploadRequest<UploadsServiceResponseDto[]>({
        path: `v1/uploads?process=${processArg}&tenant=${transientUploadTenant}`,
        method: 'POST',
        data: formData,
    });

    return `https://${uploadsUrl}/v1/uploads/${dto[0].uploadId}/original?tenant=${transientUploadTenant}`;
};

const getFileById = async (id: string, tenant = getEnvConfig().transientUploadTenant): Promise<PreviewGenerationResult> => {
    const blob = await uploadRequest<Blob>({
        path: `v1/uploads/${id}/?tenant=${tenant}`,
        method: 'GET',
        responseType: 'blob',
    });

    return JSON.parse(await blob.text());
};

const getPreviewGenerationByIds = (ids: string[]): Promise<PreviewGenerationResult[]> => Promise.all(ids.map((id) => getFileById(id)));

const uploadsApi = ({
    uploadTransientDocument,
    getFileById,
    getPreviewGenerationByIds,
});

export default uploadsApi;
