import { AxiosResponse, AxiosError } from 'axios';

export type ApiResponseOrError<T> = ApiSuccessResponse<T> | ApiError;

export interface ApiSuccessResponse<T> {
    responseType: 'success';
    data: T;
    statusCode: number;
}

export interface ApiError {
    responseType: 'error' | 'cancelled';
    content: string;
    statusCode: number;
}

export function createResponse<T>(response: AxiosResponse<T>): ApiResponseOrError<T> {
    if (response.status > 299) {
        return {
            responseType: 'error',
            statusCode: response.status,
            content: JSON.stringify(response.data),
        };
    }

    return {
        responseType: 'success',
        statusCode: response.status,
        data: response.data as T,
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createErrorResponse<T>(
    error: AxiosError,
    url: string,
    applicationName?: string,
): ApiResponseOrError<T> {
    const detail = error.response?.data?.detail // custom message from API
        ?? error.response?.data?.errors?.join('; ') // built-in validation errors
        ?? error.response?.data?.title // more general if others missing
        ?? 'unknown - see console for details.';

    return {
        responseType: 'error',
        statusCode: error.response?.status ?? 500,
        content: `Request to ${applicationName} API failed with: '${error?.message ?? error}', reason: ${detail}.`,
    };
}
