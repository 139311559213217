import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const defaultDateFormat = 'MMMM Do YYYY, h:mm:ss a';

export function formatDateTime(value: Date | string | undefined | null, fromNow = false): string {
    if (!value) {
        return 'N/A';
    }

    return fromNow ? dayjs.utc(value).local().fromNow() : dayjs.utc(value).local().format(defaultDateFormat);
}

export default formatDateTime;
