import React from 'react';
import { Grid } from '@material-ui/core';
import { Button, Alert } from '@cimpress/react-components';
import ObjCsv from 'objects-to-csv';
import ResizeTemplateJobLabel from '../templateGeneration/templateGenerationJobLabel';
import './ResizeTemplateResults.scss';
import '../templateGeneration/reviewAndApprove.scss';
import { TemplateGenerationResult } from '../../services/rtgService';
import { PreviewGenerationResult } from './previewGenerationResultData';

interface AGSResult {
    sourceToken: string;
    product: string;
    productOptions: string;
    orientation: string;
    substrateColor: string;
    substrateOpacity: string;
    designUseCaseId: string;
    templateToken: string | undefined;
    designPhysicalSpecId: string;
}

interface Props {
    generationResults: TemplateGenerationResult;
    previewSpecifications: PreviewGenerationResult[];
}

const ResizeTemplateResults = ({ generationResults, previewSpecifications }: Props): JSX.Element => {
    const getFileName = (): string => `results-${new Date().toJSON().replaceAll(/-|:|\.|Z/g, '').replace('T', '_')}.csv`;

    const getPrimarySpecData = (): AGSResult[] => {
        const results: AGSResult[] = [];

        previewSpecifications.forEach((result) => {
            const resultObj = { ...result.target, sourceToken: result.sourceTemplateToken } as AGSResult;

            const frontAndProminents = result.ensembles.filter((x) => x.isProminentVariant)
                .map((p) => p.panels).flat()
                .filter((p) => p.panelType === 'front');

            frontAndProminents.forEach((x) => results.push({ ...resultObj, designPhysicalSpecId: x.designPhysicalSpecId }));
        });

        return results;
    };

    const generateCsvData = (): Record<string, unknown>[] => {
        const csvItems = getPrimarySpecData();
        const resultsData: AGSResult[] = generationResults.data.map((r) => JSON.parse(r || '').body).flat();

        return csvItems.map((item) => {
            const match = resultsData.find((result) => result.sourceToken === item.sourceToken
            && result.templateToken?.includes(item.designPhysicalSpecId));

            const { designPhysicalSpecId, orientation, ...onlyRequiredData } = item;

            // All fields in the object must have a value in order to be "csv-ified".
            // Thus, we set TemplateToken to "something" so that it is output properly.
            return { ...onlyRequiredData, templateToken: match ? match.templateToken ?? '' : '' };
        });
    };

    const DownloadFile = async (): Promise<void> => {
        const csvData = new ObjCsv(generateCsvData());

        const generatedCsv = new File([await csvData.toString()], getFileName());

        const element = document.createElement('a');

        element.href = URL.createObjectURL(generatedCsv as Blob);
        element.download = generatedCsv?.name || '';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (
        <div>
            <div className="job-details-header">
                { /* TODO: Add Job ID header here during phase 2, shared from steps page. */ }
                <h3>
                    Job
                </h3>
                <ResizeTemplateJobLabel status="Published" />
            </div>
            <div className="job-details-subheader">
                {generationResults.data.map((r) => JSON.parse(r || '').body).flat().length}
                &nbsp;templates created
            </div>
            <Grid container className="results-grid">
                <Grid item sm={3} />
                <Grid item sm={6}>
                    <h2 className="result-message">Success!</h2>
                    <br />
                    <h4>
                        You&apos;ve completed your resize.
                        It may take up to 5 minutes for your resize to process
                        and be available to view in DTeC.
                    </h4>
                    <br />
                </Grid>
                <Grid item sm={3} />
            </Grid>
            <Grid container className="results-grid">
                <Grid item sm="auto">
                    <Alert
                        status="warning"
                        message="Please download a copy of your resize before leaving this page."
                        dismissible={false}
                    />
                </Grid>
            </Grid>
            <Grid container className="results-grid">
                <Grid item sm={3} />
                <Grid item sm={6}>
                    <Button className="download-button" variant="primary" onClick={DownloadFile}>Download CSV</Button>
                </Grid>
                <Grid item sm={3} />
            </Grid>
        </div>);
};

export default ResizeTemplateResults;
