import { Modal } from '@cimpress/react-components';
import React from 'react';
import { JobItemSubItem } from '../../services/gamService';
import ComparisonPreview from './comparisonPreview';

interface Props{
    currentSubItem: JobItemSubItem;
    publishedSubItem: JobItemSubItem;
    isModalVisible: boolean;
    onSetModalVisible: (value: boolean) => void;
}

const ComparisonModal = ({
    isModalVisible, currentSubItem, publishedSubItem, onSetModalVisible,
}: Props): JSX.Element => <Modal
    closeButton
    show={isModalVisible}
    title="Compare with published version"
    size="lg"
    onRequestHide={(): void => onSetModalVisible(false)}
>
    <ComparisonPreview publishedSubItem={publishedSubItem} currentSubItem={currentSubItem} />
</Modal>;

export default ComparisonModal;
