import React, { useContext, useEffect, useState } from 'react';
import ComposeJobHistoryPage from '../../pages/composeTemplatePage/composeJobHistoryPage';
import ComposeTemplatePage from '../../pages/composeTemplatePage/composeTemplatePage';
import PageComponent from './composeComponentEnum';
import {
    availableTenantList, Tenant, TenantContext,
} from './TenantContext';
import TenantPicker from './TenantPicker';

interface Props {
    setTenant: (tenant: Tenant) => void;
    component: PageComponent;
}

const ComposePagesWrapper = (props: Props): JSX.Element => {
    const { setTenant, component } = props;
    const { tenant } = useContext(TenantContext);
    const [availableTenants] = useState<Tenant[]>(availableTenantList);
    const [isTenantPickerReadOnly, setIsTenantPickerReadOnly] = useState(false);

    useEffect(() => {
        setIsTenantPickerReadOnly(false);
    }, [component]);

    const getComponent = (): React.ReactNode => {
        let composeComponent = <></>;
        /* eslint-disable indent */

        switch (component) {
            case PageComponent.compose:
                composeComponent = <ComposeTemplatePage onActiveStepChanged={setIsTenantPickerReadOnly} />;
                break;
            case PageComponent.composeJobHistory:
                composeComponent = <ComposeJobHistoryPage />;
                break;
            default:
                break;
        } /* eslint-enable indent */
        return composeComponent;
    };

    return (
        <div>
            <TenantPicker
                tenants={availableTenants}
                selected={tenant}
                isReadOnly={isTenantPickerReadOnly}
                onTenantSelected={setTenant}
            />
            {getComponent()}
        </div>
    );
};

export default ComposePagesWrapper;
