import React, { useState } from 'react';
import {
    Checkbox, Tooltip, Alert, Card, Button,
} from '@cimpress/react-components';
import GeneratedEnsembleMetadata from './generatedEnsembleMetadata';
import GeneratedEnsemblePanelPreview from './generatedEnsemblePanelPreview';
import '../templateGeneration/generatedPreviewGrouping.scss';
import { GeneratedArtworkJobItem } from '../../services/gamService';
import ComparisonModal from './comparisonModal';
import { extractPanelSizes } from '../../pages/composeTemplatePage/helpers/documentPanelSizeExtractor';
import DuplicateCheckmark from './duplicateCircleView';

interface Props {
    jobItem: GeneratedArtworkJobItem;
    isSelected: boolean;
    onJobItemSelected: (id: string[], isSelected: boolean) => void;
}

const GeneratedComposablePreview = ({ jobItem, isSelected, onJobItemSelected }: Props): JSX.Element => {
    const [isShowComparableModal, setIsShowComparableModal] = useState(false);
    const onCheckboxChanged = (): void => {
        onJobItemSelected([jobItem.id], !isSelected);
    };

    const labelId = `id_${Math.random()}`;
    const panelSizes = extractPanelSizes(jobItem.subItem.generatedDocument);

    return (<Card className="composable-preview-tile-container">
        <div className="composable-preview-tile-header">
            <Checkbox
                className="composable-preview-checkbox"
                checked={isSelected}
                id={labelId}
                onChange={onCheckboxChanged}
            />
            <label htmlFor={labelId}>
                <strong>Source</strong>
                <br />
                {jobItem.jobItemInput.sourceTemplateTokens}
            </label>
            {jobItem.previouslyPublishedSubItem && <DuplicateCheckmark jobItem={jobItem} />}
        </div>
        <div className="ensemble-preview-body">
            {jobItem.previouslyPublishedSubItem && <Tooltip containerClassName="warning-container" contents="Template was already published in the past">
                <Alert
                    status="warning"
                    message="Template was already published"
                    dismissible={false}
                />
            </Tooltip>}
            <div className="ensemble-preview-body-panels">
                <GeneratedEnsemblePanelPreview item={jobItem.subItem} panelSizes={panelSizes} />
            </div>
            <GeneratedEnsembleMetadata jobItem={jobItem} />
            <div style={{ flex: 1 }} />
            {jobItem.previouslyPublishedSubItem && <Button variant="default" className="published-composable-preview-button" onClick={(): void => setIsShowComparableModal(true)}>Compare with published version</Button>}
        </div>
        {jobItem.previouslyPublishedSubItem && <ComparisonModal
            currentSubItem={jobItem.subItem}
            publishedSubItem={jobItem.previouslyPublishedSubItem}
            isModalVisible={isShowComparableModal}
            onSetModalVisible={setIsShowComparableModal}
        />}
    </Card>);
};

export default GeneratedComposablePreview;
