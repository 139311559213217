import React, { useState } from 'react';
import { TextField } from '@cimpress/react-components';
import './designConceptsSelector.scss';
import TryParseValue from './helpers/multiLineParser';

interface Props {
    initialValue: string[];
    onDesignConceptsChanged: (parsedValues: string[]) => void;
    disabled?: boolean;
}

const DesignConceptsSelector = ({
    initialValue,
    onDesignConceptsChanged,
    disabled = false,
}: Props): JSX.Element => {
    const [value, setValue] = useState<string>(initialValue ? initialValue.join('\n') : '');

    const onChanged = (input: string): void => {
        setValue(input);
        const parsedValues = TryParseValue(input);

        onDesignConceptsChanged(parsedValues);
    };

    return (
        <TextField
            className="design-concepts-input"
            type="textarea"
            name="search"
            label="Design Concepts IDs (each on a new line)"
            value={value || ''}
            data-testid="search-input"
            inputStyle={{
                minHeight: '100px',
                minWidth: '30%',
                maxWidth: '100%',
            }}
            disabled={disabled}
            onChange={(e): void => onChanged(e.target.value)}
        />
    );
};

export default DesignConceptsSelector;
