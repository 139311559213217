import {
    TextField, Button, Alert, Modal,
} from '@cimpress/react-components';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ObjCsv from 'objects-to-csv';
import { ReplacementStatus } from '../../components/replaceLine/AccordianTitle';
import LineReplacement, { Replacement } from '../../components/replaceLine/LineReplacement';
import { batchGetDps } from '../../services/dpsService';
import { getEnsembleLineByTemplateToken } from '../../services/dtecService';
import { searchDucs } from '../../services/ducService';
import { splitInputByWhitespaceOrCommaAndValidateTemplateTokens } from '../../TemplateTokenUtils';
import './replaceLinePage.scss';
import { hasWriteOrPublishPermissions } from '../../auth';

/* eslint-disable react/jsx-one-expression-per-line */
// eslint-disable-next-line arrow-body-style
const ReplaceLinePage = (): JSX.Element => {
    const [replacements, setReplacements] = useState<Replacement[]>([]);
    const [designUseCases, setDesignUseCases] = useState<DesignUseCase.DesignUseCaseDto[]>([]);
    const [designPhysicalSpecs, setDesignPhysicalSpecs] = useState<DesignPhysicalSpec.DesignPhysicalSpecDto[]>([]);
    const [oldTokenInput, setOldTokenInput] = useState<string>('');
    const [newTokenInput, setNewTokenInput] = useState<string>('');
    const [inputErrorText, setInputErrorText] = useState<string | undefined>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openStartOverModal, setOpenStartOverModal] = useState<boolean>(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        hasWriteOrPublishPermissions().then((isAuthorized: boolean) => {
            setHasAccess(isAuthorized);
        });
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const replacementTemplateToken = searchParams.get('replacementTemplateToken');
        const originTemplateToken = searchParams.get('originTemplateToken');

        if (replacementTemplateToken) {
            setNewTokenInput(replacementTemplateToken);
        }

        if (originTemplateToken) {
            setOldTokenInput(originTemplateToken);
        }
    }, [location]);

    const validateReplacement = (
        oldLine: TemplateCatalog.EnsembleLine | undefined,
        newLine: TemplateCatalog.EnsembleLine | undefined,
    ): [string | undefined, ReplacementStatus] => {
        let errorText: string | undefined;
        let status: ReplacementStatus = 'pending';

        if (!oldLine) {
            errorText = 'Unable to load old ensemble line';
            status = 'failure';
        } else if (!newLine) {
            errorText = 'Unable to load new ensemble line';
            status = 'failure';
        } else if (oldLine.ensembleLineId === newLine.ensembleLineId) {
            errorText = 'Replacement was already performed.';
            status = 'already replaced';
        } else if (oldLine.designConceptId !== newLine.designConceptId) {
            errorText = 'Old and new ensemble lines must be in the same design concept.';
            status = 'failure';
        } else if (oldLine.ensembles.length !== newLine.ensembles.length) {
            errorText = 'Original and new ensemble lines must each contain the same number of ensembles.';
            status = 'failure';
        } else {
            const oldPanelTypes = oldLine.ensembles[0].templates.map((t) => t.ensemblePanelType);
            const newPanelTypes = newLine.ensembles[0].templates.map((t) => t.ensemblePanelType);

            if (oldPanelTypes.some((p) => !newPanelTypes.includes(p))) {
                errorText = 'New ensembles must include all panel types from old ensembles.';
                status = 'failure';
            }
        }

        return [errorText, status];
    };

    const loadLines = async (tokens: [string, string], index: number): Promise<Replacement> => {
        const oldLine = await getEnsembleLineByTemplateToken(tokens[0]);
        const newLine = await getEnsembleLineByTemplateToken(tokens[1]);

        const [errorText, status] = validateReplacement(oldLine, newLine);

        return {
            index,
            status,
            errorText,
            oldToken: tokens[0],
            newToken: tokens[1],
            oldLine,
            newLine,
        };
    };

    const onClickLoad = async (): Promise<void> => {
        setInputErrorText(undefined);

        const oldTokens = splitInputByWhitespaceOrCommaAndValidateTemplateTokens(oldTokenInput);
        const newTokens = splitInputByWhitespaceOrCommaAndValidateTemplateTokens(newTokenInput);

        if (oldTokens.length !== newTokens.length) {
            setInputErrorText('Old and new token lists must be the same length. Please double check that all tokens are valid.');
            return;
        }

        if (new Set(oldTokens).size !== oldTokens.length) {
            setInputErrorText('List of old tokens contains duplicates');
            return;
        }

        if (new Set(newTokens).size !== newTokens.length) {
            setInputErrorText('List of new tokens contains duplicates');
            return;
        }

        setIsLoading(true);

        const loadingTasks: Promise<Replacement>[] = [];

        for (let i = 0; i < oldTokens.length; i += 1) {
            const oldToken = oldTokens[i];
            const newToken = newTokens[i];

            loadingTasks.push(loadLines([oldToken, newToken], i)); // Capture index to preserve input order
        }

        const loaded = await Promise.all(loadingTasks);

        const ducIds: Set<string> = new Set();
        const dpsIds: Set<string> = new Set();

        loaded.forEach((replacement) => {
            if (replacement.oldLine) {
                ducIds.add(replacement.oldLine.designUseCaseId);
                dpsIds.add(replacement.oldLine.ensembles[0].templates[0].designPhysicalSpecId);
            }
            if (replacement.newLine) {
                ducIds.add(replacement.newLine.designUseCaseId);
                dpsIds.add(replacement.newLine.ensembles[0].templates[0].designPhysicalSpecId);
            }
        });

        const ducs = await searchDucs([...ducIds]);

        const dpsData = await batchGetDps([...dpsIds]);

        setIsLoading(false);
        setIsLoaded(true);
        setDesignPhysicalSpecs(dpsData);
        setDesignUseCases(ducs);
        setReplacements(loaded.sort((a, b) => a.index - b.index));
    };

    const onClickStartOver = (): void => {
        setOpenStartOverModal(true);
    };

    const onConfirmStartOver = (): void => {
        setOpenStartOverModal(false);
        setInputErrorText(undefined);
        setReplacements([]);
        setOldTokenInput('');
        setNewTokenInput('');
        setIsLoaded(false);
    };

    const onChangeReplacementStatus = (oldToken: string, status: ReplacementStatus, correlationId?: string, errorText?: string): void => {
        // Must be a callback to avoid race condition with multiple rows updating the state at once
        // and overwriting each other with stale values
        setReplacements((prev) => prev.map((r) => (r.oldToken === oldToken ? {
            ...r, status, correlationId, errorText,
        } : r)));
    };

    const onClickExport = async (): Promise<void> => {
        const results = replacements.map((r) => ({
            oldToken: r.oldToken,
            newToken: r.newToken,
            status: r.status,
            errorText: r.errorText,
            orginalTemplateId: r.status === 'success' && r.newLine
                ? r.newLine
                    .ensembles.filter((e) => e.designProperties.isProminentColorVariant)[0]
                    .templates.filter((t) => t.ensemblePanelType === 'front')[0]
                    .templateId
                : undefined,
            errorGuid: r.correlationId,
        }));

        const csvData = new ObjCsv(results);
        const generatedCsv = new File([await csvData.toString()],
            `results-${new Date().toJSON().replaceAll(/-|:|\.|Z/g, '').replace('T', '_')}.csv`);

        const element = document.createElement('a');

        element.href = URL.createObjectURL(generatedCsv as Blob);
        element.download = generatedCsv?.name || '';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return hasAccess ? (
        <div className="replace-page">
            <h2>Ensemble Line Replacement</h2>

            <Grid container className="input-area" justify="space-evenly" spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        className="token-input"
                        type="textarea"
                        label="Enter original monolith template tokens, one per line"
                        value={oldTokenInput || ''}
                        disabled={isLoaded || isLoading}
                        onChange={(e): void => setOldTokenInput(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className="token-input"
                        type="textarea"
                        label="Enter replacement ACE template tokens, one per line"
                        value={newTokenInput || ''}
                        disabled={isLoaded || isLoading}
                        onChange={(e): void => setNewTokenInput(e.target.value)}
                    />
                </Grid>
                <Grid item className="instructions" xs={12}>
                    <small>Lists must be the same length.
                        Replacement ensemble lines will be paired with originals based on order of template tokens.</small>
                </Grid>

                {inputErrorText && <Alert message={inputErrorText} dismissible={false} />}
                <div>
                    {isLoading
                        ? <p>Loading...</p>
                        : <Button variant="primary" disabled={oldTokenInput === '' || newTokenInput === '' || isLoaded} onClick={onClickLoad}>Load Line Previews</Button> }
                    {isLoaded && <Button variant="secondary" onClick={onClickStartOver}>Start Over</Button>}
                </div>
            </Grid>

            {replacements.map((r) => (
                <LineReplacement
                    key={r.index}
                    replacement={r}
                    designUseCases={designUseCases}
                    designPhysicalSpecs={designPhysicalSpecs}
                    onChangeReplacementStatus={onChangeReplacementStatus}
                />))}

            {isLoaded && <Grid item container xs={12} direction="row">
                <Button className="export-button" variant="primary" onClick={onClickExport}>Export Results</Button>
            </Grid>}

            <Modal
                closeButton
                status="warning"
                show={openStartOverModal}
                title="Are you sure you want to start over?"
                footer={
                    <>
                        <Button variant="primary" onClick={onConfirmStartOver}>Confirm</Button>
                        <Button variant="outline-secondary" onClick={onClickExport}>Export Results</Button>
                        <Button variant="outline-secondary" onClick={(): void => setOpenStartOverModal(false)}>Cancel</Button>
                    </>
                }
                onRequestHide={(): void => setOpenStartOverModal(false)}
            >
                <p>Clicking confirm will clear the currently loaded tokens and lines.</p>
                <p>Note this <b>will not</b> revert any replacements you have already saved.</p>
                <p>Don&apos;t forget to export your results first!</p>
            </Modal>

        </div>
    ) : (
        <span>Access Denied</span>
    );
};

export default ReplaceLinePage;
