import axios from 'axios';
import { getEnvConfig } from '../envConfig';

function getBaseUri(): string {
    return `${getEnvConfig().dtecUrl}`;
}

export async function searchDucs(ids: string[]): Promise<DesignUseCase.DesignUseCaseDto[]> {
    let url = `https://${getBaseUri()}/api/v4/designUseCases:search?requestor=${getEnvConfig().requestorParam}`;

    ids.forEach((id) => {
        url += `&designUseCaseIds=${id}`;
    });

    const response = await axios.get<DesignUseCase.DesignUseCaseDto[]>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
        },
        validateStatus: () => true,
    });

    if (!response) {
        return [];
    }

    return response.status > 299 ? [] : response.data;
}

export async function getAllDucs(): Promise<DesignUseCase.DesignUseCaseDto[]> {
    const url = `https://${getBaseUri()}/api/v4/designUseCases?requestor=${getEnvConfig().requestorParam}`;

    const response = await axios.get<DesignUseCase.DesignUseCaseDto[]>(url, {
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
        },
        validateStatus: () => true,
    });

    if (!response) {
        return [];
    }

    return response.status > 299 ? [] : response.data;
}
