import { splitInputByWhitespaceAndComma } from './utilityHelpers';

// Used to recoginze and validate a ComboID
export const NumericStringRegularExpression = /^[0-9]+$/;

export const GuidRegularExpression = new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$', 'i');
// TODO: We need to sanitize inputs, but this is somewhat fragile. Not only do we expect the token format to change,
// we cannot anticipate what kinds of ids our clients may use.
// If DTS wants to start using something other than GUIDs for their ids that is not a contract change
// -- in general we should treat ids as opaque strings. Ideally we can make this more resilient while still sanitizing.
//
// At minimum, next time we add an accepted format, we should make this an array of regex's one per format rather than trying
// to extend this single regex again.
//
// Used to recoginze and validate a templateToken
const TemplateMatchRegularExpressionSubset = /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/;

export const TemplateTokenRegularExpression = new RegExp(`^(?:c[0-9]*|s(${TemplateMatchRegularExpressionSubset.source}|[0-9]*)(:v[0-9]+)?)\\.\\.${TemplateMatchRegularExpressionSubset.source}$`);

// Use this function for any user input that is reserved for Template Tokens only.
// Returns every valid template token in the input string
// Per RFC 5.2.1 all inputs must be validated using best practices
export function splitInputByWhitespaceOrCommaAndValidateTemplateTokens(input: string): string[] {
    return splitInputByWhitespaceAndComma(input)
        .filter((s) => !!s && s.match(TemplateTokenRegularExpression));
}
