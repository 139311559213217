import axios, { AxiosRequestConfig } from 'axios';
import auth from '../auth';
import {
    ApiSuccessResponse, ApiResponseOrError, createErrorResponse, createResponse,
} from './ApiResponseOrError';

export const axiosDefaultConfig = (): AxiosRequestConfig => ({
    headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    validateStatus: (status) => status < 400,
} as AxiosRequestConfig);

export async function doGetRequest<T>(
    url: string,
    config?: AxiosRequestConfig,
    applicationName?: string,
): Promise<ApiResponseOrError<T>> {
    try {
        const response = await axios.get<T>(url, config ?? axiosDefaultConfig());

        return createResponse(response);
    } catch (error) {
        return createErrorResponse(error, url, applicationName);
    }
}

export async function doPatchRequest<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    body: unknown,
    config?: AxiosRequestConfig,
    applicationName?: string,
): Promise<ApiResponseOrError<T>> {
    try {
        const response = await axios.patch<T>(url, body, config ?? axiosDefaultConfig());

        return createResponse(response);
    } catch (error) {
        return createErrorResponse(error, url, applicationName);
    }
}

export async function doPostRequest<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    body: unknown,
    config?: AxiosRequestConfig,
    applicationName?: string,
): Promise<ApiResponseOrError<T>> {
    try {
        const response = await axios.post<T>(url, body, config ?? axiosDefaultConfig());

        return createResponse(response);
    } catch (error) {
        return createErrorResponse(error, url, applicationName);
    }
}

export function isSuccessfulResponse<T>(response: ApiResponseOrError<T>): response is ApiSuccessResponse<T> {
    return response.responseType === 'success';
}

export function getResponseError(response: ApiResponseOrError<unknown>): string {
    if (response.responseType === 'error') {
        return response.content || 'Unknown error happened';
    }

    return '';
}
