function convertTwipsToCm(twipNumber: number): string {
    // 1 cm = 566.929133858 twips.
    // This is accurate to two decimal places.
    // There are variations online of exact conversion but should be accurate to 2 decimals
    const cmNumber = twipNumber / 566.929133858;

    // Trim the decimal to 2 places for better display
    return cmNumber.toFixed(2);
}

/* eslint-disable @typescript-eslint/brace-style */
export function getDpsDisplayText(dps: DesignPhysicalSpec.DesignPhysicalSpecDto): string {
    // Display Rules:
    //      Always show Trim dimensions
    //      Only show opacity if it is not opaque
    //      Show substrate colors when opacity is opaque and substrate color is non-white
    //      (This means opacity will never be present alongside substrate color)
    const trimData = dps?.spec.outlines.trim.sizeInTwips;

    if (!trimData || trimData.height <= 0 || trimData.width <= 0) {
        return '';
    }

    // Add Trim Dimensions to display
    const trimWidthInCm = convertTwipsToCm(trimData.width);
    const trimHeightInCm = convertTwipsToCm(trimData.height);

    return `${trimWidthInCm}cm x ${trimHeightInCm}cm`;
}

export function getDpsDisplayColor(dps: DesignPhysicalSpec.DesignPhysicalSpecDto): string {
    const opacity = dps?.spec.substrate.opacity;

    // Add Opacity
    if (opacity && opacity !== 'opaque') {
        return `${opacity}`;
    }

    // Need to remove whitespace incase the value is valid but something like RGB(255, 255, 255)
    const substrateColor = dps?.spec.substrate.color.replace(/\s+/g, '').toLocaleLowerCase();

    // Or add substrate color
    if (opacity === 'opaque' && substrateColor) {
        return `${substrateColor}`;
    }

    return '';
}

export function delayAwait(start: number, minDelay: number): Promise<unknown> {
    return new Promise((resolve) => {
        const timePassed = Date.now() - start;
        const timeToWait = minDelay - timePassed;

        if (timeToWait < 0) {
            resolve(true);
        }
        setTimeout(() => {
            resolve(true);
        }, timeToWait);
    });
}

// Works with every type of whitespace as well as commas.
// Use this function any time input needs to be cleaned and collected
// Per RFC 5.2.1 all inputs must be validated using best practices
export function splitInputByWhitespaceAndComma(input: string): string[] {
    return input.split(/\s+|,/).filter((s) => !!s) || [];
}

// Checks if the given input string is only numbers and letters (no symbols)
// Fails for GUID's and whitespace
export function isAlphaNumeric(input: string): boolean {
    return input.match(/^[a-z0-9]+$/i) != null;
}
