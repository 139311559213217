/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

interface Props {
    error?: string;
    correlationId?: string;
}

const ReplacementError = ({ error, correlationId }: Props): JSX.Element => (
    <>
        <p>{error || 'Something went wrong.'}</p>
        {correlationId && (<p><small>Error guid: {correlationId}</small></p>)}
    </>);

export default ReplacementError;
