import axios from 'axios';
import { getEnvConfig } from '../envConfig';

export interface ContentAreaTenantResult {
    tenants: ContentAreaTenant[];
}

export interface ContentAreaTenant {
    accountId: string;
    accountName: string;
    contentAreaId: string;
    contentAreaName: string;
    description: string;
}

function getBaseUri(): string {
    return `${getEnvConfig().tenantRegistryUrl}`;
}

const authoringTenants: Map<string, string> = new Map<string, string>();

export async function getContentAreaIdToContentAuthoringAreaIdMap(): Promise<Map<string, string>> {
    if (authoringTenants.keys.length === 0) {
        try {
            const response = await axios.get(`https://${getBaseUri()}/authoring-tenants-config.json`, {
                headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                },
                validateStatus: () => true,
            });

            /* eslint-disable @typescript-eslint/no-explicit-any */
            response.data?.authoringTenants?.forEach((tenant: any) => {
                if (tenant) {
                    authoringTenants.set(tenant.contentAreaId, tenant.contentAuthoringAreaId);
                }
            });
        } catch (error) {
            return authoringTenants;
        }
    }

    return authoringTenants;
}
