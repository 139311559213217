const TryParseValue = (input: string): string[] => {
    try {
        const lines = input.split(/\r?\n/g);
        const nonEmptyLines = lines.filter((n) => /\S/.test(n)); // \S matches all strings excpet whitespaces(spaces, tabs and new lines)

        return nonEmptyLines;
    } catch {
        return [];
    }
};

export default TryParseValue;
