import React from 'react';
import { Card, CopyButton } from '@cimpress/react-components';
import GeneratedEnsembleMetadata from './generatedEnsembleMetadata';
import GeneratedEnsemblePanelPreview from './generatedEnsemblePanelPreview';
import '../templateGeneration/generatedPreviewGrouping.scss';
import { GeneratedArtworkJobItem } from '../../services/gamService';
import { extractPanelSizes } from '../../pages/composeTemplatePage/helpers/documentPanelSizeExtractor';
import DuplicateCheckmark from './duplicateCircleView';

interface Props {
    jobItem: GeneratedArtworkJobItem;
}

const PublishedComposablePreview = ({ jobItem }: Props): JSX.Element => (
    <Card className="composable-preview-tile-container">
        <div className="composable-preview-tile-header">
            <span>
                <strong>Source</strong>
                <br />
                <CopyButton className="copy-button-text" variant="anchor" value={jobItem.jobItemInput.sourceTemplateTokens[0]}>{jobItem.jobItemInput.sourceTemplateTokens}</CopyButton>
            </span>
            {jobItem.previouslyPublishedSubItem && <DuplicateCheckmark jobItem={jobItem} />}
        </div>
        <div className="ensemble-preview-body">
            <div className="ensemble-preview-body-panels">
                <GeneratedEnsemblePanelPreview
                    item={jobItem.subItem}
                    panelSizes={extractPanelSizes(jobItem.subItem.generatedDocument)}
                />
            </div>
            <GeneratedEnsembleMetadata jobItem={jobItem} />
        </div>
    </Card>
);

export default PublishedComposablePreview;
