import React from 'react';
import _ from 'lodash';
import { Button } from '@cimpress/react-components';
import { GeneratedArtworkJobItem, JobItemSubItemPanel } from '../../services/gamService';
import DualColumnMetadataView from './dualColumnMetadataView';
import '../templateGeneration/generatedEnsembleMetadata.scss';
import SingleColumnMetadataView from './singleColumnMetadataView';
import PublishedTemplateTokenView from './publishedTemplateTokenView';
import isLastPublishedTokenIsSameAsCurrentJobItem from '../../pages/composeTemplatePage/helpers/templateCheckUtility';
import { getEnsemblesContainingTemplateToken } from '../../services/dtecService';
import fetchDucNamesDpsDisplayNames from '../../utils/ensemblesUtility';

interface Props {
    jobItem: GeneratedArtworkJobItem;
}

const GeneratedEnsembleMetadata = ({ jobItem }: Props): JSX.Element => {
    // Max panels supported is two panel. Revisit when more than 2 panels has to be supported.
    const panels = jobItem.subItem.panels.slice(0, 2);
    const isPanelPropertiesSame = panels.length > 1
        ? _.isEqual(_.omit(panels[0].designPhysicalSpec), _.omit(panels[1].designPhysicalSpec))
        : true;
    const isLastPublishedTokenIsSameAsCurrentJobItemValues = isLastPublishedTokenIsSameAsCurrentJobItem(jobItem);
    const isCurrentJobItemPublishedAndLastPublishedTokenIsSameAsCurrentJob = jobItem.subItem.panels
        .filter((x) => x.generatedTemplateToken).length > 0
        && isLastPublishedTokenIsSameAsCurrentJobItemValues;
    const isPreviouslyPublishedAndLastPublishedTokenIsNotSameAsCurrentJobItem = jobItem.previouslyPublishedSubItem
     && !isLastPublishedTokenIsSameAsCurrentJobItemValues;
    const onClick = async (panel?: JobItemSubItemPanel): Promise<void> => {
        const ensembles = panel?.generatedTemplateToken ? await getEnsemblesContainingTemplateToken(panel.generatedTemplateToken)
            : undefined;

        if (ensembles) {
            await fetchDucNamesDpsDisplayNames(ensembles);
            const newWindow = window.open(`/ensembleDetails/${ensembles[0].ensembleId}`, '_blank', 'noopener,noreferrer');

            if (newWindow) newWindow.opener = null;
        }
    };

    return (<div className="ensemble-metadata-parent">
        <div>
            <span className="additional-info-property">PRD:</span>
            {` ${jobItem.jobItemInput.product}`}
        </div>
        <div className="ensemble-metadata">
            {(panels.length > 1 && !isPanelPropertiesSame)
                ? <DualColumnMetadataView firstColumnPanel={panels[0]} secondColumnPanel={panels[1]} />
                : <SingleColumnMetadataView panel={panels[0]} />}
        </div>
        {isCurrentJobItemPublishedAndLastPublishedTokenIsSameAsCurrentJob && <div>
            <span className="additional-info-property">Published Template Token: </span>
            <Button target="_blank" variant="anchor" onClick={(): Promise<void> => onClick(jobItem.subItem.panels[0])}>Show In DTeC</Button>
        </div>}
        {isPreviouslyPublishedAndLastPublishedTokenIsNotSameAsCurrentJobItem && <div>
            <span className="additional-info-property">Another version is published: </span>
            <Button target="_blank" variant="anchor" onClick={(): Promise<void> => onClick(jobItem.previouslyPublishedSubItem?.panels[0])}>Show In DteC</Button>
        </div>}
        {jobItem.subItem.panels.filter((x) => x.generatedTemplateToken).length > 0 && isLastPublishedTokenIsSameAsCurrentJobItemValues
        && <PublishedTemplateTokenView panels={jobItem.subItem.panels} />}
        {jobItem.previouslyPublishedSubItem && !isLastPublishedTokenIsSameAsCurrentJobItemValues
        && <PublishedTemplateTokenView panels={jobItem.previouslyPublishedSubItem.panels} />}
    </div>);
};

export default GeneratedEnsembleMetadata;
