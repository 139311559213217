import React from 'react';
import { Card } from '@cimpress/react-components';
import { Link } from 'react-router-dom';
import EnsembleDetailsCard from '../browseEnsembles/ensembleDetailsCard';

interface Props {
    ensemble: TemplateCatalog.Ensemble;
}

/* eslint-disable react/jsx-one-expression-per-line */
const EnsembleSearchResult = ({ ensemble }: Props): JSX.Element => (
    <Card>
        <EnsembleDetailsCard ensemble={ensemble} />
        <Link
            to={{ pathname: `/ensembleDetails/${ensemble.ensembleId}`, state: { ensemble } }}
            key={ensemble.ensembleId}
            data-testid={`link-ensemble-${ensemble.ensembleId}`}
        >
            {'Ensemble Details >'}
        </Link>
    </Card>
);
/* eslint-enable react/jsx-one-expression-per-line */

export default EnsembleSearchResult;
