const DEFAULT_PAGE_SIZE = 20;
const JOB_HISTORY_PAGE_SIZE_KEY = 'jobHistoryPageSize';

export const FIRST_PAGE_NUMBER = 1;

export const getUserPreferredPageSize = (): number => {
    const pageSize = localStorage.getItem(JOB_HISTORY_PAGE_SIZE_KEY);

    if (pageSize) {
        return parseInt(pageSize, DEFAULT_PAGE_SIZE);
    }

    return DEFAULT_PAGE_SIZE;
};

export const setUserPreferredPageSize = (pageSize: number): void => {
    localStorage.setItem(JOB_HISTORY_PAGE_SIZE_KEY, pageSize.toString());
};
