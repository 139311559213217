import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Button } from '@cimpress/react-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuidV4 } from 'uuid';
import TemplateTokensSelector from '../templateGeneration/templateTokensSelector';
import {
    TargetProductSpecification,
    areAllRequiredFieldsPresent,
} from '../templateGeneration/TargetProductSpecification';
import './resizeTemplateInput.scss';
import DestinationAccordion, { CopyData } from './DestinationAccordion';

interface Props {
    requestPreviews: (templateTokens: string[], targetProductSpecifications: TargetProductSpecification[]) => void;
}

const ResizeTemplateInput = ({ requestPreviews }: Props): JSX.Element => {
    const [templateTokens, setTemplateTokens] = useState<string[]>([]);
    const [destinationSelections, setDestinationSelections] = useState<TargetProductSpecification[]>([]);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [copyData, setCopyData] = useState<CopyData | null>(null);

    const onSubmit = (): void => {
        requestPreviews(templateTokens, destinationSelections);
    };

    const isDestinationSelectionsValid = (): boolean => {
        if (!destinationSelections.length) {
            return false;
        }
        return destinationSelections
            .every((value): boolean => !!areAllRequiredFieldsPresent(value as TargetProductSpecification).isSuccessful);
    };

    useEffect(() => {
        setDestinationSelections([{
            index: uuidV4(),
        }] as TargetProductSpecification[]);
    }, []);

    const onChangeValue = useCallback((key, value, index): void => {
        setDestinationSelections((prev: TargetProductSpecification[]) => {
            if (prev.some((item) => item.index === index)) {
                return prev.map((item) => {
                    if (item.index === index) {
                        return {
                            ...item,
                            [key]: value,
                        };
                    }
                    return item;
                });
            }

            return [...prev, { [key]: value, index }] as TargetProductSpecification[];
        });
    }, []);

    const onDeleteDestination = useCallback((index: string): void => {
        setDestinationSelections((prev) => prev.filter((item) => item.index !== index));
        setCopyData(null);
    }, []);

    const onCopyDestination = useCallback((index: string, data: CopyData): void => {
        setDestinationSelections((prev) => {
            const copy = prev.find((item) => item.index === index);

            return copy ? [...prev, { ...copy, index: data.index }] : prev;
        });
        setCopyData(data);
    }, []);

    const handleAddDestination = (): void => {
        setDestinationSelections((prev) => [...prev, { index: uuidV4() }] as TargetProductSpecification[]);
    };

    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-around"
            >
                <Grid style={{ width: '45%' }}>
                    <div className="title">Source</div>
                    <TemplateTokensSelector
                        validateTenant
                        initialValue={templateTokens}
                        label="Source template tokens"
                        setIsTokenValid={setIsTokenValid}
                        onTemplateTokensChanged={setTemplateTokens}
                    />
                </Grid>

                <div className="divider" />
                <Grid style={{ width: '45%' }}>
                    <div className="title">Destination </div>
                    {destinationSelections.map((item) => (
                        <DestinationAccordion
                            key={item.index}
                            expanded={item.index === destinationSelections[destinationSelections.length - 1].index}
                            index={item.index as string}
                            destinationsCount={destinationSelections.length}
                            copyData={copyData?.index === item.index ? copyData : null}
                            onChangeValue={onChangeValue}
                            onDeleteDestination={onDeleteDestination}
                            onCopyDestination={onCopyDestination}
                        />
                    ))}
                    <Button className="add-more" onClick={handleAddDestination}>
                        <Add />
                    </Button>
                    <Grid item>
                        <Button
                            className="generate-previews-button"
                            variant="primary"
                            disabled={
                                !isDestinationSelectionsValid()
                                || (!isTokenValid
                                    || !templateTokens
                                    || templateTokens.length === 0)
                            }
                            onClick={onSubmit}
                        >
                            Preview
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ResizeTemplateInput;
