import { TextField } from '@cimpress/react-components';
import React from 'react';

interface Props{
    value: string;
    isValidEmail: boolean;
    onStrategistChange: (value: string) => void;
}

const Strategist = ({ value, isValidEmail, onStrategistChange }: Props): JSX.Element => <TextField
    type="email"
    label="Strategist"
    status={isValidEmail ? 'success' : 'error'}
    value={value || ''}
    onChange={(e): void => onStrategistChange(e.target.value)}
/>;

export default Strategist;
