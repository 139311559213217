import _ from 'lodash';
import { GeneratedArtworkJobItem } from '../../../services/gamService';

const isLastPublishedTokenIsSameAsCurrentJobItem = (jobItem: GeneratedArtworkJobItem): boolean => {
    const currentJobItemTemplateTokens = jobItem.subItem.panels.map((x) => x.generatedTemplateToken)
        .filter((y): y is string => y !== undefined || y !== null);
    const otherVersionTemplateToken = jobItem.previouslyPublishedSubItem?.panels.map((x) => x.generatedTemplateToken)
    .filter((y): y is string => y !== undefined || y !== null) ?? [];

    return _.isEqual(currentJobItemTemplateTokens, otherVersionTemplateToken);
};

export default isLastPublishedTokenIsSameAsCurrentJobItem;
