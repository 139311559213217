import React from 'react';
import { JobItemSubItemPanel } from '../../services/gamService';
import SingleColumnMetadataView from './singleColumnMetadataView';

interface Props {
    firstColumnPanel: JobItemSubItemPanel;
    secondColumnPanel: JobItemSubItemPanel;
}
const DualColumnMetadataView = ({ firstColumnPanel, secondColumnPanel }: Props): JSX.Element => <>
    <SingleColumnMetadataView panel={firstColumnPanel} />
    <div className="vertical" />
    <SingleColumnMetadataView panel={secondColumnPanel} />
</>;

export default DualColumnMetadataView;
