/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    ChangeEvent,
    MouseEvent,
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    TextField,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import {
    DeleteForeverOutlined,
    FileCopyOutlined,
} from '@material-ui/icons';
// @ts-ignore
import { v4 as uuidV4 } from 'uuid';
// @ts-ignore
import GenericSelector from '@cimpress-technology/generic-selector';
import { Dropdown, Label } from '@cimpress/react-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ButtonBar from './ButtonBar';
import ColorInput from './ColorInput';
import auth from '../../auth';
import {
    getProduct,
    getProductBySKU,
    getProductByVersion,
    RootProduct,
    Version,
} from '../../services/productService';

export interface CopyData {
    index: string;
    id: string;
    currentVersion: Version | null;
    productOptions: string;
    designUseCaseId: string;
    orientation: string;
    opacity: string;
    substrateColor: string;
}
interface Props {
    expanded: boolean;
    onChangeValue: (key: string, value: string | number, index: string) => void;
    index: string;
    onDeleteDestination: (index: string) => void;
    onCopyDestination: (index: string, item: CopyData) => void;
    destinationsCount: number;
    copyData: CopyData | null;
}

interface SelectorRef {
    getUserSelections: () => Record<string, string>;
    buildSelectorState: (item: Record<string, string>) => void;
    state: Record<string, string>;
}

const emptySelectorMessage = 'This product has no selectable attributes.';

const DestinationAccordion = ({
    expanded,
    onChangeValue,
    index,
    onDeleteDestination,
    onCopyDestination,
    destinationsCount,
    copyData,
}: Props): JSX.Element => {
    const [designUseCaseId, setDesignUseCaseId] = useState('');
    const [orientation, setOrientation] = useState(() => {
        onChangeValue('orientation', 'Horizontal', index);
        return 'Horizontal';
    });
    const [opacity, setOpacity] = useState(() => {
        onChangeValue('substrateOpacity', 'Opaque', index);
        return 'Opaque';
    });
    const [id, setId] = useState('');
    const [PRD, setPRD] = useState('');
    const [SKU, setSKU] = useState('');
    const [product, setProduct] = useState<null | RootProduct>(null);
    const [substrateColor, setSubstrateColor] = useState(() => {
        onChangeValue('substrateColor', 'RGB(255,255,255)', index);
        return 'RGB(255,255,255)';
    });
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [isValidColor, setIsValidColor] = useState(true);
    const [isValidDesignUseCaseId, setIsValidDesignUseCaseId] = useState(true);
    const [versions, setVersions] = useState<Version[]>([]);
    const [currentVersion, setCurrentVersion] = useState<Version | null>(null);
    const [selectorAttributeConfigurations, setSelectorAttributeConfigurations] = useState(null);
    const token = auth.getAccessToken();
    const selectorRef = useRef<SelectorRef | null>(null);
    const isFirstRender = useRef<boolean>(true);

    const getCopyDataProductOptions = (): Record<string, Record<string, string>> | Record<string, undefined> => {
        if (copyData?.productOptions) {
            const parsedOptions = JSON.parse(copyData.productOptions);
            const keys = Object.keys(parsedOptions);

            keys.forEach((key: string) => {
                parsedOptions[key] = { initialSelection: parsedOptions[key] };
            });
            return parsedOptions;
        }
        return {};
    };

    const defaultVPAttributeConfig = {
        quantity: { isHidden: true },
        ...getCopyDataProductOptions(),
    };
    const defaultGenericAttributeConfig = {
        quantity: { isHidden: false },
        ...getCopyDataProductOptions(),
    };

    const setStateMap = useMemo(() => ({
        orientation: (value: string): void => setOrientation(value),
        substrateColor: (value: string): void => setSubstrateColor(value),
        opacity: (value: string): void => setOpacity(value),
        id: (value: string): void => setId(value),
        designUseCaseId: (value: string): void => setDesignUseCaseId(value),
        currentVersion: (value: Version): void => setCurrentVersion(value),
        productData: (value: RootProduct): void => setProduct(value),
    }), []);

    const selectorErrorAlertConfigurations = {
        showErrorAlert: false,
    };

    const onChangeDesignUseCaseId = (value: string): void => {
        if (!isValidDesignUseCaseId) {
            setIsValidDesignUseCaseId(!!value.length);
        }
        setDesignUseCaseId(value);
        onChangeValue('designUseCaseId', value, index);
    };
    const onChangeOrientation = (value: string): void => {
        setOrientation(value);
        onChangeValue('orientation', value, index);
    };
    const onChangeOpacity = (value: string): void => {
        setOpacity(value);
        onChangeValue('substrateOpacity', value, index);
    };
    const onChangeSubstrateColor = (value: string): void => {
        setSubstrateColor(value);
        onChangeValue('substrateColor', value, index);
    };

    const onChangeSelector = useCallback((): void => {
        const selections = selectorRef?.current?.getUserSelections();

        onChangeValue('productOptions', JSON.stringify(selections), index);
    }, [index, onChangeValue]);

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded]);

    const handleVersions = useCallback((response: RootProduct): void => {
        if (response.versions) {
            setVersions(response.versions);
            const version = response.versions.find((item) => response.version === item.version) || response.versions[0];

            setCurrentVersion(version);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const blackList = ['index', 'productOptions'];

        if (copyData && isFirstRender.current) {
            Object.keys(copyData).forEach((item) => {
                if (blackList.indexOf(item) === -1) {
                    // @ts-ignore
                    setStateMap[item](copyData[item]);
                }
            });
            isFirstRender.current = false;
        }
    }, [copyData, setStateMap]);

    const resetState = (): void => {
        setPRD('');
        setSKU('');
        setProduct(null);
        setCurrentVersion(null);
    };

    useEffect(() => {
        async function setProductOnChange(): Promise<void> {
            const PRDPattern = /^PRD-[a-zA-Z0-9]{8,9}$/;
            const SKUPattern = /^CIM-[a-zA-Z0-9]{8,9}$/;

            if (SKUPattern.test(id)) {
                const response = await getProductBySKU(id, false);

                setSKU(id);
                setProduct(response);
                handleVersions(response);
                onChangeValue('product', id, index);
                return;
            }

            if (PRDPattern.test(id)) {
                const response = await getProduct(id, currentVersion?.version);

                setPRD(id);
                setProduct(response);
                handleVersions(response);
                onChangeValue('product', response.productId, index);
            }
        }
        setProductOnChange();
    }, [currentVersion?.version, handleVersions, id, index, onChangeValue]);

    const getStatus = useCallback((item: Version): 'danger' | 'default' | 'info' | 'primary' | 'success' | 'warning' => {
        if (product?.currentVersion === item.version) {
            return 'success';
        }
        if (item.status === 'ACTIVE') {
            return 'info';
        }
        if (item.status === 'OPEN') {
            return 'primary';
        }
        if (item.status === 'RETIRED') {
            return 'default';
        }
        return 'warning';
    }, [product?.currentVersion]);

    const versionsDropdownTitle = useMemo(() => currentVersion && (
        <span>
            Version :
            {` ${currentVersion.version}`}
            <Label
                status={getStatus(currentVersion)}
                className="label"
                text={product?.currentVersion === currentVersion.version ? 'CURRENT' : currentVersion?.status}
            />
        </span>
    ), [currentVersion, getStatus, product?.currentVersion]);

    useEffect(() => {
        async function setProductOnVersionChange(): Promise<void> {
            if (currentVersion && product && product?.version !== currentVersion?.version) {
                if (SKU) {
                    const response = await getProductByVersion(SKU, currentVersion?.version);

                    setProduct(response);
                    return;
                }
                if (PRD) {
                    const response = await getProduct(PRD, currentVersion?.version);

                    setProduct(response);
                }
            }
        }
        setProductOnVersionChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVersion, product]);

    const onCopy = (e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        const dataToCopy = {
            index: uuidV4(),
            id,
            currentVersion,
            productOptions: selectorRef.current?.getUserSelections() ? JSON.stringify(selectorRef.current?.getUserSelections()) : '',
            designUseCaseId,
            orientation,
            opacity,
            substrateColor,
            productData: product,
        };

        onCopyDestination(index, dataToCopy);
    };

    const handleIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
        resetState();
        setId(event.target.value);
    };

    const onLoad = (isVP: boolean): void => {
        if (isVP && selectorRef.current) {
            const scrollRefErrorMessage = selectorRef.current.state.errorMessage;

            // set empty productOptions for product that doesn't have selectable values
            if (scrollRefErrorMessage === emptySelectorMessage) {
                onChangeValue('productOptions', '{}', index);
            } else {
                onChangeValue('productOptions', '', index);
            }
            setSelectorAttributeConfigurations(
                { quantity: { isHidden: true }, ...(copyData?.productOptions && JSON.parse(copyData.productOptions)) },
            );
            return;
        }
        setSelectorAttributeConfigurations(
            { quantity: { isHidden: false }, ...(copyData?.productOptions && JSON.parse(copyData.productOptions)) },
        );
    };

    return (
        <Accordion expanded={isExpanded}>
            <AccordionSummary
                className="accordion-container"
                expandIcon={<ExpandMoreIcon />}
                onClick={(): void => setIsExpanded((prev) => !prev)}
            >
                {id || 'Enter PRD or SKU to get started'}
                <div>
                    <IconButton
                        disabled={destinationsCount === 1}
                        onClick={(): void => onDeleteDestination(index)}
                    >
                        <DeleteForeverOutlined className="delete-button" />
                    </IconButton>
                    <IconButton onClick={onCopy}>
                        <FileCopyOutlined />
                    </IconButton>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Grid style={{ width: '100%' }}>
                    <TextField
                        label="PRD-ENRS4XZIS or CIM-ENRS4XZIS"
                        variant="outlined"
                        className="prd-input"
                        value={id}
                        onChange={handleIdChange}
                    />
                    <div className="input-container">
                        <ButtonBar
                            values={['Horizontal', 'Vertical']}
                            label="Product Orientation"
                            stateValue={orientation}
                            setStateValue={onChangeOrientation}
                        />
                        <ButtonBar
                            values={['Transparent', 'Opaque']}
                            label="Opacity"
                            stateValue={opacity}
                            setStateValue={onChangeOpacity}
                        />
                    </div>
                    <div className="input-container">
                        <ColorInput
                            color={substrateColor}
                            setColor={onChangeSubstrateColor}
                            isValidColor={isValidColor}
                            setIsValidColor={setIsValidColor}
                        />
                        <TextField
                            required
                            error={!isValidDesignUseCaseId}
                            label="designUseCaseId"
                            className="designUseCaseId-input"
                            variant="outlined"
                            value={designUseCaseId}
                            onBlur={(event): void => setIsValidDesignUseCaseId(!!event.target.value.length)}
                            onChange={(event): void => onChangeDesignUseCaseId(event.target.value)}
                        />
                    </div>
                    {!!currentVersion && (
                        <>
                            {!!PRD && (
                                <div style={{ width: '100%' }}>
                                    <Dropdown className="versionDropdown" title={versionsDropdownTitle}>
                                        {versions.map((item) => (
                                            <MenuItem
                                                value={item.version}
                                                key={item.version}
                                                className="version-option"
                                                onClick={(): void => setCurrentVersion(item)}
                                            >
                                                <span>
                                                    Version :
                                                    {` ${item.version}`}
                                                    <Label
                                                        status={getStatus(item)}
                                                        className="label"
                                                        text={product?.currentVersion === item.version ? 'CURRENT' : item?.status}
                                                    />
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Dropdown>
                                </div>
                            )}
                            {(SKU || PRD) && (
                                <GenericSelector
                                    productId={SKU || PRD}
                                    product={SKU ? product : undefined}
                                    productVersion={SKU ? currentVersion.version : product?.version}
                                    authToken={token}
                                    ref={selectorRef}
                                    errorAlertConfiguration={selectorErrorAlertConfigurations}
                                    attributeConfigurations={selectorAttributeConfigurations
                                        || (SKU ? defaultGenericAttributeConfig : defaultVPAttributeConfig)}
                                    onError={(): null => null}
                                    onChange={onChangeSelector}
                                    onLoad={(): void => (SKU ? onLoad(false) : onLoad(true))}
                                />
                            )}
                        </>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default DestinationAccordion;
