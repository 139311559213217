/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, Card } from '@cimpress/react-components';
import Loading from '../../components/common/loading';
import { hasWritePermissions } from '../../auth';
import * as gamService from '../../services/gamService';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import ReviewAndApprovePage from './reviewAndApprove';
import FailedJobDetailPage from './failedJobDetailPage';
import PublishedJobDetailPage from './publishedJobDetailPage';
import { getResponseError, isSuccessfulResponse } from '../../services/RequestUtils';
import ApiErrorToast from './ApiErrorToast';
import { Tenant } from '../../components/composableTemplates/TenantContext';
import { ApiResponseOrError } from '../../services/ApiResponseOrError';
import OriginalJobInput from '../../components/composableTemplates/originalJobInput';
import './composeJobDetailPage.scss';
import ClosedJobDetailPage from './closedJobDetailPage';

interface QueryStringParams {
    contentAccountId: string;
    contentAreaId: string;
    jobId: string;
}

const ComposeJobDetailPage = (): JSX.Element => {
    const { contentAccountId, contentAreaId, jobId } = useParams<QueryStringParams>();

    const tenant = { contentAccountId, contentAreaId } as Tenant;
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const [activeJob, setActiveJob] = useState<GeneratedArtworkJobDetail>();
    const [responseError, setResponseError] = useState<string>();
    const [rawResponse, setRawResponse] = useState<ApiResponseOrError<GeneratedArtworkJobDetail>>();

    useEffect(() => {
        hasWritePermissions().then((writePermissions: boolean) => {
            setHasAccess(writePermissions);
        });
    }, []);

    const loadAsset = async (t: Tenant, id: string) => {
        setIsLoading(true);
        const response = await gamService.getJob(t, id);

        setIsLoading(false);

        setRawResponse(response);
        if (isSuccessfulResponse(response)) {
            setActiveJob(response.data);
        }

        // if job is running, start polling
        const pendingJobResponse = await gamService.pollForCompletion(tenant, id);

        setRawResponse(pendingJobResponse);
        if (isSuccessfulResponse(pendingJobResponse)) {
            setActiveJob(pendingJobResponse.data);
        }

        setResponseError(getResponseError(response));
    };

    useEffect(() => {
        const fetchData = async () => {
            await loadAsset(tenant, jobId);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);

    return hasAccess ? (
        <>
            {isLoading && <Loading />}
            {!isLoading && activeJob && <>
                {activeJob.status === 'InProgress' && (
                    <Card>
                        <div className="previews-generation-wrapper">
                            <Loading />
                            <h2>Compose processing...</h2>
                        </div>
                    </Card>
                )}

                {activeJob.status === 'InReview' && (
                    <ReviewAndApprovePage job={activeJob} />
                )}
                {activeJob.status === 'Failed' && rawResponse && (
                    <FailedJobDetailPage job={activeJob} response={rawResponse} />
                )}
                {activeJob.status === 'Published' && rawResponse && (
                    <PublishedJobDetailPage job={activeJob} />
                )}
                {activeJob.status === 'Closed' && (
                    <ClosedJobDetailPage job={activeJob} />
                )}

                <Accordion className="accordion-job-input" title="Original Job Input">
                    <OriginalJobInput job={activeJob} />
                </Accordion>
            </>}
            <ApiErrorToast message={responseError} />
        </>
    ) : (
        <span>Access Denied</span>
    );
};

export default ComposeJobDetailPage;
