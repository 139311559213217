/**
 * Provides environment-specific configuration values. Use setEnvironment to
 * switch between dev and prod environment configurations.
 */
export interface EnvConfig {
    env: string;
    dtecUrl: string;
    dtecBackstageUrl: string;
    dterUrl: string;
    dpsUrl: string;
    asyncPollingInterval: number;
    asyncPollingTimeout: number;
    prepressAsyncPollingTimeout: number;
    gamAsyncPollingTimeout: number;
    rtgArn: string;
    instructionsDocumentsUrl: string;
    tenantRegistryUrl: string;
    camUrl: string;
    gamUrl: string;
    renderingUrl: string;
    storageUrl: string;
    productUrl: string;
    uploadsUrl: string;
    transientUploadTenant: string;
    merchantUrl: string;
    besProductsUrl: string;
    requestorParam: string;
}

const PROD_ENV_CONFIG: EnvConfig = {
    env: 'prod',
    dtecUrl: 'prod.templatecatalog.content.cimpress.io',
    dtecBackstageUrl: 'prod.dtecbackstage.content.cimpress.io',
    dterUrl: 'prod.templaterealization.content.cimpress.io',
    dpsUrl: 'prod.designphysicalspec.content.cimpress.io',
    asyncPollingInterval: 500,
    asyncPollingTimeout: 15000,
    prepressAsyncPollingTimeout: 100000,
    gamAsyncPollingTimeout: 600000,
    rtgArn: 'arn:aws:states:eu-west-1:354147838842:stateMachine:prod-resizedTemplateGeneration',
    instructionsDocumentsUrl: 'instructions.documents.cimpress.io',
    tenantRegistryUrl: 'contenttenantregistry.contentauthoring.cimpress.io',
    camUrl: 'cam.contentauthoring.cimpress.io',
    gamUrl: 'prod.gam.content.cimpress.io',
    renderingUrl: 'rendering.documents.cimpress.io',
    storageUrl: 'storage.documents.cimpress.io',
    productUrl: 'api.products.cimpress.io',
    uploadsUrl: 'uploads.documents.cimpress.io',
    transientUploadTenant: 'furl',
    merchantUrl: 'merchant-orchestration-api.products.cimpress.io',
    besProductsUrl: 'bes.products.cimpress.io',
    requestorParam: 'dtec-ui',
};

const DEV_ENV_CONFIG: EnvConfig = {
    env: 'dev',
    dtecUrl: 'dev.templatecatalog.content.cimpress.io',
    dtecBackstageUrl: 'dev.dtecbackstage.content.cimpress.io',
    dterUrl: 'dev.templaterealization.content.cimpress.io',
    dpsUrl: 'prod.designphysicalspec.content.cimpress.io',
    asyncPollingInterval: 1000,
    asyncPollingTimeout: 600000,
    prepressAsyncPollingTimeout: 100000,
    gamAsyncPollingTimeout: 600000,
    rtgArn: 'arn:aws:states:eu-west-1:354147838842:stateMachine:dev-resizedTemplateGeneration',
    instructionsDocumentsUrl: 'instructions.documents.cimpress.io',
    tenantRegistryUrl: 'contenttenantregistry.contentauthoring.cimpress.io',
    camUrl: 'dev.cam.contentauthoring.cimpress.io',
    gamUrl: 'dev.gam.content.cimpress.io',
    renderingUrl: 'rendering.documents.cimpress.io',
    storageUrl: 'storage.documents.cimpress.io',
    productUrl: 'api.products.cimpress.io',
    uploadsUrl: 'uploads.documents.cimpress.io',
    transientUploadTenant: 'furl',
    merchantUrl: 'merchant-orchestration-api.products.cimpress.io',
    besProductsUrl: 'bes.products.cimpress.io',
    requestorParam: 'dtec-ui-dev',
};

// REACT_APP_STAGE is set by running `npm run build-dev` or `npm run build-prod`
export function getEnvConfig(): EnvConfig {
    return process.env.REACT_APP_STAGE === 'prod' ? PROD_ENV_CONFIG : DEV_ENV_CONFIG;
}
