import React from 'react';
import { Alert, Card } from '@cimpress/react-components';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import ErrorHelper from './helpers/errorHelper';
import JobErrorDetailExtractor from './helpers/jobErrorDetailExtractor';
import { ApiResponseOrError } from '../../services/ApiResponseOrError';
import ComposeTemplateJobLabel from '../../components/templateGeneration/templateGenerationJobLabel';
import ReRunCompositionJob from '../../components/composableTemplates/reRunCompositionJob';

interface Props {
    job: GeneratedArtworkJobDetail;
    response: ApiResponseOrError<GeneratedArtworkJobDetail>;
}

const FailedJobDetailPage = ({ job, response }: Props): JSX.Element => {
    const error = ErrorHelper(response, JobErrorDetailExtractor);

    return (
        <Card>
            <div className="job-details-header">
                <h3>{`Job ID: ${job.id}`}</h3>
                <ComposeTemplateJobLabel status={job.status} />
                <ReRunCompositionJob job={job} />
            </div>
            <div>
                <Alert status="danger" message={error} dismissible={false} />
            </div>
        </Card>
    );
};

export default FailedJobDetailPage;
