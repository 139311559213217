import React from 'react';
import { Button, TextField } from '@cimpress/react-components';
import ObjCsv from 'objects-to-csv';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import './originalJobInput.scss';
import TemplateTokensSelector from '../templateGeneration/templateTokensSelector';
import DesignConceptsSelector from '../../pages/composeTemplatePage/designConceptsSelector';

interface Props {
    job: GeneratedArtworkJobDetail;
}

const OriginalJobInput = ({ job }: Props): JSX.Element => {
    const jobInput = JSON.parse(job.rawInput);
    const downloadProductOptions = async (): Promise<void> => {
        const csvData = new ObjCsv(jobInput.products);
        const generatedCsv = new File(
            [await csvData.toString()],
            `composable-template-${job.id}.csv`,
        );
        const element = document.createElement('a');

        element.href = URL.createObjectURL(generatedCsv as Blob);
        element.download = generatedCsv?.name || '';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (<div className="original-job-input-section">
        <h4>Original input</h4>
        <div className="product-options-overview">
            <TextField
                disabled
                type="textarea"
                value={JSON.stringify(jobInput, undefined, 4)}
                inputStyle={{
                    minHeight: '300px',
                    minWidth: '30%',
                    maxWidth: '100%',
                }}
                onChange={(): void => { }}
            />

            <TemplateTokensSelector disabled initialValue={jobInput.templateTokens} validateTenant={false} label="Template Tokens" onTemplateTokensChanged={(): void => { }} />
            <DesignConceptsSelector disabled initialValue={jobInput.designConceptIds} onDesignConceptsChanged={(): void => { }} />

            <Button
                className="download-product-options"
                variant="default"
                size="sm"
                style={{ marginLeft: '0px' }}
                onClick={downloadProductOptions}
            >
                Download product options CSV
            </Button>
        </div>
    </div>);
};

export default OriginalJobInput;
