/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import SearchBox from '../../components/searchEnsembles/searchBox';
import {
    ComboIdLookupResult, getTemplateTokenByComboId, getEnsemblesContainingTemplateToken, getEnsembleLinesByDesignConceptId,
}
    from '../../services/dtecService';
import './searchPage.scss';
import EnsembleSearchResult from '../../components/searchEnsembles/ensembleSearchResult';
import { GuidRegularExpression, NumericStringRegularExpression, TemplateTokenRegularExpression } from '../../TemplateTokenUtils';
import { isVistaprintOrCimpressUser } from '../../auth';
import BreadcrumbsConfigContext from '../../components/breadcrumbs/breadcrumbsConfigContextProvider';
import { splitInputByWhitespaceAndComma } from '../../utilityHelpers';
import fetchDucNamesDpsDisplayNames from '../../utils/ensemblesUtility';

const SearchPage = (): JSX.Element => {
    const [resultEnsembles, setResultEnsembles] = useState<TemplateCatalog.Ensemble[]>([]);
    const [resultEnsembleLines, setResultEnsembleLines] = useState<TemplateCatalog.EnsembleLine[]>([]);
    const [helpMessage, setHelpMessage] = useState<string>('');
    const { setBreadcrumbsConfig } = useContext(BreadcrumbsConfigContext);

    useEffect(() => {
        // Clear breadcrumbs
        setBreadcrumbsConfig({
            designConceptId: undefined,
            ensembleLineId: undefined,
            ensembleId: undefined,
        });
        // eslint wants breadcrumbsConfig as a dependency but function dependencies cause duplicate calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* eslint-disable no-param-reassign */
    /**
     * Search Emsembles by comboID or template token
     * @param searchInput: the comboID, template token, or design concept
     * @returns an array of Ensembles that match the inputted ID/Token
     */
    async function searchForEntity(searchInput: string):
    Promise<TemplateCatalog.Ensemble[] | undefined> {
        setHelpMessage('');

        let templateToken = '';
        let findDesignConcept = false;

        // Clean input. We are only expecting 1 ID here
        const cleanedId = splitInputByWhitespaceAndComma(searchInput);

        searchInput = cleanedId.length > 0 ? cleanedId[0] : '';

        if (searchInput.match(NumericStringRegularExpression)) {
            if (isVistaprintOrCimpressUser()) {
                const comboIdLookupResult: ComboIdLookupResult = await getTemplateTokenByComboId(searchInput);

                if (comboIdLookupResult.statusCode !== 200 && comboIdLookupResult.statusCode !== 404) {
                    setHelpMessage(`Error occurred when calling DTeC. Status code: ${comboIdLookupResult.statusCode}`);
                    return undefined;
                }
                templateToken = comboIdLookupResult.templateToken;
            }

            findDesignConcept = true;
        } else if (searchInput.match(TemplateTokenRegularExpression)) {
            templateToken = searchInput;
        } else if (searchInput.match(GuidRegularExpression)) {
            findDesignConcept = true;
        } else {
            setHelpMessage('Invalid template token or combo ID');
            return undefined;
        }

        let ensembles: TemplateCatalog.Ensemble[] | undefined = [];
        let designConceptResult: TemplateCatalog.EnsembleLine[] | undefined = [];

        if (templateToken) {
            ensembles = await getEnsemblesContainingTemplateToken(templateToken);

            // Fetch the DUC names and DPS Display names and set as additional data on the ensemble.
            if (ensembles) {
                await fetchDucNamesDpsDisplayNames(ensembles);
                setResultEnsembles(ensembles);
            }
        }
        if (findDesignConcept) {
            designConceptResult = await getEnsembleLinesByDesignConceptId(searchInput);

            if (designConceptResult) {
                setResultEnsembleLines(designConceptResult);
            }
        }
        if ((!ensembles || ensembles.length === 0) && (!designConceptResult || designConceptResult.length === 0)) {
            setHelpMessage('No results found');
        }

        return undefined;
    }

    // eslint-disable react/jsx-wrap-multilines
    return (
        <Grid container direction="column" justify="space-evenly" alignItems="stretch" spacing={1}>
            <Grid item>
                <SearchBox onSearchSubmit={searchForEntity} />
            </Grid>
            <Grid item>
                <b id="help-message">
                    {helpMessage}
                </b>
            </Grid>
            {resultEnsembles.length > 0 && resultEnsembleLines.length > 0
            // If we have both design concept and combo results, show disambiguation
            && (<>
                <Grid item>
                    <h2>Search Results</h2>
                </Grid>
                <Link to={{ pathname: `/designConceptDetails/${resultEnsembleLines[0].designConceptId}`, state: { ensembleLines: resultEnsembles } }}>
                    Design Concept details
                    {' '}
                    {'>'}
                </Link>
                <Link to={{ pathname: `/ensembleDetails/${resultEnsembles[0].ensembleId}`, state: { ensemble: resultEnsembles[0] } }}>
                    Ensemble details
                    {' '}
                    {'>'}
                </Link>
            </>)}
            {resultEnsembles.length > 0 && resultEnsembleLines.length === 0 // Otherwise if there are just ensemble results
            && (<>
                <Grid item>
                    <h2>Search Results</h2>
                </Grid>
                {resultEnsembles.length > 1
                    // If there is only one search result, go straight to its ensemble details page. Otherwise show search results
                    ? resultEnsembles.map((ensemble) => (<Grid item key={ensemble.ensembleId} data-testid={`search-result-${ensemble.ensembleId}`}><EnsembleSearchResult key={ensemble.ensembleId} ensemble={ensemble} /></Grid>))
                    : (<Redirect to={{ pathname: `/ensembleDetails/${resultEnsembles[0].ensembleId}`, state: { ensemble: resultEnsembles[0] } }} key={resultEnsembles[0].ensembleId} />)}
            </>)}
            {resultEnsembleLines.length > 0 && resultEnsembles.length === 0 // Otherwise if there are just design concept results
            && (<Redirect to={{ pathname: `/designConceptDetails/${resultEnsembleLines[0].designConceptId}`, state: { ensembleLines: resultEnsembleLines } }} key={resultEnsembleLines[0].designConceptId} />)}
        </Grid>
    );
};
/* eslint-enable no-param-reassign */

export default SearchPage;
