import { CopyButton } from '@cimpress/react-components';
import React from 'react';
import { JobItemSubItemPanel } from '../../services/gamService';

interface Props{
    panels: JobItemSubItemPanel[];
}
const PublishedTemplateTokenView = ({ panels }: Props): JSX.Element => (<div className="template-token-button-group">

    {panels.map((x) => <div key={`${x.generatedTemplateToken}${x.type}`}>
        <span className="additional-info-property">{`${x.type[0].toUpperCase() + x.type.substring(1)} Template Token: `}</span>
        <CopyButton className="template-token-button" variant="anchor" value={x.generatedTemplateToken || ''}>Copy</CopyButton>
    </div>)}
</div>);

export default PublishedTemplateTokenView;
