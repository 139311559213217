import React from 'react';
import { extractPanelSizes } from '../../pages/composeTemplatePage/helpers/documentPanelSizeExtractor';
import { JobItemSubItem } from '../../services/gamService';

import './comparisonPreview.scss';
import GeneratedEnsemblePanelPreview from './generatedEnsemblePanelPreview';

interface Props {
    publishedSubItem: JobItemSubItem;
    currentSubItem: JobItemSubItem;
}

const ComparisonPreview = ({ publishedSubItem, currentSubItem }: Props): JSX.Element => (<div className="row">
    <div className="column">
        <div>
            <h3 className="panel-title">Published Version</h3>
            <GeneratedEnsemblePanelPreview
                enforceSingleColumn
                item={publishedSubItem}
                panelSizes={extractPanelSizes(publishedSubItem.generatedDocument)}
            />
        </div>
    </div>
    <div className="column">
        <div>
            <h3 className="panel-title">Current Version</h3>
            <GeneratedEnsemblePanelPreview
                enforceSingleColumn
                item={currentSubItem}
                panelSizes={extractPanelSizes(currentSubItem.generatedDocument)}
            />
        </div>
    </div>
</div>);

export default ComparisonPreview;
