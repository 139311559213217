import React, { useState } from 'react';
import { Button, shapes } from '@cimpress/react-components';
import downloadHighResolutionPDFByTemplateToken from '../../services/pdfDownloadService';
import './downloadEnsemblePdfsButton.scss';

interface Props {
    ensemble: TemplateCatalog.Ensemble;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-boolean-value */

const DownloadEnsemblePdfsButton = ({ ensemble }: Props): JSX.Element => {
    const { Spinner } = shapes;

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [progressIndicator, setProgressIndicator] = useState<string>('');
    const [pdfButtons, setPdfButtons] = useState<unknown[]>([]);
    const [hideGeneratePdfsButton, setHideGeneratePdfsButton] = useState<boolean>(false);

    /**
     * Used to download high-res pdfs of an ensemble
     */
    async function fetchPdfsByEnsembleId(): Promise<void> {
        setIsProcessing(true);
        setProgressIndicator(`1 of ${ensemble.templates.length}`);
        setHideGeneratePdfsButton(true);

        let remainingTemplateNumber = ensemble.templates.length;
        const promises: Promise<string | undefined>[] = [];

        function downloadPdfCallBack(res: string | undefined): string | undefined {
            remainingTemplateNumber -= 1;

            if (typeof res !== 'string') {
                // if failed, restore generate pdfs button so the user can retry
                setHideGeneratePdfsButton(false);
                setPdfButtons([]);
            }

            if (remainingTemplateNumber === 0) {
                setIsProcessing(false);
            } else {
                setProgressIndicator(`${ensemble.templates.length - remainingTemplateNumber + 1} of ${ensemble.templates.length}`);
            }

            return res;
        }

        for (let i = 0; i < ensemble.templates.length; i += 1) {
            promises.push(downloadHighResolutionPDFByTemplateToken(ensemble.templates[i].templateToken).then(downloadPdfCallBack));
        }

        Promise.all(promises).then((results) => setPdfButtons(results.filter((res) => res !== undefined).map((res, index) => <Button href={res?.toString()} target="_blank" variant="default" key={res?.toString()} className="download-button">
            Open PDF {index + 1}
        </Button>)));
    }

    return (
        <div>
            {(isProcessing)
                ? <div>
                    <div id="spinner"> <Spinner size="medium" /> </div>
                    <div id="indicator"> Generating <span> {progressIndicator} </span> </div>
                </div>
                : !hideGeneratePdfsButton && <Button
                    className="download-button"
                    variant="default"
                    data-testid="download-button-test-id"
                    onClick={(): void => { fetchPdfsByEnsembleId(); }}
                > Generate PDFs </Button> }
            {pdfButtons.map((item) => item)}
        </div>
    );
};

/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable react/jsx-boolean-value */

export default DownloadEnsemblePdfsButton;
