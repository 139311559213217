export interface TargetProductSpecification {
    product: string;
    productOptions: string;
    orientation: string;
    substrateColor: string;
    substrateOpacity: string;
    designUseCaseId: string;
}

export interface ValidationResult {
    isSuccessful?: boolean;
    errorMessages?: string[];
}

export function areAllRequiredFieldsPresent(inputSpecifications: TargetProductSpecification): ValidationResult {
    const requiredFields = ['product', 'productOptions', 'orientation', 'substrateColor', 'substrateOpacity', 'designUseCaseId'];

    const validationResult: ValidationResult = { isSuccessful: true, errorMessages: [] };

    requiredFields.forEach((field) => {
        if (!(field in inputSpecifications)) {
            validationResult.isSuccessful = false;
            validationResult.errorMessages?.push(`Missing required field: ${field}. `);
            return false;
        }
        return true;
    });

    return validationResult;
}
