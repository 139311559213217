import React, { useState } from 'react';
import { TextField, Button } from '@cimpress/react-components';
import { Grid } from '@material-ui/core';
import './batchSearchBox.scss';

interface Props {
    onSearchSubmit: (searchInput: string, prewiewWidth?: number) => void;
}

const BatchSearchBox = ({ onSearchSubmit }: Props): JSX.Element => {
    const [searchInput, setSearchInput] = useState('');
    const [previewWidth, setPreviewWidth] = useState('500');

    const searchSubmitted = (e: React.FormEvent<HTMLFormElement>): void => {
        const previewWidthInt = parseInt(previewWidth, 10);

        e.preventDefault();

        onSearchSubmit(searchInput, previewWidthInt);
    };

    /* eslint-disable react/jsx-one-expression-per-line */
    /* eslint-disable react/jsx-boolean-value */

    return (
        <form id="batchSearchForm" className="input-form" onSubmit={searchSubmitted}>
            <Grid item container alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        type="textarea"
                        name="search"
                        label="Enter ComboIDs and TemplateTokens separated by newlines or commas"
                        value={searchInput || ''}
                        data-testid="search-input"
                        className="ids-input"
                        inputStyle={{
                            minHeight: '100px',
                            maxHeight: '250px',
                            minWidth: '550px',
                        }}
                        onChange={(e): void => setSearchInput(e.target.value)}
                    />
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={1}>
                    <TextField
                        name="width"
                        label="Preview width (px)"
                        value={previewWidth || ''}
                        data-testid="width-input"
                        className="width-input"
                        pattern="[0-9]*"
                        onChange={(e): void => setPreviewWidth(e.target.value)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button
                        className="search-button"
                        data-testid="search-submit"
                        variant="default"
                    > Show Previews </Button>
                </Grid>
            </Grid>
        </form>
    );
};

/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable react/jsx-boolean-value */

export default BatchSearchBox;
