/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    Alert, Button, Card,
} from '@cimpress/react-components';
import { useLocation } from 'react-router-dom';
import csvParserHelper from './helpers/csvParserHelper';
import Loading from '../../components/common/loading';
import { hasWritePermissions } from '../../auth';
import DesignConceptsSelector from './designConceptsSelector';
import TemplateTokensSelector from './templateTokensSelector';
import StepperLayout from '../../components/stepper/stepperLayout';
import StepDefinition from '../../components/stepper/stepDefinition';
import DownloadSampleCsvComponent from './downloadSampleCsvComponent';
import { areAllRequiredFieldsPresent, TargetProductSpecification } from './TargetProductSpecification';
import * as gamService from '../../services/gamService';
import DropzoneWrapper from '../../components/uploads/DropzoneWrapper';
import './composeTemplatePage.css';
import ReviewAndApprovePage from './reviewAndApprove';
import { isSuccessfulResponse } from '../../services/RequestUtils';
import ErrorHelper from './helpers/errorHelper';
import ApiErrorToast from './ApiErrorToast';
import JobErrorDetailExtractor from './helpers/jobErrorDetailExtractor';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import { TenantContext } from '../../components/composableTemplates/TenantContext';
import Strategist from './strategist';
import { CompositeState } from '../../components/composableTemplates/CompositeState';

interface Props {
    onActiveStepChanged: (value: boolean) => void;
}

const ComposeTemplatePage = ({ onActiveStepChanged }: Props): JSX.Element => {
    const { tenant } = useContext(TenantContext);
    const { state: locationState } = useLocation<CompositeState>();

    const productSpecsFileFromLocationState = locationState?.products
        ? [new File([], 'Product specifications from previous run', undefined)]
        : [];

    const [designConcepts, setDesignConcepts] = useState<string[]>(locationState?.designConceptIds ?? []);
    const [templateTokens, setTemplateTokens] = useState<string[]>(locationState?.templateTokens ?? []);
    const [errorText, setErrorText] = useState<string>('');
    const [hasAccess, setHasAccess] = useState(false);
    const [targetProductSpecifications, setTargetProductSpecifications] = useState<TargetProductSpecification[]>(
        locationState?.products ?? [],
    );
    const [activeStep, setActiveStep] = useState(0);
    const [activeJob, setActiveJob] = useState<GeneratedArtworkJobDetail>();
    const [responseError, setResponseError] = useState<string>();
    const [filesList, setFilesList] = useState<File[]>(productSpecsFileFromLocationState);
    const [strategist, setStrategist] = useState(locationState?.strategist ?? '');
    const [isValidEmail, setIsValidEmail] = useState(true);

    useEffect(() => {
        hasWritePermissions().then((writePermissions: boolean) => {
            setHasAccess(writePermissions);
        });
    }, []);

    const changeActiveStep = (newActiveStepValue: number) => {
        setActiveStep(newActiveStepValue);
        if (newActiveStepValue === 1 || newActiveStepValue === 2) {
            onActiveStepChanged(true);
        } else {
            onActiveStepChanged(false);
        }
    };

    const onFileDrop = async (acceptedFiles: File[]) => {
        setFilesList(acceptedFiles);
        if (acceptedFiles.length === 0) {
            return;
        }

        try {
            const specifications = await csvParserHelper(acceptedFiles[0]);

            // filter out all empty lines
            const sanitisedSpecifications = specifications.filter(spec => Object.values(spec).every(
                // in order for a line to be non-empty, every field of a product specification must be non-empty
                field => field !== '',
            ));
            const validationResult = areAllRequiredFieldsPresent(sanitisedSpecifications[0]);

            if (!validationResult.isSuccessful) {
                throw new Error(validationResult.errorMessages?.join('\r\n'));
            }
            setErrorText('');
            setTargetProductSpecifications(sanitisedSpecifications);
        } catch (err) {
            setErrorText(`Error parsing CSV: ${err}`);
        }
    };

    const onDesignConceptsChanged = (parsedValues: string[]) => setDesignConcepts(parsedValues);
    const onTemplateTokensChanged = (parsedValues: string[]) => setTemplateTokens(parsedValues);

    const validateEmail = (email: string): boolean => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(email).toLowerCase());
    };

    const onStrategistChange = (value: string): void => {
        setStrategist(value);
        setIsValidEmail(validateEmail(value));
    };

    const onSubmit = async () => {
        // Clear previous error messages
        setResponseError('');

        let job = await gamService.createNewJob(
            tenant,
            targetProductSpecifications,
            designConcepts,
            templateTokens,
            strategist,
        );

        changeActiveStep(1);
        if (isSuccessfulResponse(job)) {
            try {
                job = await gamService.pollForCompletion(tenant, job.data.id);

                if (isSuccessfulResponse(job) && job.data.status !== 'Failed') {
                    setActiveJob(job.data);
                    changeActiveStep(2);
                } else {
                    changeActiveStep(0);
                    setResponseError(ErrorHelper(job, JobErrorDetailExtractor));
                }
            } catch (error) {
                setResponseError((error as Error)?.stack ?? 'Unknown polling error');
            }
        } else {
            changeActiveStep(0);
            setResponseError(ErrorHelper(job, JobErrorDetailExtractor));
        }
    };

    const stepDefinition: StepDefinition[] = [
        {
            order: 0,
            name: 'First Step',
            title: 'Setup product configurations',
            canBeSetByUser: true,
        },
        {
            order: 1,
            name: 'Second Step',
            title: 'Generating previews',
        },
        {
            order: 2,
            name: 'Third Step',
            title: 'Review and approve',
        },
    ];

    return hasAccess ? (
        <>
            <Card>
                <h2 className="page-title">New Composed Templates job</h2>

                <StepperLayout
                    activeStep={activeStep}
                    steps={stepDefinition}
                    onStepChange={changeActiveStep}
                />

                {activeStep === 0 && (
                    <>
                        <Grid
                            container
                            direction="column"
                            justify="space-evenly"
                            alignItems="stretch"
                            spacing={1}
                        >
                            <Grid item>
                                <DownloadSampleCsvComponent />
                            </Grid>
                            <Grid item>
                                <DropzoneWrapper
                                    allowMultiple={false}
                                    disabled={false}
                                    listOfFiles={filesList}
                                    onDrop={onFileDrop}
                                />
                            </Grid>
                            <Grid item>
                                <DesignConceptsSelector initialValue={designConcepts} onDesignConceptsChanged={onDesignConceptsChanged} />
                                <TemplateTokensSelector initialValue={templateTokens} onTemplateTokensChanged={onTemplateTokensChanged} />
                            </Grid>
                            <Grid item>
                                <Strategist value={strategist} isValidEmail={isValidEmail} onStrategistChange={onStrategistChange} />
                            </Grid>
                            {errorText ? <Alert title="ERROR" message={errorText} /> : ''}
                        </Grid>
                        <Grid item>
                            <Button
                                className="generate-previews-button"
                                variant="primary"
                                disabled={!targetProductSpecifications
                                    || targetProductSpecifications.length === 0
                                    || !designConcepts
                                    || designConcepts.length === 0
                                    || !templateTokens
                                    || templateTokens.length === 0
                                    || strategist.length === 0
                                    || !isValidEmail
                                    || errorText.length !== 0}
                                onClick={onSubmit}
                            >
                                Preview
                            </Button>
                        </Grid>
                    </>
                )}
                {activeStep === 1 && (
                    <div className="previews-generation-wrapper">
                        <Loading />
                        <h2>Compose processing...</h2>
                    </div>
                )}
                {activeStep === 2 && activeJob && (
                    <ReviewAndApprovePage job={activeJob} />
                )}
            </Card>
            <ApiErrorToast message={responseError} />
        </>
    ) : (
        <span>Access Denied</span>
    );
};

export default ComposeTemplatePage;
