/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useState, useEffect } from 'react';
import {
    Link, Router, Route, Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { CssLoader } from '@cimpress/react-components';
import Header from '@cimpress-technology/react-platform-header';
import { warning } from '@cimpress/react-components/lib/colors';
import './app.css';
import auth from './auth';
import { hasWritePermissions } from './auth';
import SearchPage from './pages/searchPage/searchPage';
import BatchSearchPage from './pages/batchSearchPage/batchSearchPage';
import HomePage from './pages/homePage/homePage';
import Loading from './components/common/loading';
import EnsembleDetailsPage from './pages/ensembleDetailsPage/ensembleDetailsPage';
import EnsembleLineDetailsPage from './pages/ensembleLineDetailsPage/ensembleLineDetailsPage';
import EnsembleSearchResultPage from './pages/ensembleSearchResultPage/ensembleSearchResultPage';
import Preferences from './components/preferences/preferences';
import { PreferencesConfiguration } from './components/preferences/preferencesConfig';
import { PreferencesProvider } from './components/preferences/preferencesContext';
import DesignConceptDetailsPage from './pages/designConceptDetailsPage/designConceptDetailsPage';
import { BreadcrumbsConfig } from './components/breadcrumbs/breadcrumbsConfig';
import AppBreadcrumbs from './components/breadcrumbs/appBreadcrumbs';
import { BreadcrumbsConfigContextProvider } from './components/breadcrumbs/breadcrumbsConfigContextProvider';
import {
    Tenant, availableTenantList, getDefaultTenant, TenantContext,
} from './components/composableTemplates/TenantContext';
import ComposePagesWrapper from './components/composableTemplates/composePagesWrapper';
import PageComponent from './components/composableTemplates/composeComponentEnum';
import ComposeJobDetailPage from './pages/composeTemplatePage/composeJobDetailPage';
import ResizeTemplateStepsPage from './pages/resizeTemplatePage/resizeTemplateStepsPage';
import ReplaceLinePage from './pages/replaceLinePage/replaceLinePage';

const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
const history = createBrowserHistory({ basename });

const App = (): JSX.Element => {
    const [authenticating, setAuthenticating] = useState(false);
    const [profileHasWritePermissions, setHasWritePermissions] = useState(false);
    const [authenticationError, setAuthenticationError] = useState({} as Error);
    const [preferencesConfig, setPreferencesConfig] = useState({} as PreferencesConfiguration);
    const [breadcrumbsConfig, setBreadcrumbsConfig] = useState({} as BreadcrumbsConfig);
    const [availableTenants] = useState<Tenant[]>(availableTenantList);
    const [tenant, setTenant] = useState<Tenant>(getDefaultTenant(history.location.pathname));

    // Only change the context if the input has changed, otherwise we get into an infinite loop because the subscriber
    // of the context changes actually changes the context itself
    const setBreadcrumbs = (config: BreadcrumbsConfig): void => {
        if (config.designConceptId !== breadcrumbsConfig.designConceptId
            || config.ensembleLineId !== breadcrumbsConfig.ensembleLineId
            || config.ensembleId !== breadcrumbsConfig.ensembleId) {
            setBreadcrumbsConfig(config);
        }
    };

    const ensureLoggedIn = (): void => {
        hasWritePermissions().then((writePermissions: boolean) => {
            setHasWritePermissions(writePermissions);
        });
        if (!auth.isLoggedIn()) {
            setAuthenticating(true);
            auth.ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
                .then((isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        setAuthenticating(false);
                        auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));
                    }
                })
                .catch((error: Error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    setAuthenticationError(error);
                });
        }
    };

    useEffect(ensureLoggedIn);

    return (
        <div>
            {authenticating ? (
                <Loading />
            // eslint-disable-next-line no-nested-ternary
            ) : auth.isLoggedIn() ? (
                <Router history={history}>
                    <CssLoader>
                        <BreadcrumbsConfigContextProvider value={{ breadcrumbsConfig, setBreadcrumbsConfig: setBreadcrumbs }}>
                            <PreferencesProvider value={preferencesConfig}>
                                <TenantContext.Provider value={{ tenant, availableTenants }}>
                                    <Header
                                        accessToken={auth.getAccessToken()}
                                        appTitle={<Link to="/"><p>Design Template Catalog</p></Link>}
                                        appLinks={[
                                            {
                                                id: 'search-page-id',
                                                content: <Link to="/searchPage">Search</Link>,
                                            },
                                            {
                                                id: 'batch-preview-id',
                                                content: <Link to="/batchSearch">Batch preview</Link>,
                                            },
                                            {
                                                id: 'preferences-id',
                                                content: <Preferences
                                                    onPreferencesChange={(newConfig: PreferencesConfiguration): void => {
                                                        setPreferencesConfig(newConfig);
                                                    }}
                                                />,
                                            },
                                            {
                                                id: 'generate-resize-page-id',
                                                content: <Link
                                                    to="/resize"
                                                    style={{ display: profileHasWritePermissions ? 'inline-block' : 'none', color: warning.darkest }}
                                                    className="warning"
                                                >
                                                    Resize
                                                </Link>,
                                            },
                                            {
                                                id: 'compose-template-id',
                                                content: <Link
                                                    to="/compose"
                                                    style={{ display: profileHasWritePermissions ? 'inline-block' : 'none', color: warning.darkest }}
                                                    className="warning"
                                                >
                                                    Compose
                                                </Link>,
                                            },
                                            {
                                                id: 'compose-job-history-page-id',
                                                content: <Link
                                                    to="/composeJobHistory"
                                                    style={{ display: profileHasWritePermissions ? 'inline-block' : 'none', color: warning.darkest }}
                                                    className="warning"
                                                >
                                                    Compose History
                                                </Link>,
                                            },
                                        ]}
                                        profile={auth.getProfile()}
                                        isLoggedIn={auth.isLoggedIn()}
                                        onLogInClicked={(): Promise<boolean> => auth.login()}
                                        onLogOutClicked={(): void => auth.logout('/')}
                                    />
                                    <AppBreadcrumbs />
                                    <div style={{ minHeight: 'calc(100vh - 225px)' }} className="App-container">
                                        <Switch>
                                            <Route exact path="/(index.html)?" component={HomePage} />
                                            <Route exact path="/searchPage" component={SearchPage} />
                                            <Route exact path="/batchSearch" component={BatchSearchPage} />
                                            <Route exact path="/resize" render={() => <ResizeTemplateStepsPage />} />
                                            <Route exact path="/compose" render={(props) => <ComposePagesWrapper {...props} setTenant={setTenant} component={PageComponent.compose} />} />
                                            <Route exact path="/composeJobHistory" render={(props) => <ComposePagesWrapper {...props} setTenant={setTenant} component={PageComponent.composeJobHistory} />} />
                                            <Route exact path="/composeJobDetails/:contentAccountId/:contentAreaId/:jobId" component={ComposeJobDetailPage} />
                                            <Route path="/ensembleDetails/:ensembleId" component={EnsembleDetailsPage} />
                                            <Route path="/ensembleLineDetails/:ensembleLineId" component={EnsembleLineDetailsPage} />
                                            <Route exact path="/ensembleSearchResult" component={EnsembleSearchResultPage} />
                                            <Route path="/designConceptDetails/:designConceptId" component={DesignConceptDetailsPage} />
                                            <Route path="/replace" render={() => <ReplaceLinePage />} />
                                        </Switch>
                                    </div>
                                </TenantContext.Provider>
                            </PreferencesProvider>
                        </BreadcrumbsConfigContextProvider>
                    </CssLoader>
                </Router>
            ) : authenticationError ? (
                <div>Unexpected error encountered. {authenticationError.message}</div>
            ) : null}
        </div>
    );
};

export default App;
