/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as QueryString from 'query-string';
import { FlexBox } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import BatchSearchBox from '../../components/searchEnsembles/batchSearchBox';
import {
    ComboIdLookupResult, getTemplateTokenByComboId, getEnsemblesContainingTemplateToken, getEnsembleLinesByIds,
} from '../../services/dtecService';
import { getContentAreaIdToContentAuthoringAreaIdMap } from '../../services/tenantRegistryService';
import './batchSearchPage.scss';
import ExportBatchSearchResults from '../../components/batchTemplates/exportBatchResults';
import BatchPreviewPanelCard from '../../components/batchTemplates/batchPreviewPanelCard';
import { NumericStringRegularExpression, TemplateTokenRegularExpression } from '../../TemplateTokenUtils';
import BreadcrumbsConfigContext from '../../components/breadcrumbs/breadcrumbsConfigContextProvider';
import { getAllDucs } from '../../services/ducService';
import { batchGetDps } from '../../services/dpsService';
import { getDpsDisplayText, getDpsDisplayColor, splitInputByWhitespaceAndComma } from '../../utilityHelpers';

interface RouterLocationPartial {
    search: string;
}
interface Props {
    location: RouterLocationPartial;
}

const BatchSearchPage = ({ location }: Props): JSX.Element => {
    const [resultPanels, setResultPanels] = useState<PanelWithEnsembleInfo.PanelWithEnsembleInfo[] | undefined>(undefined);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [invalidOrNotFoundIds, setInvalidOrNotFoundIds] = useState<string[]>([]);
    const tmpInvalidOrNotFoundIds: string[] = [];
    const history = useHistory();
    let width = 500;
    let templateEnsemblesMap: Map<string, PanelWithEnsembleInfo.PanelWithEnsembleInfo>;
    const { setBreadcrumbsConfig } = useContext(BreadcrumbsConfigContext);

    // Parse the query parameters
    const parsed = QueryString.parse(location?.search);

    if (resultPanels !== undefined && resultPanels.length > 0) {
        if (location.search !== undefined) {
            // check if user specified the width
            if (typeof parsed.width === 'string') {
                width = parseInt(parsed.width, 10);
            }
        }
    }

    /**
     * Input a list of ensembleLineIds, find and return all
     * EnsembleIds that belong to these ensembleLines and marked as "prominent color variant"
     */
    async function getProminentEnsembleIdsFromEnsembleLineIds(ensembleLineIds: string[]):
    Promise<string[]> {
        const prominentEnsembleIds: string[] = [];
        const ensembleLinesResult = await getEnsembleLinesByIds(ensembleLineIds);

        if (!ensembleLinesResult || ensembleLinesResult.length === 0) {
            return prominentEnsembleIds;
        }

        ensembleLinesResult.forEach((el) => {
            el.ensembles.forEach((e) => {
                if (e.designProperties.isProminentColorVariant) {
                    prominentEnsembleIds.push(e.ensembleId);
                }
            });
        });

        return prominentEnsembleIds;
    }

    /**
     * validate ids and tramsform comboIds to corresponding templateTokens
     * @param ids: a list of comboIds and templateTokens
     * @returns a list of valid templateTokens
     */
    async function validateAndTransformIdsToTemplateTokens(ids: string[]):
    Promise<string[]> {
        const templateTokens: string[] = [];
        const promises: Promise<ComboIdLookupResult>[] = [];

        for (let i = 0; i < ids.length; i += 1) {
            if (ids[i].match(NumericStringRegularExpression)) {
                // case1: search by comboID, transform the comboID to template token
                promises.push(getTemplateTokenByComboId(ids[i]));
            } else if (ids[i].match(TemplateTokenRegularExpression)) {
                // case2: search by template token
                templateTokens.push(ids[i]);
            } else {
                // case3: invalid input
                tmpInvalidOrNotFoundIds.push(ids[i]);
            }
        }

        await Promise.all(promises).then((results) => results.map((res, index) => {
            if (res?.statusCode === 200) {
                templateTokens.push(res.templateToken);
            } else {
                tmpInvalidOrNotFoundIds.push(res?.comboId);
            }
            return index;
        }));

        return templateTokens;
    }

    /**
     * If input is a monolith templateToken, extract and return the comboID. Otherwise return undefined.
     * @param templateToken
     */
    function checkAndGetComboIdFromTemplateToken(templateToken: string): string | undefined {
        if (!templateToken || !templateToken.startsWith('c')) {
            return undefined;
        }

        return templateToken.split('..')[0].substring(1);
    }

    async function setIsProminentColorVariantForPanels(panels: PanelWithEnsembleInfo.PanelWithEnsembleInfo[]): Promise<void> {
        const ensembleLineIdSet: Set<string> = new Set();

        panels.forEach((element) => {
            for (let i = 0; i < element.ensembles.length; i += 1) {
                ensembleLineIdSet.add(element.ensembles[i].ensembleLineId);
            }
        });

        const prominentEnsembleIds = await getProminentEnsembleIdsFromEnsembleLineIds([...ensembleLineIdSet]);

        /* eslint-disable no-param-reassign */
        panels.forEach((element) => {
            for (let i = 0; i < element.ensembleIds.length; i += 1) {
                if (prominentEnsembleIds.includes(element.ensembleIds[i])) {
                    element.isProminentColorVariant = true;
                    break;
                }
            }
        });
        /* eslint-enable no-param-reassign */
    }

    /**
     * Remove version info from inputted templateToken
    */
    function removeVersionInfoFromTemplateToken(templateToken: string): string {
        const versionRegex = /:v[0-9]+\.\./;

        return templateToken.replace(versionRegex, '');
    }

    function batchSearchCallBack(ensembles: TemplateCatalog.Ensemble[], templateTokens: string[],
        contentAreaToAuthoringAreaMap: Map<string, string>, tmpResultPanels: PanelWithEnsembleInfo.PanelWithEnsembleInfo[]): void {
        const templateTokensWithoutVersion: string[] = templateTokens
            .map((templateToken) => removeVersionInfoFromTemplateToken(templateToken));

        // A template can be mapped to multiple ensembles. Example: comboID 5971742
        ensembles.map((ensemble) => {
            /* eslint-disable no-continue */
            for (let i = 0; i < ensemble.templates.length; i += 1) {
                const templateTokenWithoutVersion = removeVersionInfoFromTemplateToken(ensemble.templates[i].templateToken);

                if (!templateTokensWithoutVersion.includes(templateTokenWithoutVersion)) {
                    continue;
                }

                // used to dedup
                if (!templateEnsemblesMap.has(ensemble.templates[i].templateToken)) {
                    // create a new PanelWithEnsembleInfo
                    const panel: PanelWithEnsembleInfo.PanelWithEnsembleInfo = {
                        template: ensemble.templates[i],
                        isProminentColorVariant: ensemble.designProperties.isProminentColorVariant,
                        comboId: checkAndGetComboIdFromTemplateToken(ensemble.templates[i].templateToken),
                        tenant: ensemble.tenant,
                        contentAuthoringAreaId: contentAreaToAuthoringAreaMap.has(ensemble.tenant?.contentAreaId)
                            ? contentAreaToAuthoringAreaMap.get(ensemble.tenant?.contentAreaId) as string : '',
                        ensembleIds: [ensemble.ensembleId],
                        ensembles: [ensemble],
                    };

                    templateEnsemblesMap.set(panel.template.templateToken, panel);

                    tmpResultPanels.push(panel);
                } else if (!templateEnsemblesMap.get(ensemble.templates[i].templateToken)?.ensembleIds.includes(ensemble.ensembleId)) {
                    // add ensembleId to existing PanelWithEnsembleInfo
                    templateEnsemblesMap.get(ensemble.templates[i].templateToken)?.ensembleIds.push(ensemble.ensembleId);
                    templateEnsemblesMap.get(ensemble.templates[i].templateToken)?.ensembles.push(ensemble);
                }
            }

            return true;
        });
    }

    /**
     * Search ensembles by a list of comboIds and TemplateTokens, store results in state "resultPanels"
     * @param input is a list of user-input comboIds and TemplateTokens
     */
    async function batchSearchByComboIdsAndTemplateTokens(ids: string[]):
    Promise<void> {
        const templateTokens = await validateAndTransformIdsToTemplateTokens(ids);

        if (!templateTokens || templateTokens.length === 0) {
            setResultPanels(undefined);
            setInvalidOrNotFoundIds(tmpInvalidOrNotFoundIds);
            setShowSpinner(false);
            return undefined;
        }

        const promises: Promise<TemplateCatalog.Ensemble[] | undefined>[] = [];

        for (let i = 0; i < templateTokens.length; i += 1) {
            promises.push(getEnsemblesContainingTemplateToken(templateTokens[i]));
        }

        const tmpResultPanels: PanelWithEnsembleInfo.PanelWithEnsembleInfo[] = [];

        templateEnsemblesMap = new Map<string, PanelWithEnsembleInfo.PanelWithEnsembleInfo>();

        const contentAreaToAuthoringAreaMap = await getContentAreaIdToContentAuthoringAreaIdMap();

        await Promise.all(promises).then((results) => results.map((res) => {
            if (res === undefined || res.length < 1) {
                return undefined;
            }

            batchSearchCallBack(res, templateTokens, contentAreaToAuthoringAreaMap, tmpResultPanels);

            return res;
        }));

        // Because we await all the promises of api calls there is no way to tie a failed call back to a template token
        // By looking at the array of TTs as input and what results we were left with, we can find what TTs didn't get a result
        templateTokens.forEach(((token) => {
            if (!tmpResultPanels.some(((panel) => panel.ensembles.some(((e) => e.templates.some(((t) => t.templateToken === token))))))) {
                tmpInvalidOrNotFoundIds.push(token);
            }
        }));

        await setIsProminentColorVariantForPanels(tmpResultPanels);
        const ducs = await getAllDucs();

        const dpsIds = tmpResultPanels.map((p) => p.template.designPhysicalSpecId);
        const uniqueDpsIds = [...new Set(dpsIds)];
        const dpsData = await batchGetDps(uniqueDpsIds);

        if (tmpResultPanels.length > 0) {
            tmpResultPanels.forEach((p) => {
                const dpsForPanel = dpsData.find((d) => d.id === p.template.designPhysicalSpecId);

                /* eslint-disable no-param-reassign */
                p.template.dpsDisplayName = getDpsDisplayText(dpsForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                p.template.dpsDisplayColor = getDpsDisplayColor(dpsForPanel as DesignPhysicalSpec.DesignPhysicalSpecDto);
                p.template.dpsSubstrateColor = dpsForPanel?.spec.substrate.color ?? '';
                /* eslint-enable no-param-reassign */
                p.ensembles.forEach((e) => {
                    e.designUseCaseName = ducs?.find((d) => d.id === e.designUseCaseId)?.name;
                });
            });

            setResultPanels(tmpResultPanels);
            setInvalidOrNotFoundIds(tmpInvalidOrNotFoundIds);
        }
        setInvalidOrNotFoundIds(tmpInvalidOrNotFoundIds);
        setShowSpinner(false);
        return undefined;
    }

    /**
     * onSearchSubmit function for "Batch preview" button
     */
    const onBatchPreviewByIds = async (input: string, userInputWidth = 500): Promise<void> => {
        const ids = splitInputByWhitespaceAndComma(input);
        const idsStringWithCommas = ids.join(',');

        history.push(`/batchSearch?ids=${idsStringWithCommas}&width=${userInputWidth}`);
        if (ids && ids.length > 0) {
            setShowSpinner(true);
            batchSearchByComboIdsAndTemplateTokens(ids);
        } else {
            // User is most likely trying to clear out the results
            if (resultPanels && resultPanels.length > 0) {
                setResultPanels(undefined);
            }
            if (invalidOrNotFoundIds && invalidOrNotFoundIds.length > 0) {
                setInvalidOrNotFoundIds([]);
            }
        }
    };

    useEffect(() => {
        // Clear breadcrumbs
        setBreadcrumbsConfig({
            designConceptId: undefined,
            ensembleLineId: undefined,
            ensembleId: undefined,
        });

        if (typeof parsed.ids === 'string' && parsed.ids) {
            setShowSpinner(true);
            batchSearchByComboIdsAndTemplateTokens(splitInputByWhitespaceAndComma(parsed.ids));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container direction="column" justify="space-evenly" alignItems="stretch" spacing={1}>
            <div id="result-cards">
                <Grid item>
                    <BatchSearchBox onSearchSubmit={onBatchPreviewByIds} />
                </Grid>
                {invalidOrNotFoundIds && invalidOrNotFoundIds.length > 0
                && (<>
                    {`Invalid IDs and IDs not found: ${[...new Set(invalidOrNotFoundIds)]}`}
                </>)}
                { showSpinner
                    ? (<FlexBox>
                        <Spinner size="medium" />
                        <div>Loading...</div>
                    </FlexBox>)
                    : (resultPanels && resultPanels.length > 0)
                    && (<>
                        <Grid item container alignItems="center">
                            <Grid item xs={4}>
                                <h2>Search Results</h2>
                            </Grid>

                            <Grid item xs={4}>
                                <ExportBatchSearchResults templates={resultPanels} previewWidth={width} />
                            </Grid>
                        </Grid>
                        {resultPanels.map((panel) => (<Grid item className="template-card" key={panel.template.templateToken} data-testid={`search-result-${panel.template.templateToken}`}><BatchPreviewPanelCard panelWithEnsembleInfo={panel} previewWidth={width} key={panel.template.templateId} /></Grid>))}
                    </>)}
            </div>
        </Grid>
    );
};

export default BatchSearchPage;
