import _ from 'lodash';
import React, { useState, useContext } from 'react';
import {
    Alert, Button, Card, Checkbox,
} from '@cimpress/react-components';
import { useHistory } from 'react-router-dom';
import * as colors from '@cimpress/react-components/lib/colors';
import '../../components/templateGeneration/reviewAndApprove.scss';
import { GeneratedArtworkJobDetail } from '../../services/gamService';
import * as gamService from '../../services/gamService';
import PreviewGroup from '../../components/composableTemplates/previewGroup';
import ComposeTemplateJobLabel from '../../components/templateGeneration/templateGenerationJobLabel';
import { isSuccessfulResponse } from '../../services/RequestUtils';
import ApiErrorToast from './ApiErrorToast';
import ErrorHelper from './helpers/errorHelper';
import JobErrorDetailExtractor from './helpers/jobErrorDetailExtractor';
import { TenantContext } from '../../components/composableTemplates/TenantContext';
import RejectJobConfirmationModal from '../../components/templateGeneration/rejectJobConfirmationModal';

interface Props {
    job: GeneratedArtworkJobDetail;
}

const ReviewAndApprovePage = ({ job }: Props): JSX.Element => {
    const { tenant } = useContext(TenantContext);
    const history = useHistory();

    const [selectedJobItems, setSelectedJobItems] = useState<string[]>(job.items.reduce((ids: string[], item) => {
        // TODO-PULSAR
        // if (!item.overwrittenInFutureJob) {
        ids.push(item.id);
        // }
        return ids;
    }, []));

    const [isSaving, setIsSaving] = useState(false);
    const [responseError, setResponseError] = useState<string>('');
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

    const onJobItemSelected = (id: string[], isSelected: boolean): void => {
        if (isSelected) {
            setSelectedJobItems([...new Set([...selectedJobItems, ...id])]);
        } else {
            setSelectedJobItems([...new Set(selectedJobItems.filter((jobItemId) => !id.includes(jobItemId)))]);
        }
    };

    const [wasPageReviewed, setWasPageReviewed] = useState(false);
    const onPageReviewChanged = (): void => {
        setWasPageReviewed(!wasPageReviewed);
    };

    const isContentSolutionTestTenant = tenant.contentAccountId === 'g2Ez5VaoZWoqU22XqPjTLU' && tenant.contentAreaId === 'V0DJMs5nByPR6UD';

    const onPublishClick = async (): Promise<void> => {
        // Clear previous error messages
        setResponseError('');
        setIsSaving(true);

        const saveTemplates = await gamService.saveTemplates(selectedJobItems);

        try {
            setResponseError(ErrorHelper(saveTemplates));
            if (isSuccessfulResponse(saveTemplates)) {
                const finishJob = await gamService.finishJob(tenant, job.id, 'Published');

                setResponseError(ErrorHelper(finishJob, JobErrorDetailExtractor));
                if (isSuccessfulResponse(finishJob)) {
                    history.push({ pathname: `/composeJobDetails/${tenant.contentAccountId}/${tenant.contentAreaId}/${job.id}?updated` });
                }
            }
        } finally {
            setIsSaving(false);
        }
    };

    const onRejectClick = (): void => {
        setIsConfirmationModalVisible(true);
    };

    const onRejectConfirmed = async (): Promise<void> => {
        // Clear previous error messages
        setResponseError('');
        setIsSaving(true);

        try {
            const closedJob = await gamService.finishJob(tenant, job.id, 'Closed');

            setResponseError(ErrorHelper(closedJob, JobErrorDetailExtractor));
            if (isSuccessfulResponse(closedJob)) {
                history.push({ pathname: `/composeJobDetails/${tenant.contentAccountId}/${tenant.contentAreaId}/${job.id}?updated` });
            }
        } finally {
            setIsSaving(false);
        }
    };

    const grouped = _.groupBy(job.items, 'jobItemInput.designConcept');

    return (
        <div>
            <Card>
                <div className="job-details-header">
                    <h3>
                        {`Job ID: ${job.id}`}
                    </h3>
                    <ComposeTemplateJobLabel status={job.status} />
                </div>

                <div className="job-details-subheader">
                    Review each preview of your primary component configurations. These are presented by Design Concept ID.
                    <br />
                    If there are issues with your component composition leave unchecked.
                    <br />
                    Any unchecked will not be enabled and the ensemble line will not be generated.
                </div>
            </Card>

            <div>
                {/* eslint-disable-next-line */}
                {Object.entries(grouped).map(([dc, jobItems]) => (<PreviewGroup dc={dc} jobItems={jobItems} selectedJobItems={selectedJobItems}
                    onJobItemSelected={onJobItemSelected}
                />))}
            </div>

            <div>
                <Checkbox
                    checked={wasPageReviewed}
                    label="By checking this box I confirm that all previews have been reviewed and that any checked meet Cimpress standards."
                    onChange={onPageReviewChanged}
                />
            </div>
            {isContentSolutionTestTenant && <Alert
                status="warning"
                message={<>
                    {'You are in '}
                    <b>{tenant.name}</b>
                </>}
                dismissible={false}
            />}
            <div className="approve-buttons-wrapper">
                <Button
                    disabled={selectedJobItems.length === 0 || wasPageReviewed !== true || isSaving}
                    className="publish-button"
                    variant="primary"
                    size="lg"
                    onClick={onPublishClick}
                >
                    {isSaving ? 'Saving...' : 'Publish'}
                </Button>
                <small style={{ color: colors.shale, flex: 1 }}>{`${selectedJobItems.length} of ${job.items.length} selected`}</small>
                <Button
                    disabled={isSaving}
                    variant="anchor"
                    onClick={onRejectClick}
                >
                    Reject All
                </Button>
            </div>
            <ApiErrorToast message={responseError} />
            <RejectJobConfirmationModal
                isModalVisible={isConfirmationModalVisible}
                isSaving={isSaving}
                onSetModalVisible={setIsConfirmationModalVisible}
                onConfirmed={onRejectConfirmed}
            />
        </div>
    );
};

export default ReviewAndApprovePage;
