import { Button } from '@cimpress/react-components';
import React, { useState } from 'react';
import { PreferencesConfiguration } from './preferencesConfig';
import PreferencesModal from './preferencesModal';

interface Props {
    onPreferencesChange: (newConfig: PreferencesConfiguration) => void;
}

const Preferences = ({ onPreferencesChange }: Props): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Button variant="link" onClick={(): void => setIsModalOpen(true)}>
                Preferences
            </Button>

            <PreferencesModal
                isOpen={isModalOpen}
                onClose={(): void => setIsModalOpen(false)}
                onPreferencesChange={onPreferencesChange}
            />
        </>
    );
};

export default Preferences;
