import React from 'react';
import '../templateGeneration/generatedEnsembleMetadata.scss';
import { TargetProductSpecification } from '../templateGeneration/TargetProductSpecification';

interface Props {
    target: TargetProductSpecification;
    ensembleCount: number;
    multiColumn: boolean;
    dpsTrimDisplayText: string;
    productName: string;
}

const PreviewGroupMetadata = ({
    target, ensembleCount, multiColumn, dpsTrimDisplayText, productName,
}: Props): JSX.Element => {
    const firstHalfMetaData = (<>
        <li>
            <span className="additional-info-property">Trim:</span>
            {dpsTrimDisplayText}
        </li>
        <li>
            <span className="additional-info-property">PRD:</span>
            {target.product}
        </li>
        <li>
            <span className="additional-info-property">Product Name:</span>
            {productName}
        </li>
    </>);

    const secondHalfMetaData = (<>
        <li>
            <span className="additional-info-property">Substrate RGB:</span>
            {target.substrateColor}
            <span style={{ backgroundColor: target.substrateColor, border: '1px solid gray', maxHeight: '20px' }}>&emsp;</span>
        </li>
        <li>
            <span className="additional-info-property">Orientation:</span>
            {target.orientation}
        </li>
        <li>
            <span className="additional-info-property">{`Contains: ${ensembleCount} ensembles`}</span>
        </li>
    </>);

    const metaData = (<>
        {multiColumn
            ? (<>
                <div className="ensemble-metadata smallFont">
                    <ul>
                        {firstHalfMetaData}
                    </ul>
                    <ul>
                        {secondHalfMetaData}
                    </ul>
                </div>
            </>)
            : <div className="ensemble-metadata">
                <ul>
                    {firstHalfMetaData}
                    {secondHalfMetaData}
                </ul>
            </div>}
    </>);

    return (<div className="ensemble-metadata-parent">
        {metaData}
    </div>);
};

export default PreviewGroupMetadata;
