import { Card, Checkbox, FlexBox } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplatePreview from '../browseEnsembles/templatePreview';
import './batchPreviewPanelCard.scss';
import '../browseEnsembles/panelDetailsCard.scss';
import { isVistaprintOrCimpressUser } from '../../auth';
import { getEnvConfig } from '../../envConfig';
import { catalogUpdateEnsemble, DtecBackstageResult } from '../../services/dtecBackstageService';
import DesignPhysicalSpecDetailsSection from '../browseEnsembles/designPhysicalSpecDetailsSection';

interface Props {
    panelWithEnsembleInfo: PanelWithEnsembleInfo.PanelWithEnsembleInfo;
    previewWidth: number;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
const BatchPreviewPanelCard = ({
    panelWithEnsembleInfo, previewWidth,
}: Props): JSX.Element => {
    const [isCatalogDeleted, setCatalogDeleted] = useState<boolean>(panelWithEnsembleInfo.ensembles[0].isCatalogDeleted);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const history = useHistory();

    const { camUrl } = getEnvConfig();

    const EnsembleDetailsOnClick = (): void => {
        if (panelWithEnsembleInfo.ensembleIds?.length === 1) {
            history.push({
                pathname: `/ensembleDetails/${panelWithEnsembleInfo.ensembleIds[0]}`,
                state: { ensemble: panelWithEnsembleInfo.ensembles[0] },
            });
        } else if (panelWithEnsembleInfo.ensembleIds?.length > 1) {
            history.push({
                pathname: '/ensembleSearchResult',
                state: { ensembles: panelWithEnsembleInfo.ensembles },
            });
        }
    };

    const determineSource = (token: string): string => {
        switch (token.substring(0, 1)) {
            case 'c': // eslint-disable-line indent
                return 'comboBackedDesign'; // eslint-disable-line indent
            case 's': // eslint-disable-line indent
                return 'staticDesign'; // eslint-disable-line indent
            default: // eslint-disable-line indent
                return ''; // eslint-disable-line indent
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCatalogDeleteEnsemble = (e: any, payload: TemplateCatalog.Ensemble[]): void => {
        setShowSpinner(true);

        const promises: Promise<DtecBackstageResult>[] = [];
        const newIsCatalogDeleted = !isCatalogDeleted;

        (payload).forEach((ensemble) => {
            promises.push(catalogUpdateEnsemble(determineSource(ensemble.templates[0].templateToken),
                ensemble.ensembleId,
                isCatalogDeleted));
        });

        Promise.all(promises).then(() => setCatalogDeleted(newIsCatalogDeleted))
            .finally(() => setShowSpinner(false));
    };

    const getDesignUseCaseName = (): string => {
        // Build out the Design Use Case label and values
        // Each Template could have multiple ensembles and DUCs so we append them
        if (!(panelWithEnsembleInfo.ensembles?.length > 0)) {
            return '';
        }

        const ducs = new Array<string>();

        // For all ensembles that have a DUC Name, build out the value
        panelWithEnsembleInfo.ensembles.filter((e) => e.designUseCaseName).forEach((e) => {
            const uniqueDucString = ` ${e.designUseCaseName} (ID: ${e.designUseCaseId}),`;

            // Ignore Duplicate entries
            if (!ducs.find((s) => s === uniqueDucString)) {
                ducs.push(uniqueDucString);
            }
        });

        if (!(ducs.length > 0)) {
            return '';
        }

        // When values exist, create label and append all values
        let ducString = 'Design Use Case:';

        ducs.forEach((ds) => {
            ducString += ds;
        });

        // Remove trailing coma
        return ducString.substring(0, ducString.length - 1);
    };

    return (
        <Card className="batch-preview-panel-card-style">
            <div className="panel-details-card-preview">
                <TemplatePreview
                    templateToken={panelWithEnsembleInfo.template.templateToken}
                    designPhysicalSpecId={panelWithEnsembleInfo.template.designPhysicalSpecId}
                    backgroundColor={panelWithEnsembleInfo.template.dpsSubstrateColor}
                    previewWidth={previewWidth}
                />
            </div>
            <div className="panel-detail">
                {
                    panelWithEnsembleInfo.isProminentColorVariant
                    && (<div className="panel-details-item">
                        <div className="panel-details-card-text">
                            <p className="ensemble-prominent">
                                Prominent Color Variant
                            </p>
                        </div>
                    </div>)
                }
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            Panel Type: {panelWithEnsembleInfo.template.ensemblePanelType}
                        </p>
                    </div>
                </div>
                {
                    (isVistaprintOrCimpressUser() && panelWithEnsembleInfo.comboId)
                    && <div className="panel-details-item">
                        <div className="panel-details-card-text">
                            <p>
                                Combo ID: {panelWithEnsembleInfo.comboId}
                            </p>
                        </div>
                    </div>
                }
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            Template Token: {panelWithEnsembleInfo.template.templateToken}
                        </p>
                    </div>
                </div>
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            Template ID: {panelWithEnsembleInfo.template.templateId}
                        </p>
                    </div>
                </div>
                <div className="panel-details-item">
                    <div className="panel-details-card-text">
                        <p>
                            {getDesignUseCaseName()}
                        </p>
                    </div>
                </div>
                <DesignPhysicalSpecDetailsSection
                    displayName={panelWithEnsembleInfo.template.dpsDisplayName}
                    displayColor={panelWithEnsembleInfo.template.dpsDisplayColor}
                    dpsId={panelWithEnsembleInfo.template.designPhysicalSpecId}
                    showFrontIndicatorText={false}
                />
                <div className="panel-details-item">
                    {
                        panelWithEnsembleInfo.comboId
                            ? <a href={`https://corewiki.cimpress.net/w/index.php?issimpleformsform=true&title=Combo_details&combo_id=${panelWithEnsembleInfo.comboId}`} target="_blank" rel="noopener noreferrer">
                                {'Source Details >'}
                            </a>
                            : <a href={`https://${camUrl}/accounts/${panelWithEnsembleInfo.tenant.accountId}/contentarea/${panelWithEnsembleInfo.contentAuthoringAreaId}/designs?search=${panelWithEnsembleInfo.template.templateToken}`} target="_blank" rel="noopener noreferrer">
                                {'Source Details >'}
                            </a>
                    }
                </div>
                <a
                    href={panelWithEnsembleInfo.ensembleIds?.length === 1 ? `/ensembleDetails/${panelWithEnsembleInfo.ensembleIds[0]}` : '#'}
                    className="ensemble-details"
                    data-testid="ensemble-details"
                    onClick={EnsembleDetailsOnClick}
                >
                    {'Ensemble Details >'}
                </a>

                {showSpinner
                    ? (<FlexBox>
                        <Spinner size="small" />
                        <div>Loading...</div>
                    </FlexBox>)
                    : (<Checkbox
                        label={panelWithEnsembleInfo.ensembles.length > 1
                            ? `Enable all ${panelWithEnsembleInfo.ensembles.length} ensembles containing this template`
                            : 'Enable ensemble containing this template'}
                        checked={!isCatalogDeleted}
                        payload={panelWithEnsembleInfo.ensembles}
                        disabled={panelWithEnsembleInfo.ensembles[0].isSourceDeleted}
                        onChange={onCatalogDeleteEnsemble}
                    />)}

            </div>
        </Card>
    );
};

export default BatchPreviewPanelCard;
/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable max-len */
/* eslint-enable no-nested-ternary */
