import { Card } from '@cimpress/react-components';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DownloadEnsemblePdfButton from './downloadEnsemblePdfsButton';
import './ensembleLineDetailsCard.scss';
import EnsembleLineEnsemblePreviewCard from './ensembleLineEnsemblePreviewCard';
import DesignPhysicalSpecDetailsSection from './designPhysicalSpecDetailsSection';

interface Props {
    ensembleLine: TemplateCatalog.EnsembleLine;
    minified: boolean;
}

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line
/* eslint-disable react/jsx-boolean-value */

const EnsembleLineDetailsCard = ({
    ensembleLine, minified,
}: Props): JSX.Element => {
    const [prominentEnsembleIds] = useState<Set<string>>(new Set());
    const [completeEnsembleLineByDps, setCompleteEnsembleLineByDps] = useState<Map<string, TemplateCatalog.Ensemble[]>| undefined>(undefined);

    useEffect(() => {
        // Get all Front DPSes only. Line grouping will only happen on the front DPS
        const frontDpsIds = ensembleLine?.ensembles.map((e) => e.templates.find((t) => t.ensemblePanelType === 'front')?.designPhysicalSpecId).flat();
        const uniqueDpsIds = [...new Set(frontDpsIds)].filter(Boolean) as string[]; // Force type to string[] since we filter out undefineds
        const ensemblesByDpsId: Map<string, TemplateCatalog.Ensemble[]> = new Map();

        // Group Ensembles in Ensemble Line by front DPS_ID so they display in separate sections
        uniqueDpsIds.forEach((dpsId) => {
            // Warning: Grouping by DPS_Id from front template in ensemble. Disregards if other panels in ensemble have different dps
            const ensembleLineByDps = ensembleLine?.ensembles.filter((e) => e.templates.find((t) => t.ensemblePanelType === 'front')?.designPhysicalSpecId === dpsId);

            ensemblesByDpsId.set(dpsId, ensembleLineByDps);
        });

        // Finish assigning properties to ensembles and order ensembles/templates appropriately
        ensemblesByDpsId.forEach((ensembleGrouping) => {
            ensembleGrouping.forEach(async (e) => {
                // Set the 1 prominent ensemble in the line
                // Grouping by DPS means there should only be 1 prominent
                if (e.designProperties.isProminentColorVariant) {
                    prominentEnsembleIds.add(e.ensembleId);
                }

                // Set the ensembles DUC based on the EnsembleLine
                e.designUseCaseName = ensembleLine.designUseCaseName;

                // Move the front panels to the beginning of the panels list, so that they're displayed first
                const frontTemplate = e.templates.find((t) => t.ensemblePanelType === 'front');

                if (frontTemplate) {
                    const index = e.templates.indexOf(frontTemplate);

                    e.templates.splice(index, 1);
                    e.templates.unshift(frontTemplate);
                }
            });

            // Move the prominent ensemble to the beginning of the grouping so that it's displayed first
            const prominentEnsemble = ensembleGrouping.find((e) => e.designProperties.isProminentColorVariant);

            if (prominentEnsemble) {
                const index = ensembleGrouping.indexOf(prominentEnsemble);

                ensembleGrouping.splice(index, 1);
                ensembleGrouping.unshift(prominentEnsemble);
            }
        });

        setCompleteEnsembleLineByDps(ensemblesByDpsId);
    }, [prominentEnsembleIds, ensembleLine]);

    // Some header components pulled out of render() to here for readability
    const ensembleLineIdComponent = (<>
        {minified
            ? <Link to={{ pathname: `/ensembleLineDetails/${ensembleLine?.ensembleLineId}`, state: { ensembleLine } }}>
                <p className="design-concept-ensemble-line-description-text">
                    Ensemble Line ID: {ensembleLine?.ensembleLineId}
                </p>
            </Link>
            : <p>
                Ensemble Line ID: {ensembleLine?.ensembleLineId}
            </p>}
    </>);
    const designConceptIdComponent = (<>
        {!minified
        && <Link to={`/designConceptDetails/${ensembleLine.designConceptId}`}>
            <p>
                Design Concept ID: {ensembleLine.designConceptId}
            </p>
        </Link>}
    </>);

    return (<>
        {completeEnsembleLineByDps
        && (<Grid
            item
            container
            direction={minified ? 'row' : 'column'}
            justify={minified ? 'flex-start' : 'space-evenly'}
            alignItems="stretch"
            spacing={1}
        >
            {minified}
            <Grid item xs={4}>
                <Card className="ensemble-line-text">
                    {designConceptIdComponent}
                    {ensembleLineIdComponent}
                    <p className={minified ? 'design-concept-ensemble-line-description-text' : ''}>
                        Design Use Case: {ensembleLine.designUseCaseName}
                        &nbsp;(ID: {ensembleLine.designUseCaseId})
                    </p>
                </Card>
            </Grid>
            {!minified && <h2>Ensembles</h2>}
            {Array.from(completeEnsembleLineByDps).map(([dpsId, ensembleGrouping]) => (
                <Grid item container direction="row" spacing={1} wrap={minified ? 'nowrap' : 'wrap'} key={dpsId} xs={12}>
                    <Grid item xs={3}>
                        <Card>
                            <DesignPhysicalSpecDetailsSection
                                displayName={ensembleGrouping[0].templates[0].dpsDisplayName}
                                displayColor={ensembleGrouping[0].templates[0].dpsDisplayColor}
                                dpsId={dpsId}
                                showFrontIndicatorText={true}
                            />
                        </Card>
                    </Grid>
                    <Grid container xs={9} spacing={1} wrap={minified ? 'nowrap' : 'wrap'} className={minified ? 'horizontal-ensembles-details' : ''}>
                        {ensembleGrouping.map((ensemble) => (
                            <Grid item xs={4} key={ensemble.ensembleId}>
                                <EnsembleLineEnsemblePreviewCard
                                    ensemble={ensemble}
                                    minified={minified}
                                    isProminentEnsemble={prominentEnsembleIds.has(ensemble.ensembleId)}
                                />
                                {!minified
                                    && <Grid>
                                        <DownloadEnsemblePdfButton ensemble={ensemble} />
                                    </Grid>}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>)}
    </>);
};
/* eslint-enable react/jsx-one-expression-per-line */
/* eslint-enable no-nested-ternary */
/* eslint-enable max-len */
/* eslint-enable react/no-array-index-key */
/* eslint-enable react/jsx-boolean-value */

export default EnsembleLineDetailsCard;
